import { MEDIA_URL } from '../baseAPI';

export default interface IUser {
  pk: number;
  id?: number;
  username: string;
  isLoading?: boolean;
  avatar: string;
  date_joined?: string;
  balance?: number
  skin?: string
  cloak?: string
  permissions?: Set<string>
}

export interface IAvatarUpdatedResponse {
  avatar: string;
}

export function getAvatarUrl(user: IUser | undefined | null) {
  if (user === undefined) return '';
  if (user === null) return '';
  if (user.avatar.startsWith('http')) return user.avatar;
  if (user.avatar.startsWith('/')) return MEDIA_URL + user.avatar;
  return MEDIA_URL + '/' + user.avatar;
}

export function prepareIdString(id: string | undefined) {
  if (id === undefined) return 'null';
  if (isNaN(parseInt(id))) return '@' + id;
  else return id;
}
