import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import AppRouter from './AppRouter';

const queryClient = new QueryClient();

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Suspense
        fallback={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#222',
              width: '100vw',
              height: '100vh',
            }}
          >
            <CircularProgress />
          </Box>
        }
      >
        <AppRouter />
      </Suspense>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
