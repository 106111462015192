import { useQuery } from 'react-query';
import { getInventory } from '../API';

export const useInventory = () => {
  let result = useQuery([`inventory`], () => getInventory().then((r) => r.data), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  return {inventory: result.data, areInventoryLoading: result.isLoading, ...result};
};

export default useInventory;