import { useTranslation } from 'react-i18next';
import React, { FormEvent, SyntheticEvent, useCallback, useContext, useState } from 'react';
import { NotificationContext } from '../../../AppRouter';
import { validColor, validLength, validLocalizedString } from '../../../utils/validation';
import { HEXToIntColor, nullableNumber, toCssHexColor } from '../../../utils/dataTypes';
import { editGroup } from '../../../api/UserGroups';
import { thereWasOurSideError } from '../../../stores/notificationContext';
import { handledValidation } from '../../../utils/responses';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ColorPicker } from '../component/ColorPicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { Group } from '../../../entities/group';


interface Props {
  initialGroup: Group
  onEdited: (group: Group) => void;
}


const ModalEditGroupForm = (props: Props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [discordSync, setDiscordSync] = useState<boolean>(false);
  const [color, setColor] = useState<string>(toCssHexColor(props.initialGroup.color));
  const [errorFieldColor, setErrorFieldColor] = useState<null | string>(null);
  const [errorFieldKeyId, setErrorFieldKeyId] = useState<null | string>(null);
  const [errorFieldName, setErrorFieldName] = useState<null | string>(null);
  const [errorFieldPrefix, setErrorFieldPrefix] = useState<null | string>(null);
  const [errorFieldShortPrefix, setErrorFieldShortPrefix] = useState<null | string>(null);
  const [errorFieldSuffix, setErrorFieldSuffix] = useState<null | string>(null);
  const [errorFieldWeight, setErrorFieldWeight] = useState<null | string>(null);
  const notificationContext = useContext(NotificationContext);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    let key_id = formData.get("key_id");
    let name_localized = formData.get("name_localized");
    let prefix_localized = formData.get("prefix_localized");
    let short_prefix_localized = formData.get("short_prefix_localized");
    let suffix_localized = formData.get("suffix_localized");
    let color = formData.get("color");
    let discord_sync1 = discordSync; // controlled, because discord ID input is dependent on it
    let discord_id = formData.get("discord_id");
    let weight = formData.get("weight");

    if (!validColor(color, setErrorFieldColor, false)) return;
    if (!validLength(key_id, 3, 16, setErrorFieldKeyId)) return;
    if (!validLocalizedString(name_localized, setErrorFieldName, false)) return;
    if (!validLocalizedString(prefix_localized, setErrorFieldPrefix, true)) return;
    if (!validLocalizedString(short_prefix_localized, setErrorFieldShortPrefix, true)) return;
    if (!validLocalizedString(suffix_localized, setErrorFieldSuffix, true)) return;

    const payload = {
      key_id: key_id,
      name: JSON.parse(name_localized as string),
      prefix: JSON.parse(prefix_localized as string),
      short_prefix: JSON.parse(short_prefix_localized as string),
      suffix: JSON.parse(suffix_localized as string),
      color: HEXToIntColor(color as string),
      discord_sync: Boolean(discord_sync1),
      discord_id: nullableNumber(discord_id),
      weight: Number(weight),
    };

    setLoading(true)
    editGroup(props.initialGroup.id, payload)
      .then(response => {
        props.onEdited(response.data);
      })
      .catch(error => {
        if (thereWasOurSideError(error, notificationContext)) return;

        let m = new Map();
        m.set('key_id', setErrorFieldKeyId);
        m.set('color', setErrorFieldColor);
        m.set('name', setErrorFieldName);
        m.set('prefix', setErrorFieldPrefix);
        m.set('suffix', setErrorFieldSuffix);
        m.set('short_prefix', setErrorFieldShortPrefix);
        m.set('weight', setErrorFieldWeight);
        if (handledValidation(error, m)) return;

      })
      .finally(() => {
        setLoading(false)
      });

  };

  const handleChange = (event: SyntheticEvent, checked: boolean) => {
    setDiscordSync(checked);
  };


  const changeColor = useCallback((e: string) => validColor(e, setErrorFieldColor, false), [setErrorFieldColor])

  console.log("[re-render] Edit form");

  return (
    <form onSubmit={onSubmit} style={{position: "relative"}}>
      <Card sx={{ minWidth: 375 }}>
        <CardContent>
          <Typography variant='h6' gutterBottom>{t('Edit group') + ' ' + props.initialGroup.id}</Typography>
          <Box style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
            <div style={{display: "flex", flexDirection: "column", flex: "1 1 0", minWidth: "40%", margin: "2px"}}>
              <Typography variant='caption' gutterBottom>{t('Key id of the group (used in mc commands)')}</Typography>
              <TextField id="key_id"
                         name="key_id"
                         label={errorFieldKeyId !== null ? errorFieldKeyId : "Key ID"}
                         fullWidth
                         error={errorFieldKeyId !== null}
                         margin='dense'
                         variant='outlined'
                         size='small'
                         defaultValue={props.initialGroup.key_id}
                         onChange={(e) => validLength(e.target.value, 3, 16, setErrorFieldKeyId)}
              />
            </div>

            <div style={{display: "flex", flexDirection: "column", flex: "1 1 0", minWidth: "40%", margin: "2px"}}>
              <Typography variant='caption' gutterBottom>{t('Color of the group')}</Typography>
              {/*<ColorPickerTest value={color}/>*/}
              <ColorPicker color={color} setColor={setColor} onChange={changeColor} error={errorFieldColor !== null} errorMsg={errorFieldColor}/>
            </div>
          </Box>


          <Box style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
            <div style={{display: "flex", flexDirection: "column", flex: "1 1 0", minWidth: "90%", margin: "2px"}}>
              <Typography variant='caption' gutterBottom>{t('Localized name JSON')}</Typography>
              <TextField id="name_localized"
                         name="name_localized"
                         onChange={(e) => validLocalizedString(e.target.value, setErrorFieldName, false)}
                         error={errorFieldName !== null}
                         multiline minRows={5}
                         label={errorFieldName !== null ? errorFieldName : "JSON"}
                         fullWidth margin='dense'
                         variant='outlined' size='small'
                         defaultValue={JSON.stringify(props.initialGroup.name, undefined, 2)}  />
            </div>

            <div style={{display: "flex", flexDirection: "column", flex: "1 1 0", minWidth: "90%", margin: "2px"}}>
              <Typography variant='caption' gutterBottom>{t('Localized prefix JSON')}</Typography>
              <TextField id="prefix_localized"
                         name="prefix_localized"
                         onChange={(e) => validLocalizedString(e.target.value, setErrorFieldPrefix, true)}
                         error={errorFieldPrefix !== null}
                         multiline minRows={5}
                         label={errorFieldPrefix !== null ? errorFieldPrefix : "nullable JSON"}
                         fullWidth margin='dense'
                         variant='outlined' size='small'
                         defaultValue={JSON.stringify(props.initialGroup.prefix, undefined, 2)}  />

            </div>

            <div style={{display: "flex", flexDirection: "column", flex: "1 1 0", minWidth: "90%", margin: "2px"}}>
              <Typography variant='caption' gutterBottom>{t('Short prefix JSON')}</Typography>
              <TextField id="short_prefix_localized"
                         name="short_prefix_localized"
                         onChange={(e) => validLocalizedString(e.target.value, setErrorFieldShortPrefix, true)}
                         error={errorFieldShortPrefix !== null}
                         multiline minRows={3}
                         label={errorFieldShortPrefix !== null ? errorFieldShortPrefix : "nullable JSON"}
                         fullWidth margin='dense'
                         variant='outlined' size='small'
                         defaultValue={JSON.stringify(props.initialGroup.short_prefix, undefined, 2)}  />

            </div>

            <div style={{display: "flex", flexDirection: "column", flex: "1 1 0", minWidth: "90%", margin: "2px"}}>
              <Typography variant='caption' gutterBottom>{t('Localized suffix JSON')}</Typography>
              <TextField id="suffix_localized"
                         name="suffix_localized"
                         onChange={(e) => validLocalizedString(e.target.value, setErrorFieldSuffix, true)}
                         error={errorFieldSuffix !== null}
                         multiline minRows={3}
                         label={errorFieldSuffix !== null ? errorFieldSuffix : "nullable JSON"}
                         fullWidth margin='dense'
                         variant='outlined' size='small'
                         defaultValue={JSON.stringify(props.initialGroup.suffix, undefined, 2)}  />

            </div>
          </Box>

          <Box style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "end"}}>

            <div style={{display: "flex", flexDirection: "column", flex: "1 1 0", minWidth: "90%", margin: "2px"}}>
              <Typography variant='caption' gutterBottom>{t('Discord sync with corresponding discord group by id')}</Typography>
              <FormControlLabel checked={discordSync} onChange={handleChange} name="discord_sync" control={<Checkbox />} label="Synced" />
            </div>

            <div style={{display: "flex", flexDirection: "column", flex: "1 1 0", minWidth: "90%", margin: "2px"}}>
              <Typography variant='caption' gutterBottom>{t('Key id of the group (used in mc commands)')}</Typography>
              <TextField disabled={!discordSync} id="discord_id" name="discord_id" label='discord_id' fullWidth margin='dense' variant='outlined' size='small' defaultValue="" type="number"/>
            </div>

            <div style={{display: "flex", flexDirection: "column", flex: "1 1 0", minWidth: "90%", margin: "2px"}}>
              <Typography variant='caption' gutterBottom>{t('Weight of the group')}</Typography>
              <TextField
                name="weight"
                label={errorFieldWeight !== null ? errorFieldWeight : "nullable JSON"}
                margin='dense'
                variant='outlined'
                size='small'
                defaultValue="2"
                error={errorFieldWeight !== null}
                type="number" required inputProps={{max: 10, min: 1}}/>
            </div>

          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button disabled={loading} type="submit" variant="contained" color="success">{loading ? t("Loading") : t("Update")}</Button>
          </Box>
        </CardContent>
      </Card>
    </form>

  );
}

export default ModalEditGroupForm;