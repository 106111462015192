import { BASE_URL } from '../baseAPI';

export enum ItemType {
  ITEM = "I", // item
  PRODUCT = "P", // privilege
  SPECIAL = "S", // special
  KIT = "K", // kit
}

export interface IItem {
  description: string,
  id: number,
  image: string,
  mod: number,
  name: string,
  name_ru: string,
  type: ItemType
}
interface IInnerServer {
  id: number,
  name: string
  color: string,
}
export interface IProduct {
  id: number,
  name: string,
  type: ItemType,
  price: number,
  servers: IInnerServer[],
  image: string,
  description: string,
  items: {item: IItem, count: number}[]
}

export function getProductImage(product: IProduct) {
  return product.image.startsWith("http") ? product.image : BASE_URL + product.image
}

export function getItemImage(item: IItem) {
  return item.image.startsWith("http") ? item.image : BASE_URL + item.image
}