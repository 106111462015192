import { useQuery } from 'react-query';
import { getDonationTop } from '../API';

const useDonationsPlayersTop = () => {
  const {
    data: entries,
    isLoading,
    isError,
  } = useQuery(['donation-top'], () => getDonationTop().then((r) => r.data), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  return { donationEntries: entries, areDonationEntriesLoading: isLoading, isError };
};

export default useDonationsPlayersTop;
