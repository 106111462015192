import { baseAPI_V2 } from '../baseAPIV2';


export async function loginWithCookies(username: string, password: string, recaptcha: string) {
  const payload = {
    username,
    password,
    recaptcha
  }
  await baseAPI_V2.post<{}>('/api/v2/login', payload);
}

export async function logoutWithCookies() {
  await baseAPI_V2.post<{}>('/api/v2/logout', {});
}

export async function register(username: string, password: string, email: string, recaptcha: string) {
  const payload = {
    username,
    password,
    email,
    recaptcha
  }
  await baseAPI_V2.post<{}>('/api/v2/register', payload);
}