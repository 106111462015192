import axios from 'axios';
import { getCookie } from './API';
import { CSRF_TOKEN_COOKIE_KEY, CSRF_TOKEN_HEADER_KEY } from './stores/constants';


export const BASE_URL = process.env.REACT_APP_BASE_URL === undefined ? 'https://starshine-project.ru' : process.env.REACT_APP_BASE_URL;
export const MEDIA_URL = 'https://media.starshine-project.ru';

const baseAPI = axios.create({ baseURL: BASE_URL });
baseAPI.interceptors.request.use((config) => {
  if (!config.headers) config.headers = {};
  const cookieToken = getCookie(CSRF_TOKEN_COOKIE_KEY);
  if (cookieToken) config.headers[CSRF_TOKEN_HEADER_KEY] = cookieToken;
  config.withCredentials = true;
  return config;
});

export default baseAPI;
