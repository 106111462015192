import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';

const StarsineTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'whitesmoke',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'whitesmoke',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'gold',
    },
    // '-webkit-box-shadow': '0 0 0 100px gray inset',
  },
});

export default StarsineTextField;
