import { BASE_URL } from '../baseAPI';

export enum IModCategory {
  BASE = 'B',
  HELPER = 'H',
  CORE = 'C',
  PLUGIN = 'P',
}

export default interface IMod {
  id: number;
  name: string;
  forge_id: string;
  description: string;
  image: string;
  category: IModCategory;
}

export function getModImage(mod: IMod) {
  return mod.image.startsWith("http") ? mod.image : BASE_URL + mod.image
}
