export default function LauncherWelcome() {
  return <div style={{
    height: "100vh",
    width: "100vw",
    background: "#222",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: 'white'
  }}>
    <h1 style={{fontFamily: "Audiowide", }}>
      Мы обновили лаунчер!
    </h1>
    <p style={{fontFamily: "Roboto", }}>
      💛 Перекачайте его 💛
    </p>
  </div>
}