import { useQuery } from 'react-query';
import { getAllServers, getAllServersForAdminPanel, getServer } from '../API';

export const useServersForAdmin = () => {
  return useQuery(['servers-admin-v2'], () => getAllServersForAdminPanel().then((r) => r.data), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

const useServers = () => {
  let result = useQuery(['servers'], () => getAllServers().then((r) => r.data.results), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  return {servers: result.data, areServersLoading: result.isLoading, ...result};
};

export const useServer = (id: number) => {
  let result = useQuery([`server-${id}`], () => getServer(id).then((r) => r.data), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !isNaN(id)
  });
  if (result.data)
    result.data.mods = result.data.mods.filter(m => m.name.toLowerCase() !== "minecraft")
  return {server: result.data, areServersLoading: result.isLoading, ...result};
};

export default useServers;
