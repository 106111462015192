import React from 'react';
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

interface Props {
  message: string
  status: "error" | "success" | "warning" | "frozen"
}


function WholeScreenStatus(props: Props) {

  let icon = <InfoIcon fontSize="large" style={{color: "red"}}/>;
  let color = "white";
  if (props.status === 'error') {
    color = 'crimson'
    icon = <ReportIcon fontSize="large" style={{color: "red"}}/>;
  }
  if (props.status === 'success') {
    color = 'green'
    icon = <CheckCircleOutlineIcon fontSize="large" style={{color: "green"}}/>
  }
  if (props.status === 'warning') {
    color = 'gold'
    icon = <WarningIcon fontSize="large" style={{color: "goldenrod"}}/>
  }
  if (props.status === 'frozen') {
    color = 'blue'
    icon = <AcUnitIcon fontSize="large" style={{color: "lightblue"}}/>
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#222',
      }}
    >
      {icon}
      <p
        style={{
          color: color,
          fontFamily: 'Roboto, serif',
          fontWeight: 'thin',
          fontStyle: 'italic',
        }}
      >
        {props.message}
      </p>
    </div>
  );
}

export default WholeScreenStatus;
