import IMod from './IMod';
import { IInnerServer } from './IServerPlayingTime';
import { BASE_URL } from '../baseAPI';

export default interface IServer {
  id: number;
  name: string;
  ServerIcon: string;
  version: string;
  PvP: boolean;
  mods: IMod[];
  isOnline?: boolean
}

export function getServerImage(server: IServer) {
  return server.ServerIcon.startsWith("http") ? server.ServerIcon : BASE_URL + server.ServerIcon
}

export function get_color(server: IServer | IInnerServer) {
  switch (server.id) {
    case 1: //classic v2
      return '#75D3FF';
    case 2: // magic v5
      return '#FF85FF';
    case 3: //rpg-tech
      return '#FF6B70';
    case 4: //tech hard
      return '#BAE9FF';
    case 5: //Adventure RPG
      return '#FF9195';
    case 6: //Magic Hard
      return '#FFABFF';
    case 7: //Tech Classic
      return '#94DDFF'
    case 8: //Magic V6
      return '#FF65FF'
    case 9: //Tech Classic V3
      return '#5CCBFF'
    case 10: //RPG-Tech V5
      return '#FF4D52';
    case 11: //Magic V7
      return '#FF4DFF';
    case 12: //Vanilla V2
      return '#F0EE84';
  }
  return '#e19322';
}
