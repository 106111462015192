import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { HexColorPicker } from "react-colorful";


interface Props {
  color: string,
  setColor: (a: string) => void
  onChange: (e: string) => void
  error: boolean
  errorMsg: string | null
  defaultValue?: string
}

// const BIG_ON_CLICK_LISTENER =

interface kek {
  value: string
}

export const ColorPickerTest = React.memo((props: kek) => {
  console.log("[re-render] color picker test");

  return <div>{props.value}</div>

}, (a,b) => {return a.value === b.value})

export const ColorPicker = React.memo((props: Props) => {
  const [visible, setVisible] = useState<boolean>(false);

  console.log("Color picker rerenders bitch", props);
  return <FormControl sx={{ m: 1 }} style={{position: "relative"}}>
    <InputLabel size="small" error={props.error}>
      {props.error !== null ? props.errorMsg : "HEX Color"}
    </InputLabel>
    <OutlinedInput
      size="small"
      id="standard-adornment-password"
      type='text'
      name='color'
      error={props.error}
      defaultValue={props.defaultValue}
      style={{color: props.color}}
      value={props.color}
      onChange={e => {props.setColor(e.target.value); props.onChange(e.target.value)}}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setVisible(visible => !visible)}
          >
            <ColorLensIcon/>
          </IconButton>
        </InputAdornment>
      }
    />
    {visible ? <div style={{position: 'fixed', opacity: 0, width: "10000px", height: "10000px", zIndex: 100, top: "-5000px", left: "-5000px"}} onClick={() => setVisible(false)}>

    </div> : null}
    {visible ? <HexColorPicker style={{position: 'absolute', top: "100%", zIndex: 101}} color={props.color} onChange={(e) => {
      props.setColor(e);
      props.onChange(e)
    }} /> : null}
  </FormControl>
}, (a,b) => {return a.color === b.color && a.error === b.error})
