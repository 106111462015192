import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LockResetIcon from '@mui/icons-material/LockReset';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import StarsineTextField from '../../components/Forms/StarshineTextField';
import Copyright from '../../components/Copyright';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ActionStatus } from '../../types/ActionStatus';
import { getPasswordResetInfo, putNewPassword } from '../../API';
import Alert from '@mui/material/Alert';
import WholeScreenLoader from '../../components/WholeScreenLoader';
import WholeScreenStatus from '../../components/WholeScreenStatus';


const PASSWORD1 = 'browser-please-do-not-autofill';
const PASSWORD2 = 'browser-please-do-not-autofill2';


export default function PasswordResetPage() {
  const params = useParams();
  const { t } = useTranslation();
  const [resetInfoLoading, setResetInfoLoading] = useState<boolean>(true);
  const [resetInfo, setResetInfo] = useState<{ username: string } | null>(null);
  const [status, setStatus] = useState<ActionStatus | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (params.token === undefined) return;
    if (params.user_id === undefined) return;
    getPasswordResetInfo(Number(params.user_id), params.token)
      .then(r => {
        setResetInfo({username: r.data.username})
      })
      .catch(err => {

      })
      .finally(() => setResetInfoLoading(false));
  }, [params.token, params.user_id]);

  if (params.user_id === undefined) {
    return <WholeScreenStatus message="Link is expired or corrupted"  status="error"/>;
  }
  if (params.token === undefined) {
    return <WholeScreenStatus message="Link is expired or corrupted"  status="error"/>;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (params.user_id === undefined) return;
    if (params.token === undefined) return;
    const data = new FormData(event.currentTarget);
    const password = data.get(PASSWORD1);
    if (password !== data.get(PASSWORD2)) {
      setStatus({ severity: 'error', message: t('Password do not match') });
      return;
    }
    if (password === null) return;

    setLoading(true)
    putNewPassword(Number(params.user_id), params.token, password.toString())
      .then(r => {
        setStatus({severity: "success", message: t("Password changed successfully. Please login")})
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
            if (err.response.data.password) {
              setStatus({severity: 'error', message: err.response.data.password.join(" ")})
            }
          }
        }
      })
      .finally(() => setLoading(false))

  };
  return (
    resetInfoLoading ? <WholeScreenLoader /> :
      resetInfo === null ? <WholeScreenStatus message="Link is expired or already activated"  status="warning"/> : <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockResetIcon />
        </Avatar>
        <Typography component='h1' variant='h6'>
          {t('Password reset for')} {" " + resetInfo.username}
        </Typography>
        <form onSubmit={handleSubmit}>
          <StarsineTextField
            margin='normal'
            autoComplete='off'
            required
            fullWidth
            helperText='Минимум 8 символов.'
            name={PASSWORD1}
            label={t("New Password")}
            type='password'
            id={PASSWORD1}
            inputProps={{ minLength: 8, maxLength: 100 }}
          />
          <StarsineTextField
            margin='normal'
            autoComplete='off'
            required
            fullWidth
            helperText={t("Please save the password at a safe place")}
            name={PASSWORD2}
            label={t("Confirm New Password")}
            type='password'
            id={PASSWORD2}
            inputProps={{ minLength: 8, maxLength: 100 }}
          />
          {status ? <Alert severity={status.severity}>{status.message}</Alert> : null}
          {status && status.severity === 'success' ? <Button
            type='submit'
            fullWidth
            variant='contained'
            onClick={() => navigate("/login")}
            disabled={loading}
            sx={{
              mt: 3,
              mb: 2,
              bgcolor: 'success',
              '&:hover': {
                backgroundColor: 'lime',
                color: '#000',
              },
            }}
          >
            {t("Login")}
          </Button>: <Button
            type='submit'
            fullWidth
            variant='contained'
            disabled={loading}
            sx={{
              mt: 3,
              mb: 2,
              bgcolor: 'secondary.main',
              '&:hover': {
                backgroundColor: '#fff',
                color: '#000',
              },
            }}
          >
            {loading ? t("Loading") : t('Update Password')}
          </Button>}

        </form>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
