import { BASE_URL, MEDIA_URL } from '../baseAPI';
import IServer from '../types/IServer';

export default interface ServerAdminInfo {
  id: number;
  name: string;
  subtitle: string;
  description: string;
  icon: string;
  version: string;
  pvp: boolean;
}


export function getAdminServerImage(server: ServerAdminInfo) {
  return server.icon.startsWith("http") ? server.icon : MEDIA_URL + "/" + server.icon
}