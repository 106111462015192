import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import CSSTransition from 'react-transition-group/CSSTransition';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import StarsineTextField from '../../components/Forms/StarshineTextField';
import WholeScreenLoader from '../../components/WholeScreenLoader';
import { GOOGLE_CAPTCHA_SITE_KEY } from '../../stores/constants';
import './LoginPage.css';
import { AuthContext, NotificationContext } from '../../AppRouter';
import { AuthState, loadSelfFromBackendAndUpdateContext } from '../../stores/authContext';
import { loginWithCookies } from '../../api/auth';
import {
  getApplicationCode,
  getCooldownInMinutes,
  hasApplicationCode,
  loadCaptchaScriptToDOM,
} from '../../utils/responses';
import { thereWasOurSideError, notification } from '../../stores/notificationContext';
import { AxiosError } from 'axios';
import { ErrorCode } from '../../api/codes';



function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link className="small-link" color="inherit" to="https://starshine-project.ru/">
        Starshine Project
      </Link>
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const nodeRef = React.useRef(null);
  const authContext = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordFieldError, setPasswordFieldError] = useState<string | null>(null);
  const [usernameFieldError, setUsernameFieldError] = useState<string | null>(null);
  const [generalError, setGeneralError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(loadCaptchaScriptToDOM, []);

  if (authContext.state === AuthState.AUTHORIZED) {
    // @ts-ignore
    return <Navigate to={location.state?.from?.pathname || '/'} replace />;
  }

  if (authContext.state === AuthState.LOADING) {
    return <WholeScreenLoader />;
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let token = "";
    if (window.grecaptcha !== undefined)
      token = await window.grecaptcha.execute(GOOGLE_CAPTCHA_SITE_KEY, { action: 'login' });

    if (token.length === 0) {
      setGeneralError(t("Google captcha token is empty. Maybe Google servers are not reachable"))
      return
    }

    try {
      setIsLoading(true);
      setPasswordFieldError(null);
      setGeneralError(null);
      await loginWithCookies(username, password, token);
      loadSelfFromBackendAndUpdateContext(authContext);

      // @ts-ignore
      let pathname = location.state?.from?.pathname;

      // @ts-ignore
      let search = location.state?.from?.search;

      if (pathname !== undefined && search !== undefined && search !== null && search !== "?") {
        pathname = pathname + search
      }

      navigate(pathname === undefined ? '/' : pathname, { replace: true });
      notification("success", t("Successful login with @{{username}}", {username}), null, null, notificationContext);

    } catch (error: any | AxiosError) {
      if (thereWasOurSideError(error, notificationContext)) return;

      if (hasApplicationCode(error)) {
        switch (getApplicationCode(error)) {
          case ErrorCode.LOGIN_TOO_MANY_ATTEMPTS:
            let cooldown = getCooldownInMinutes(error);
            setGeneralError(t("Too many failed login attempts. Please wait for ~{{cooldown}} minutes.", {cooldown}))
            break;
          case ErrorCode.LOGIN_INVALID_CREDENTIALS:
            setPasswordFieldError(t("Invalid username or password."))
            break;
          case ErrorCode.BAD_CAPTCHA:
            setGeneralError(t("Google captcha did not approved your request. Try wait for some time and try again (are you using Tor browser)"));
            break;
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <Container component="main" maxWidth="xs" sx={{ minHeight: '65vh' }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Sign In Form')}
          </Typography>
          <Box id="login-form" autoComplete="on" component="form" onSubmit={onSubmit} sx={{ mt: 1 }}>
            <StarsineTextField
              inputProps={{maxLength: 16}}
              id="starshine-username"
              name="starshine-username"
              type="text"
              autoComplete="username"
              defaultValue={username}
              margin="normal"
              required
              fullWidth
              label={t('input-text-username').toString()}
              autoFocus
              onChange={(e) => {setUsername(e.target.value);}}
              helperText={usernameFieldError != null ? usernameFieldError : ''}
              error={usernameFieldError != null}
            />
            <StarsineTextField
              id="starshine-password"
              name="starshine-password"
              type="password"
              autoComplete="current-password"
              defaultValue={password}
              inputProps={{maxLength: 160}}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordFieldError(null);
              }}
              margin="normal"
              required
              fullWidth
              label={t('input-text-password').toString()}
              error={passwordFieldError !== null}
              helperText={passwordFieldError != null ? passwordFieldError : ''}
            />
            <input type="hidden" name="g-recaptcha-response" id="recaptcha" />
            {generalError !== null ? (
              <Alert severity="error" variant="filled">{generalError}</Alert>
            ) : null}
            <CSSTransition
              ref={nodeRef}
              in={isLoading}
              classNames={'loader-transition'}
              timeout={{ enter: 200, exit: 300 }}
            >
              <div ref={nodeRef} style={{ minHeight: '2em', textAlign: 'center' }}>
                {isLoading ? <CircularProgress color="warning" /> : <div />}
              </div>
            </CSSTransition>
            <Button
              type="submit"
              className="g-recaptcha"
              fullWidth
              disabled={isLoading}
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: 'secondary.main',
                '&:hover': {
                  backgroundColor: '#fff',
                  color: '#000',
                },
              }}
            >
              {isLoading ? t('Loading...') : t('Sign In')}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link className="small-link" to="/password-reset">
                  {t('Forgot password?')}
                </Link>
              </Grid>
              <Grid item>
                <Link className="small-link" to="/sign-up">
                  {t('Don\'t have an account? Sign Up')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}

export default LoginPage;
