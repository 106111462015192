import useInventory from '../../hooks/useInventory';
import NamedLoaderAbsolute from '../../components/NamedLoaderAbsolute';
import { getInventoryEntryImage, IInventoryEntry } from '../../types/IInventoryEntry';
import './SettingsInventory.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyCollectionAbsolute } from '../../components/EmptyCollectionAbsolute';
import Button from '@mui/material/Button';
import IServer, { getServerImage } from '../../types/IServer';
import { putItemInInventory } from '../../API';
import useOwnOnlineStatus from '../../hooks/useOwnOnlineStatus';
import { ActionStatus } from '../../types/ActionStatus';

interface Props {
  window?: () => Window;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface ServerProps {
  server: IServer,
  selectedServer: IServer | null,
  setSelectedServer: Function
}


function ServerHex(props: ServerProps) {
  const s = props.server;
  const {online, isLoading, isRefetching} = useOwnOnlineStatus(s.id);

  useEffect(() => {
    if (online) {
      s.isOnline = online.result
    }
  }, [s, online]);

  let color = "red"
  let message = ""
  if (s.isOnline === undefined) {message = "Failed to fetch online status"}
  if (isLoading || isRefetching) {message = "Loading..."; color = 'white'}
  if (s.isOnline) {message = "Вы на сервере"; color = 'lime'}
  if (!s.isOnline) {message = "Вы не на сервере"}

  return <div key={s.id} className='server server-select-element'>
    <div className={`hexagon ${s === props.selectedServer ? 'hexagon-active' : ''}`}
         onClick={() => props.setSelectedServer(s)}>
      <div className='hexagon inner-hexagon'>
        <img src={getServerImage(s)} alt={s.name} />
      </div>
    </div>
    <p>{s.name}</p>
    <span className='online-status' style={{ color: color }}>{message}</span>
  </div>;
}

export default function SettingsInventory(props: Props) {
  const { inventory, refetch, isFetched, isFetching } = useInventory();

  const [activeInventoryEntry, setActiveInventoryEntry] = useState<IInventoryEntry | null>(null);
  const [selectedServer, setSelectedServer] = useState<IServer | null>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [givingIsLoading, setGivingLoading] = useState<boolean>(false);
  const [givingStatus, setGivingStatus] = useState<ActionStatus | null>(null);

  const { t } = useTranslation();
  const onClose = () => {
    setOpen(false);
    setGivingStatus(null);
  };
  const onOpen = () => {
    setOpen(true);
  };
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (activeInventoryEntry === null) return;
    if (selectedServer === null) return;

    setGivingLoading(true);
    putItemInInventory(selectedServer.id, activeInventoryEntry.item.id)
      .then(() => {
        setGivingStatus({message: "Предмет выдан на сервер " + selectedServer.name, severity: "success"})
        refetch()
      })
      .catch(err => {
        if (err.response) {
          if (err.response.data.code) {
            if (err.response.data.code === 4001) {
              setGivingStatus({message: "You are not online on server", severity: "error"})
              return
            }
            if (err.response.data.code === 4269) {
              setGivingStatus({message: "Item not found in inventory", severity: "error"})
              return
            }
          }
          if (err.response.data.message) {
            setGivingStatus({message: err.response.data.message, severity: "error"})
            return
          }
          setGivingStatus({message: "Ошибка при выдаче", severity: "error"})
        }
      })
      .finally(() => {setGivingLoading(false)})
  };

  console.log("inventory", inventory);
  console.log("isFetched && inventory?.length === 0", isFetched && inventory?.length === 0);
  console.log("isFetching", isFetching);
  return <div>
    <Modal
      keepMounted
      open={open}
      onClose={onClose}
      aria-labelledby='keep-mounted-modal-title'
      aria-describedby='keep-mounted-modal-description'
    >
      <Box sx={style}>

        <Typography id='keep-mounted-modal-title' variant='h6' component='h2' textAlign='center'>
          {" " + activeInventoryEntry?.item.name}
        </Typography>
        <form onSubmit={onSubmit} style={{ textAlign: 'center', marginTop: '1em' }}>
          <div id='giving-body' className='modal-body'>
            {activeInventoryEntry && activeInventoryEntry.servers.map(s => <ServerHex key={s.id} server={s} setSelectedServer={setSelectedServer}
                                                    selectedServer={selectedServer} />)}
          </div>
          {givingStatus ? <Alert severity={givingStatus.severity}>{givingStatus.message}</Alert> : null}
          <div id='confirmation-buttons' className='modal-footer'>
            <Button
              type='submit'
              color='warning'
              variant='contained'
              sx={{ my: 2, color: 'black' }}
              style={{ position: 'relative' }}
              disabled={selectedServer === null || !selectedServer.isOnline || givingIsLoading}
            >
              {givingIsLoading
                ? "Loading..."
                : selectedServer === null
                  ? 'Укажите сервер'
                  : (selectedServer.isOnline
                    ? 'Выдать'
                    : "Нужно быть на сервере")}
            </Button>
          </div>
        </form>
      </Box>
    </Modal>

    <div id='inventory-list'>
      <EmptyCollectionAbsolute message={t("Empty inventory")} visible={isFetched && inventory?.length === 0} />
      <NamedLoaderAbsolute message={t("Loading inventory")} visible={isFetching} />
      <div id='inventory-list-inner'>
        {inventory === undefined ? null : inventory.map(i =>
          <div key={i.item.id} className='item-inventory-card'>
            <div className='item-image-wrapper'>
              <img src={getInventoryEntryImage(i)} alt={i.item.name} />
            </div>
            <h6><p>x{i.count}</p>{i.item.name}</h6>
            <div className='item-control'>
              <button onClick={() => {setActiveInventoryEntry(i); setOpen(true)}}>Получить на сервере</button>
            </div>
          </div>,
        )}
      </div>
    </div>
  </div>;
}