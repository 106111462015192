import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { StarshineHeader } from '../../components/StarshineHeader';
import * as React from 'react';
import { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { updateSkin } from '../../API';
import { AuthContext, NotificationContext } from '../../AppRouter';
import ReactSkinview3d from "react-skinview3d"
import { notification } from '../../stores/notificationContext';


export default function SettingsAppearance() {
  const {t} = useTranslation();
  const [canvasWidth, setCanvasWidth] = useState<number>(400);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileURL, setSelectedFileURL] = useState<string | null>(null);
  const {user} = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);
  const canvasWrapperRef = useRef<HTMLInputElement>(null); // used to set initial canvas height

  useEffect(() => {

    if (user === null) return; // should never happen because we are under <AuthorizedRoutes>
    window.addEventListener("resize", () => {
      if (canvasWrapperRef.current !== null) {
        setCanvasWidth(canvasWrapperRef.current.offsetWidth)
      }
    });
    if (canvasWrapperRef.current !== null) {
      setCanvasWidth(canvasWrapperRef.current.offsetWidth)
    }

  }, [canvasWrapperRef])

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedFile === null) return;

    setLoading(true);
    updateSkin(selectedFile)
      .then(_ => {notification("success", t("Skin changed successfuly"), null, 3000, notificationContext)})
      .catch(error => {console.error(error)})
      .finally(() => {setLoading(false);})
  }

  if (user === null) return null;

  return <div style={{width: "100%"}}>
    <StarshineHeader message={t('skin-settings')}/>
    <Container maxWidth="md">
      <Typography component="p" textAlign='center'>
        {t('About skin')}
      </Typography>
      <form onSubmit={onSubmit} style={{textAlign: "center"}}>
        <input onChange={event => {
          const target = event.target;
          const files = target.files;
          if (files === null) return;

          const image = files[0];
          if (image === undefined) {
            setSelectedFileURL(null);
            setSelectedFile(null);
          } else {
            setSelectedFileURL(URL.createObjectURL(image));
            setSelectedFile(image);
          }
        }} type="file" accept="image/png" name="skin" required id="skin-select"/>
        <Button
          type="submit"
          color="warning"
          variant="contained"
          sx={{ my: 2, color: 'black' }}
          style={{position: "relative"}}
          disabled={selectedFileURL === null || loading}
        >
          {loading ? <CircularProgress size={20} color="info" /> : selectedFileURL ? t("update-skin-button") : "Choose file"}
        </Button>
      </form>
    </Container>
    <Container ref={canvasWrapperRef} id="appearance-viewer" maxWidth="md">
      <ReactSkinview3d
        skinUrl={selectedFileURL === null ? user.getSkin() : selectedFileURL}
        capeUrl={user.hasCape() ? user.getCape() : undefined}
        height={window.innerHeight - 200}
        width={canvasWidth}
        options={{
          panorama: "/panorama.jpg",
          background: 0x5a76f3,
        }}
      />
    </Container>
  </div>
}