import baseAPI_V2 from '../baseAPI';
import { LocalizedString } from './LocalizedString';
import { Group } from '../entities/group';


interface UserGroup {
  id: number,
  key_id: string,
  name: LocalizedString,
  prefix: LocalizedString | null,
  short_prefix: LocalizedString | null,
  color: number | null,
  discord_id: number | null,
  discord_sync: boolean,
  weight: number,
  permissions: number,
}

export async function fetchAllGroupsInSystem() {
  return await baseAPI_V2.get<UserGroup[]>("/api/v2/groups");
}

export async function createGroup(data: any) {
  return await baseAPI_V2.post<Group>("/api/v2/groups", data);
}

export async function editGroup(id: number, data: any) {
  return await baseAPI_V2.put<Group>(`/api/v2/groups/${id}`, data);
}