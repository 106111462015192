import { useQuery } from 'react-query';
import { getAllProducts } from '../API';
import IServer from '../types/IServer';

const useProducts = (server: IServer | null) => {
  let result = useQuery(['products', server], () => getAllProducts(server).then((r) => r.data.results), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  return {products: result.data, areProductsLoading: result.isLoading, ...result};
};

export default useProducts;
