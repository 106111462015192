import { useQuery } from 'react-query';
import { getTotalPlayingTime, getUser } from '../API';

const useUserPlayingTime = (id: string | undefined) => {
  const {
    data: entries,
    isLoading,
    isError,
    isRefetching,
  } = useQuery(
    ['user-playing-time', id],
    () => getTotalPlayingTime(id === undefined ? '-1' : id).then((r) => r),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );
  const result = entries === undefined ? [] : entries;
  return {
    entries: result,
    entriesAreLoading: isLoading,
    entriesError: isError,
    entriesAreRefetching: isRefetching,
  };
};

export default useUserPlayingTime;
