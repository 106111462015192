import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function WholeScreenLoader() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#222',
      }}
    >
      <CircularProgress color="warning" />
    </div>
  );
}

export default WholeScreenLoader;
