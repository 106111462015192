import { useQuery } from 'react-query';
import { getPhotos } from '../API';

export const usePhotos = (server_id: number | undefined) => {
  let result = useQuery([`photos-server-${server_id}`], () => getPhotos(server_id).then((r) => r.data), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !isNaN(Number(server_id))
  });
  return {photos: result.data, areServersLoading: result.isLoading, ...result};
};

export default usePhotos;
