import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface NamedLoaderAbsoluteProps {
  message: string,
  visible?: boolean
}

function NamedLoaderAbsolute(props: NamedLoaderAbsoluteProps) {

  return (
      <div
        style={{
          display: props.visible ? "flex" : "none",
          opacity: props.visible ? "100%" : "0",
          position: 'absolute',
          transition: '0.3s',
          top: '50%',
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: '100%',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <CircularProgress color="warning" />
        <p
          style={{
            color: 'white',
            fontFamily: 'Roboto, serif',
            fontWeight: 'thin',
            fontStyle: 'italic',
          }}
        >
          {props.message}
        </p>
      </div>
  );
}

export default NamedLoaderAbsolute;
