import { useTranslation } from 'react-i18next';
import React from 'react';
import useUserPlayingTime from '../../hooks/useUserPlayingTime';
import { get_color } from '../../types/IServer';
import { Pie } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from 'chart.js';
import NamedLoaderAbsolute from '../NamedLoaderAbsolute';

interface Props {
  user_id: number
}

ChartJS.register(ArcElement, Tooltip, Legend, Title);


export default function PieChartPlayerTime({user_id}: Props) {
  const { t } = useTranslation();
  const { entries, entriesAreLoading, entriesAreRefetching } = useUserPlayingTime(user_id.toString());

  const data = {
    labels: entries.map((el) => ' (' + Math.floor(el.total / 3600) + ` ${t('h.')}) ` + el.server.name),
    datasets: [
      {
        data: entries.map((el) => (el.total / 3600).toFixed(2)),
        backgroundColor: entries.map((el) => get_color(el.server)),
        borderColor: entries.map((_el) => 'white'),
        hoverOffset: 2,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: true,
        text: t("Statistics by server"),
      },
    },
  };

  return <div id="server-playing-status">
    <NamedLoaderAbsolute visible={entriesAreLoading || entriesAreRefetching} message="Loading Statistics" />
    {entriesAreLoading || entriesAreRefetching ? null : (
      <Pie width={200} height={400} data={data} options={options} />
    )}
  </div>
}