import { useQuery } from 'react-query';
import { getSelf, getUser } from '../API';

const useUser = (id: string | undefined) => {
  const {
    data: user,
    isLoading,
    isError,
    isRefetching,
  } = useQuery(['user', id], () => getUser(id === undefined ? '-1' : id).then((r) => r.data), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });
  return { user, isLoading, isError, isRefetching };
};

export const useSelf = () => {
  return useQuery(['user-self'], () => getSelf().then((r) => r.data), {
    keepPreviousData: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
export default useUser;
