import axios from 'axios';
import { loadSelfFromBackendAndUpdateContext } from './stores/authContext';

// export const BASE_URL_V2 = 'http://localhost:8080';

export const BASE_URL_V2 = process.env.REACT_APP_BASE_URL === undefined ? 'https://starshine-project.ru' : process.env.REACT_APP_BASE_URL;
export const baseAPI_V2 = axios.create({ baseURL: BASE_URL_V2 });

baseAPI_V2.interceptors.request.use((config) => {
  config.withCredentials = true;
  return config;
});

