import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { DatePicker } from '@mui/x-date-pickers';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { createGrant } from '../../../api/admin';
import { notification, thereWasOurSideError } from '../../../stores/notificationContext';
import { loadSelfFromBackendAndUpdateContext } from '../../../stores/authContext';
import { isPermissionError } from '../../../utils/responses';
import { Group } from '../../../entities/group';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../hooks/usePermissions';
import { useServersForAdmin } from '../../../hooks/useServers';
import { useAdminGroups } from './GroupAPI';
import { useQueryClient } from 'react-query';
import { AuthContext, NotificationContext } from '../../../AppRouter';


interface Props {
  group: Group
  setOpen: (a: boolean) => void
  onCreated: () => void
}


const ModalCreatePermissionGrant = (props: Props) => {
  const { t } = useTranslation();

  const queryPerms = usePermissions();
  const queryServers = useServersForAdmin();
  const queryGroups = useAdminGroups();
  const queryClient = useQueryClient();
  const notificationContext = useContext(NotificationContext);
  const authContext = useContext(AuthContext);

  const addPermission = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (event.currentTarget === null) return;

    const formData = new FormData(event.currentTarget);
    let permissionId = formData.get('Permission id') as string | null;
    let serverId = formData.get('Server id') as string | null;
    let permissionValue = formData.get('Permission Value') as string | null;
    let startDate = formData.get('startDate') as string | null;

    if (permissionId === null) return;
    if (serverId === null) return;
    if (permissionValue === null) return;
    if (startDate === null) return;

    let data = {
      perm_id: Number(permissionId),
      server_id: serverId === '' ? null : Number(serverId),
      world_id: null,
      value: Boolean(permissionValue),
      expire: startDate === '' ? null : dayjs(startDate).unix(),
    };
    createGrant(props.group.id, data)
      .then(response => {
        if (queryGroups.data === undefined) return;
        const group = queryGroups.data.find(group => group.id === props.group.id);
        if (group === undefined) return;

        let grant = response.data;
        group.permission_grants.push(grant);

        queryClient.setQueryData(['groups'], [...queryGroups.data]);
        notification('success', t('Permission grant created successfully'), null, null, notificationContext);
        props.setOpen(false);
        loadSelfFromBackendAndUpdateContext(authContext);
        props.onCreated()

      })
      .catch(error => {
        if (thereWasOurSideError(error, notificationContext)) return;
        if (isPermissionError(error)) return;
      })
      .finally();
  };

  return <div>
    <Container maxWidth='sm'>
      <form onSubmit={addPermission}>
        <DialogTitle id='alert-dialog-title'>
          {t('Add permission to group')} #{props.group.id}
        </DialogTitle>
        <FormControl fullWidth variant='standard' sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id='grant-permission-id'>{t('Choose permission to add to group')}</InputLabel>
          <NativeSelect inputProps={{ name: 'Permission id', id: 'new-grant-permission-id' }}>
            {queryPerms.data  && queryPerms.data.map(p => {
              return <option key={p.id} value={p.id}>Permission: {p.key_id}</option>;
            })}
          </NativeSelect>
        </FormControl>
        <FormControl fullWidth variant='standard' sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id='grant-server-id'>{t('Choose server context')}</InputLabel>
          <NativeSelect inputProps={{ name: 'Server id', id: 'new-grant-server-id' }}>
            <option value='Global' style={{ color: 'lightblue' }}>{t('Globally')}</option>
            {queryServers.data && queryServers.data.map(p => <option key={p.id} value={p.id}>Server: {p.name} (id={p.id})</option>)}
          </NativeSelect>
        </FormControl>
        <FormControl fullWidth variant='standard' sx={{ m: 1, minWidth: 120 }}>
          <InputLabel
            id='grant-permission-value'>{t('Permission value (allowed or explicitly denied)')}</InputLabel>
          <NativeSelect inputProps={{ name: 'Permission Value', id: 'new-grant-permission-value' }}>
            <option value='true'>True</option>
            <option value='false'>False</option>
          </NativeSelect>
        </FormControl>
        <FormControl fullWidth variant='standard' sx={{ m: 1, minWidth: 120 }}>
          <DatePicker label='Expiration date' name='startDate' defaultValue={null} />
        </FormControl>
        <DialogActions>
          <Button onClick={_e => props.setOpen(false)}>Cancel</Button>
          <Button type='submit' autoFocus>Ok</Button>
        </DialogActions>
      </form>
    </Container>
  </div>
}

export default ModalCreatePermissionGrant;