import React from 'react';


interface Props {
  width?: string,
  height?: string
  noWrap?: boolean
}


export const StarCoinIcon = (props: Props) => {
  if (props.noWrap) {
    return <img style={{width: "1.2em", height: "1.2em"}} src="/starcoin-min.png" alt=""/>
  }
  return <span style={{
    width: props.width ? props.width : "1.3em",
    height: props.height ? props.height : "1.3em",
    marginRight: "4px",
    marginLeft: "4px"
  }}><img style={{width: "100%"}} src="/starcoin-min.png" alt=""/></span>
}