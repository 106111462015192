

export default class IUserSelfInfo {
  readonly id: number;
  readonly username: string;
  readonly uuid: string;
  readonly avatar: URL | null;
  readonly skin: URL | null;
  readonly cloak: URL | null
  readonly date_joined: Date;
  readonly balance: number
  readonly permissions: Set<string>

  constructor(id: number, username: string, uuid: string, avatar: URL | null, skin: URL | null, cloak: URL | null, date_joined: Date, balance: number, permissions: Set<string>) {
    this.id = id;
    this.username = username;
    this.uuid = uuid;
    this.avatar = avatar;
    this.skin = skin;
    this.cloak = cloak;
    this.date_joined = date_joined;
    this.balance = balance;
    this.permissions = permissions;
  }

  public hasPermission(value: string): boolean {
    return this.permissions.has(value);
  }

  public getAvatar(): string {
    return this.avatar === null ? "" : this.avatar.toString();
  }

  public getSkin(): string {
    return this.skin === null ? "" : this.skin.toString();
  }

  public hasCape(): boolean {
    return this.cloak !== null;
  }

  public getCape(): string {
    return this.cloak === null ? "" : this.cloak.toString();
  }

  public updateAvatar(url: string): IUserSelfInfo {
    return new IUserSelfInfo(
      this.id,
      this.username,
      this.uuid,
      new URL(url),
      this.skin,
      this.cloak,
      this.date_joined,
      this.balance,
      this.permissions,
    );
  }
}