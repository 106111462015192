import { useQuery } from 'react-query';
import { getPlayingTop } from '../API';

const usePlayingTimePlayersTop = () => {
  const {
    data: entries,
    isLoading,
    isError,
  } = useQuery(['playing-top'], () => getPlayingTop().then((r) => r.data), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  return { playingTimeEntries: entries, arePlayingTimeEntriesLoading: isLoading, isError };
};

export default usePlayingTimePlayersTop;
