import { useQuery } from 'react-query';
import { fetchIsOnlineOnServer } from '../API';

export const useOwnOnlineStatus = (server: number) => {
  let result = useQuery([`own-online-status-${server}`], () => fetchIsOnlineOnServer(server).then((r) => r.data), {
    keepPreviousData: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: 7000
  });
  return {online: result.data, ...result};
};

export default useOwnOnlineStatus;