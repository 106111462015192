import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActionStatus } from '../../types/ActionStatus';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import * as React from 'react';
import './EmailConfirmation.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import NamedLoaderAbsolute from '../../components/NamedLoaderAbsolute';
import { NotificationContext } from '../../AppRouter';
import { getEmailConfirmation, putEmailConfirmation } from '../../api/email_confirmation';
import { thereWasOurSideError } from '../../stores/notificationContext';
import { getApplicationCode, hasApplicationCode } from '../../utils/responses';
import { ErrorCode } from '../../api/codes';

export default function EmailConfirmation() {
  const params = useParams();
  const { t } = useTranslation();
  const [needConfirm, setNeedConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<ActionStatus | null>(null);
  const notificationContext = useContext(NotificationContext);

  const onClickSync = (_event: any) => {
    const jwt = params.jwt;
    if (jwt === undefined) {
      setStatus({ severity: 'error', message: t('No token provided in URL. Please try follow the link again') });
      return;
    }

    setLoading(true);
    putEmailConfirmation(jwt)
      .then(response => {
        if (response.status === 200) {
          setNeedConfirm(false);
          setStatus({
            severity: 'success',
            message: t('You already confirmed your email. You can leave this page safely'),
          });
          return;
        }
        if (response.status === 201) {
          setNeedConfirm(false);
          setStatus({ severity: 'success', message: t('Confirmation success. You can leave this page safely') });
          return;
        }
      })
      .catch(error => {
        if (thereWasOurSideError(error, notificationContext)) {
          setStatus({
            severity: 'error',
            message: t('Server error occurred, please try again later or contact us in discord'),
          });
          return;
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const jwt = params.jwt;
    if (jwt === undefined) {
      setStatus({ severity: 'error', message: t('No token provided in URL. Please try follow the link again') });
      return;
    }

    setLoading(true);
    getEmailConfirmation(jwt)
      .then(response => {
        if (response.status === 200) {
          setNeedConfirm(true);
          setStatus({ severity: 'warning', message: t('Please confirm your email using the button below') });
          return;
        }
        if (response.status === 201) {
          setNeedConfirm(false);
          setStatus({
            severity: 'success',
            message: t('You already confirmed your email. You can leave this page safely'),
          });
          return;
        }
      })
      .catch(error => {
        if (thereWasOurSideError(error, notificationContext)) {
          setStatus({
            severity: 'error',
            message: t('Server error occurred, please try again later or contact us in discord'),
          });
          return;
        }
        if (hasApplicationCode(error)) {
          switch (getApplicationCode(error)) {
            case ErrorCode.JWT_TOKEN_EXPIRED:
              setStatus({
                severity: 'error',
                message: t('Email confirmation link is expired. Please resend confirmation email'),
              });
              break;
            case ErrorCode.JWT_TOKEN_INVALID:
              setStatus({
                severity: 'error',
                message: t('Invalid or corrupted link, please try follow mail link again'),
              });
              break;
            case ErrorCode.JWT_TOKEN_INVALID_USER_NOT_FOUND:
              setStatus({ severity: 'error', message: t('Invalid or corrupted link: user not found') });
              break;
            case ErrorCode.JWT_TOKEN_INVALID_EMAIL_NOT_MATCHED:
              setStatus({
                severity: 'error',
                message: t('Invalid or corrupted link: email not matched. Probably it was changed'),
              });
              break;
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return <div id='confirmation-sync-wrapper'>
    <Container maxWidth='sm' id='confirmation-sync'>

      <Typography variant='h5' component='h5'>
        🛡️{t('Confirm you email')}🛡️
      </Typography>
      <div style={{ position: 'relative', minHeight: '40px' }}>
        <NamedLoaderAbsolute visible={loading} message='Loading...' />
      </div>
      <Container maxWidth='md' style={{ marginBottom: '20px' }}>
        <Typography
          variant='body1'
          component='p'
          gutterBottom
          style={{ textAlign: 'center', color: 'white', fontWeight: '100' }}
        >
          {t('This is Email verification page 📧. It is needed to confirm your account to play on the servers. Most likely you were navigated here from Mail link')}
        </Typography>
      </Container>

      {status ? <Alert severity={status.severity}>{status.message}</Alert> : null}

      <Button
        color='warning'
        variant='contained'
        sx={{ my: 2, color: 'black', display: 'block' }}
        onClick={onClickSync}
        disabled={loading || !needConfirm}
      >
        {loading ? t('Loading') : t('Confirm email')}
      </Button>

    </Container>
  </div>;
}