import './AdminGroupsPage.css';
import AdminWrapper from '../component/AdminWrapper';
import React, { useCallback, useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  PermissionGrantDTO,
} from '../../../api/admin';
import NamedLoaderAbsolute from '../../../components/NamedLoaderAbsolute';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Group } from '../../../entities/group';
import Button from '@mui/material/Button';
import { AuthContext, NotificationContext } from '../../../AppRouter';
import Paper from '@mui/material/Paper';
import { useServersForAdmin } from '../../../hooks/useServers';
import { useAdminGroups } from './GroupAPI';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import { toCssHexColor } from '../../../utils/dataTypes';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';


const style = {
  width: 100,
  maxWidth: 100,
  overflow: "hidden",
  textOverflow: "ellipsis",
  borderStyle: "border-box"
};


const AdminGroupsPage = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string | null>(null);
  const [permissionToRemove, setPermissionToRemove] = useState<PermissionGrantDTO | null>(null);
  const [newPermissionModalOpen, setPermissionModalOpen] = useState<boolean>(false);
  const [focusedGroup, setFocusedGroup] = useState<Group | null>(null);
  const [newGroupModalOpen, setGroupModalOpen] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const notificationContext = useContext(NotificationContext);
  const queryClient = useQueryClient();
  const authContext = useContext(AuthContext);
  const [actionGroupId, setActionGroupId] = React.useState<null | number>(null);
  const [actionMenuAnchor, setActionMenuAnchor] = React.useState<null | HTMLElement>(null);
  const open = Boolean(actionMenuAnchor);
  const navigate = useNavigate();

  let queryGroups = useAdminGroups();
  let queryServers = useServersForAdmin();

  if (authContext === null) return null;
  if (authContext.user === null) return null;

  const bySearch = useCallback((row: Group) => {
    if (search === null || search === "") return true;
    return row.key_id.startsWith(search);
  }, [search]);

  return (
    <AdminWrapper name='Groups' link='/admin-panel/groups'>
      <div style={{ position: 'relative' }}>

        {queryGroups.isLoading ? <NamedLoaderAbsolute message={t('Loading groups')} /> : null}
        {queryGroups.isFetched && queryGroups.data && <List dense>
          <Grid container spacing={2}>

            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '8px 12px', width: "100%" }}>
              <TextField id="outlined-search" label="Search field" type="search" size="small" onChange={event => setSearch(event.currentTarget.value)}/>
              <Button variant='contained' style={{ height: '2em' }} onClick={() => {
                setGroupModalOpen(true);
              }} size='small' color='success'>
                {t('Create group')}
              </Button>
            </Box>

            <Menu
              id="group-actions-menu"
              anchorEl={actionMenuAnchor}
              open={open}
              onClose={() => setActionMenuAnchor(null)}
              MenuListProps={{ 'aria-labelledby': 'basic-button'}}
            >
              <MenuItem onClick={() => {}}>{t("Manage Permissions")}</MenuItem>
              <MenuItem onClick={() => {actionGroupId !== null && navigate(`/admin-panel/groups/${actionGroupId}/attributes`)}}>{t("Manage Attributes")}</MenuItem>
              <MenuItem onClick={() => {}}>{t("Manage Members")}</MenuItem>
            </Menu>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} size='small' aria-label='Groups table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='left' size="small" style={style}>ID</TableCell>
                    <TableCell align='left' size="small">Key</TableCell>
                    <TableCell align='left' size="small">Name</TableCell>
                    <TableCell align='left' size="small">Weight</TableCell>
                    <TableCell align='right' size="small">Discord</TableCell>
                    <TableCell align='right' size="small">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {queryGroups.data.filter(bySearch).map((row) => (
                    <TableRow
                      key={row.id}
                    >
                      <TableCell align='left' component='th' scope='row' size="small" style={style}>

                        <span
                          id='basic-button'
                          style={{color: "lightblue", fontWeight: "bold", cursor: "pointer", textDecoration: "underline"}}
                          onClick={(event) => {
                            navigate(`/admin-panel/groups/${row.id}/details`)
                          }}
                        >
                          {row.id}
                        </span>

                      </TableCell>
                      <TableCell align='left' size="small" style={{color: toCssHexColor(row.color)}}>{row.key_id}</TableCell>
                      <TableCell align='left'>{JSON.stringify(row.name)}</TableCell>
                      <TableCell align='left'>{row.weight}</TableCell>
                      <TableCell align='right' size="small">{row.discord_sync ? <CheckIcon fontSize="small" color="primary"/> : null}</TableCell>
                      <TableCell align='right' size="small">
                        <IconButton style={{padding: 0}} onClick={e => {setActionMenuAnchor(e.currentTarget); setActionGroupId(row.id)}} size="small" color="secondary" aria-label="add an alarm">
                          <MoreHorizIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/*<Grid item xs={7} md={7}>*/}
            {/*<Card sx={{ minWidth: 275 }}>*/}
            {/*  <CardContent>*/}
            {/*    {*/}
            {/*      selectedGroup === null*/}
            {/*        ? <Typography variant='h6' gutterBottom>{t('Please select group')}</Typography>*/}
            {/*        :*/}
            {/*        <>*/}
            {/*          <div style={{ display: 'flex', justifyContent: 'space-between' }}>*/}
            {/*            <Typography variant='h6' gutterBottom>*/}
            {/*              {hasSnapshot(selectedGroup) ? t('Editing group') : t('Group info')}*/}
            {/*            </Typography>*/}

            {/*            {hasSnapshot(selectedGroup) &&*/}
            {/*              <IconButton onClick={() => removeSnapshot(selectedGroup)} color='primary'*/}
            {/*                          aria-label='Edit group'>*/}
            {/*                <EditOffOutlinedIcon fontSize='small' />*/}
            {/*              </IconButton>*/}
            {/*            }*/}
            {/*            {!hasSnapshot(selectedGroup) &&*/}
            {/*              <Tooltip*/}
            {/*                title={authContext.user.hasPermission('web.admin-panel.manage-groups') ? 'Edit' : 'No permission'}>*/}
            {/*                <span*/}
            {/*                  style={{ cursor: authContext.user.hasPermission('web.admin-panel.manage-groups') ? 'pointer' : 'not-allowed' }}>*/}
            {/*                  <IconButton onClick={() => addSnapshot(selectedGroup)} color='primary'*/}
            {/*                              aria-label='Edit group'*/}
            {/*                              disabled={!authContext.user.hasPermission('web.admin-panel.manage-groups')}*/}
            {/*                  >*/}
            {/*                  <EditOutlinedIcon fontSize='small' />*/}
            {/*                </IconButton>*/}
            {/*                </span>*/}
            {/*              </Tooltip>*/}
            {/*            }*/}
            {/*          </div>*/}
            {/*          {hasSnapshot(selectedGroup)*/}
            {/*            ? <TextField key={'  mutable_key' + selectedGroup.id} margin='dense' fullWidth*/}
            {/*                         variant='outlined' size="small" label='Key id'*/}
            {/*                         defaultValue={getSnapshot(selectedGroup).key_id}*/}
            {/*                         onChange={(event) => getSnapshot(selectedGroup).key_id = event.target.value} />*/}
            {/*            : <TextField key={'immutable_key' + selectedGroup.id} margin='dense' fullWidth*/}
            {/*                         variant='outlined' size="small" label='Key id' value={selectedGroup.key_id} disabled />*/}
            {/*          }*/}

            {/*          /!* ========= NAMES LOCALIZATION ========= *!/*/}
            {/*          <Typography variant='caption' gutterBottom>{t('Name localized')}</Typography>*/}
            {/*          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>*/}
            {/*            {hasSnapshot(selectedGroup)*/}
            {/*              ? <TextField key={'  mutable_key_en' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='EN' size='small'*/}
            {/*                           defaultValue={getSnapshot(selectedGroup).name.EN}*/}
            {/*                           onChange={(event) => getSnapshot(selectedGroup).name.EN = event.target.value}*/}
            {/*              />*/}
            {/*              : <TextField key={'immutable_key_en' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='EN' size='small'*/}
            {/*                           value={selectedGroup.name.EN} disabled />*/}
            {/*            }*/}
            {/*            {hasSnapshot(selectedGroup)*/}
            {/*              ? <TextField key={'  mutable_key_ru' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='RU' size='small'*/}
            {/*                           defaultValue={getSnapshot(selectedGroup).name.RU}*/}
            {/*                           onChange={(event) => getSnapshot(selectedGroup).name.RU = event.target.value}*/}
            {/*              />*/}
            {/*              : <TextField key={'immutable_key_ru' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='RU' size='small'*/}
            {/*                           value={selectedGroup.name.RU} disabled />*/}
            {/*            }*/}
            {/*            {hasSnapshot(selectedGroup)*/}
            {/*              ? <TextField key={'  mutable_key_uk' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='UK' size='small'*/}
            {/*                           defaultValue={getSnapshot(selectedGroup).name.UK}*/}
            {/*                           onChange={(event) => getSnapshot(selectedGroup).name.UK = event.target.value}*/}
            {/*              />*/}
            {/*              : <TextField key={'immutable_key_uk' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='UK' size='small'*/}
            {/*                           value={selectedGroup.name.UK} disabled />*/}
            {/*            }*/}
            {/*          </Box>*/}
            {/*          /!* ========= NAMES LOCALIZATION END ========= *!/*/}

            {/*          /!* ========= PREFIXES LOCALIZATION ========= *!/*/}
            {/*          <Typography variant='caption' gutterBottom>{t('Prefix localized')}</Typography>*/}
            {/*          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>*/}
            {/*            {hasSnapshot(selectedGroup)*/}
            {/*              ? <TextField key={'  mutable_key_en' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='EN' size='small'*/}
            {/*                           defaultValue={getSnapshot(selectedGroup).prefix?.EN}*/}
            {/*                           onChange={(event) => {*/}
            {/*                             let prefix = getSnapshot(selectedGroup).prefix*/}
            {/*                             if (prefix) {*/}
            {/*                               prefix.EN = event.target.value*/}
            {/*                             }*/}
            {/*                           }}*/}
            {/*              />*/}
            {/*              : <TextField key={'immutable_key_ru' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='EN' size='small'*/}
            {/*                           value={selectedGroup.prefix?.EN} disabled />*/}
            {/*            }*/}
            {/*            {hasSnapshot(selectedGroup)*/}
            {/*              ? <TextField key={'  mutable_key_ru' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='RU' size='small'*/}
            {/*                           defaultValue={getSnapshot(selectedGroup).prefix?.RU}*/}
            {/*                           onChange={(event) => {*/}
            {/*                             let prefix = getSnapshot(selectedGroup).prefix*/}
            {/*                             if (prefix) {*/}
            {/*                               prefix.RU = event.target.value*/}
            {/*                             }*/}
            {/*                           }}*/}
            {/*              />*/}
            {/*              : <TextField key={'immutable_key_en' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='RU' size='small'*/}
            {/*                           value={selectedGroup.prefix?.RU} disabled />*/}
            {/*            }*/}
            {/*            {hasSnapshot(selectedGroup)*/}
            {/*              ? <TextField key={'  mutable_key_uk' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='UK' size='small'*/}
            {/*                           defaultValue={getSnapshot(selectedGroup).prefix?.UK}*/}
            {/*                           onChange={(event) => {*/}
            {/*                             let prefix = getSnapshot(selectedGroup).prefix*/}
            {/*                             if (prefix) {*/}
            {/*                               prefix.UK = event.target.value*/}
            {/*                             }*/}
            {/*                           }}*/}
            {/*              />*/}
            {/*              : <TextField key={'immutable_key_uk' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='UK' size='small'*/}
            {/*                           value={selectedGroup.prefix?.UK} disabled />*/}
            {/*            }*/}
            {/*          </Box>*/}
            {/*          /!* ========= PREFIXES LOCALIZATION END ========= *!/*/}

            {/*          /!* ========= SHORT PREFIXES LOCALIZATION ========= *!/*/}
            {/*          <Typography variant='caption' gutterBottom>{t('Short prefix localized')}</Typography>*/}
            {/*          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>*/}
            {/*            {hasSnapshot(selectedGroup)*/}
            {/*              ? <TextField key={'  mutable_key_en1' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='EN' size='small'*/}
            {/*                           defaultValue={getSnapshot(selectedGroup).short_prefix?.EN}*/}
            {/*                           onChange={(event) => {*/}
            {/*                             let short_prefix = getSnapshot(selectedGroup).short_prefix*/}
            {/*                             if (short_prefix) {*/}
            {/*                               short_prefix.EN = event.target.value*/}
            {/*                             }*/}
            {/*                           }}*/}
            {/*              />*/}
            {/*              : <TextField key={'immutable_key_ru1' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='EN' size='small'*/}
            {/*                           value={selectedGroup.short_prefix?.EN} disabled />*/}
            {/*            }*/}
            {/*            {hasSnapshot(selectedGroup)*/}
            {/*              ? <TextField key={'  mutable_key_ru1' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='RU' size='small'*/}
            {/*                           defaultValue={getSnapshot(selectedGroup).short_prefix?.RU}*/}
            {/*                           onChange={(event) => {*/}
            {/*                             let short_prefix = getSnapshot(selectedGroup).short_prefix*/}
            {/*                             if (short_prefix) {*/}
            {/*                               short_prefix.RU = event.target.value*/}
            {/*                             }*/}
            {/*                           }}*/}
            {/*              />*/}
            {/*              : <TextField key={'immutable_key_en1' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='RU' size='small'*/}
            {/*                           value={selectedGroup.short_prefix?.RU} disabled />*/}
            {/*            }*/}
            {/*            {hasSnapshot(selectedGroup)*/}
            {/*              ? <TextField key={'  mutable_key_uk1' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='UK' size='small'*/}
            {/*                           defaultValue={getSnapshot(selectedGroup).short_prefix?.UK}*/}
            {/*                           onChange={(event) => {*/}
            {/*                             let short_prefix = getSnapshot(selectedGroup).short_prefix*/}
            {/*                             if (short_prefix) {*/}
            {/*                               short_prefix.UK = event.target.value*/}
            {/*                             }*/}
            {/*                           }}*/}
            {/*              />*/}
            {/*              : <TextField key={'immutable_key_uk1' + selectedGroup.id} margin='dense'*/}
            {/*                           variant='outlined' label='UK' size='small'*/}
            {/*                           value={selectedGroup.short_prefix?.UK} disabled />*/}
            {/*            }*/}
            {/*          </Box>*/}
            {/*          /!* ========= SHORT PREFIXES LOCALIZATION END ========= *!/*/}

            {/*          <Box sx={{ display: 'flex', justifyContent: 'center' }} m="10px">*/}
            {/*            <Button*/}
            {/*              onClick={() => {}}*/}
            {/*              disabled={!hasSnapshot(selectedGroup)}*/}
            {/*              variant="outlined"*/}
            {/*              sx={{opacity: hasSnapshot(selectedGroup) ? 100 : 0, transition: "0.3s ease-in-out"}}>*/}
            {/*              {t("Confirm")}*/}
            {/*            </Button>*/}
            {/*          </Box>*/}
            {/*          <Table sx={{ minWidth: 400 }} size='small' aria-label='Permissions table'*/}
            {/*                 key={selectedGroup.id}>*/}
            {/*            <TableHead>*/}
            {/*              <TableRow>*/}
            {/*                <TableCell align='left'>Key</TableCell>*/}
            {/*                <TableCell align='left'>Value</TableCell>*/}
            {/*                <TableCell align='left'>Server</TableCell>*/}
            {/*                <TableCell align='left'>World</TableCell>*/}
            {/*                <TableCell align='left'>Expire</TableCell>*/}
            {/*                <TableCell align='center'>*/}
            {/*                  <Tooltip*/}
            {/*                    title={authContext.user.hasPermission('web.admin-panel.manage-groups') ? 'Add permission' : 'No permission'}>*/}
            {/*                    <span*/}
            {/*                      style={{ cursor: authContext.user.hasPermission('web.admin-panel.manage-groups') ? 'pointer' : 'not-allowed' }}>*/}
            {/*                      <IconButton onClick={() => {*/}
            {/*                        setPermissionModalOpen(true);*/}
            {/*                      }} color='primary' aria-label='Edit group' style={{ padding: 0 }}*/}
            {/*                                  disabled={!authContext.user.hasPermission('web.admin-panel.manage-groups')}>*/}
            {/*                        <AddOutlinedIcon fontSize='small' />*/}
            {/*                      </IconButton>*/}
            {/*                    </span>*/}
            {/*                  </Tooltip>*/}
            {/*                </TableCell>*/}
            {/*              </TableRow>*/}
            {/*            </TableHead>*/}
            {/*            <TableBody>*/}
            {/*              {selectedGroup.permission_grants.map((row) => (*/}
            {/*                <TableRow*/}
            {/*                  key={'grant' + JSON.stringify(row)}*/}
            {/*                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}*/}
            {/*                >*/}
            {/*                  <TableCell component='th' scope='row'>{row.permission_key}</TableCell>*/}
            {/*                  <TableCell component='th' scope='row'>{row.value ? TRUE : FALSE}</TableCell>*/}
            {/*                  <TableCell align='left'>{row.server_id ? row.server_id : t('Global')}</TableCell>*/}
            {/*                  <TableCell align='left'>{row.world_id ? row.server_id : t('Global')}</TableCell>*/}
            {/*                  <TableCell align='left'>{row.expire ? row.expire : t('Never')}</TableCell>*/}
            {/*                  <TableCell align='center'>*/}
            {/*                    {authContext.user && <Tooltip*/}
            {/*                      title={authContext.user.hasPermission('web.admin-panel.manage-groups') ? 'Add permission' : 'No permission'}>*/}
            {/*                      <span*/}
            {/*                        style={{ cursor: authContext.user.hasPermission('web.admin-panel.manage-groups') ? 'pointer' : 'not-allowed' }}>*/}
            {/*                        <IconButton onClick={() => {*/}
            {/*                          setPermissionToRemove(row);*/}
            {/*                        }} color='primary' aria-label='Edit group' style={{ padding: 0 }}*/}
            {/*                                    disabled={!authContext.user.hasPermission('web.admin-panel.manage-groups')}>*/}
            {/*                          <RemoveOutlinedIcon fontSize='small' />*/}
            {/*                        </IconButton>*/}
            {/*                      </span>*/}
            {/*                    </Tooltip>}*/}

            {/*                  </TableCell>*/}
            {/*                </TableRow>*/}
            {/*              ))}*/}
            {/*            </TableBody>*/}
            {/*          </Table>*/}
            {/*        </>*/}
            {/*    }*/}
            {/*  </CardContent>*/}
            {/*</Card>*/}
            {/*</Grid>*/}

          </Grid>
        </List>}
      </div>
    </AdminWrapper>
  );
};

export default AdminGroupsPage;