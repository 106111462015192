import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import * as React from 'react';

export default function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link className="small-link" color="inherit" to="https://starshine-project.ru/">
        Starshine Project
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}