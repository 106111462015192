import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ActionStatus } from '../../types/ActionStatus';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import './DiscordSync.css'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useSyncInfo from '../../hooks/useSyncInfo';
import { deleteSync, putSync } from '../../API';
import NamedLoaderAbsolute from '../../components/NamedLoaderAbsolute';
import { NotificationContext } from '../../AppRouter';
import Divider from '@mui/material/Divider';
import { thereWasOurSideError } from '../../stores/notificationContext';

export default function DiscordSync() {
  const [searchParams, _setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [unsyncVisible, setUnsyncVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<ActionStatus | null>(null);
  const {data} = useSyncInfo();
  const notificationContext = useContext(NotificationContext);

  useEffect(() => {
    const code = searchParams.get("code");
    if (code === null) {
      setStatus({severity: "error", message: t("No code parameter passed by Discord. Please try again.")})
      return
    }
    if (data === undefined) return;

    if (data.status === 200) {
      setStatus({severity: "success", message: t("Account is already synced")})
      setUnsyncVisible(true);
      return;
    }
    if (data.status === 404) {
      setStatus(null)
      return;
    }
  }, [data, t, searchParams])

  const onClickSync = (_e: any) => {
    const code = searchParams.get("code");
    if (code === null) {
      setStatus({severity: "error", message: t("No code parameter passed by Discord. Please try again.")})
      return
    }

    setLoading(true)
    putSync(code)
      .then(_r => {
        setStatus({severity: "success", message: t("Account synced successfully, you can leave this page")})
      })
      .catch(err => {
        if (thereWasOurSideError(err, notificationContext)) return;

        if (err.response) {
          if (err.response.status === 400) {
            if (err.response.data.code === 10002) {
              setStatus({severity: "error", message: t("Invalid code received from discord. Please try sync again")})
            }
          }
          if (err.response.status === 409) {
            setStatus({severity: "error", message: t("Account is already synced, please try reload the page")})
          }
        }
      })
      .finally(() => setLoading(false))
  }

  const onClickUnsync = (_e: any) => {
    setLoading(true)
    deleteSync()
      .then(_r => {
        setStatus({severity: "success", message: t("Account was successfully unsynced")})
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 404) {
            setStatus({severity: "error", message: t("Account is not synced, please try reload the page")})
          }
        }
      })
      .finally(() => setLoading(false))
  }

  return <div id="discord-sync-wrapper">
    <Container maxWidth='sm' id="discord-sync">

      <Typography variant="h5" component="h5">
        {t("Sync starshine account with discord")}
      </Typography>

      <Container maxWidth='md' style={{ marginBottom: '20px', marginTop: "30px",  }}>
        <Typography
          variant='body1'
          component='p'
          gutterBottom
          style={{ textAlign: 'center', color: 'white', fontWeight: '100', opacity: loading ? "0" : "100" }}
        >
          {t('This is Discord sync page. It is necessary to sync your account to be able to chat in discord minecraft channels. Most likely you were navigated here from Discord sync link')}
        </Typography>
        <NamedLoaderAbsolute visible={loading} message="Syncing..."/>
        <Divider />
        {status !== null ? null : <Typography
          variant='body1'
          component='p'
          gutterBottom
          style={{ textAlign: 'center', color: 'white', fontWeight: '100', marginTop: "16px", opacity: loading ? "0" : "100" }}
        >
          {t('Press button below to sync')}
        </Typography>}
      </Container>

      {status ? <Alert severity={status.severity}>{status.message}</Alert> : null}

      {status !== null ? null : <Button
        color="success"
        variant="contained"
        sx={{ my: 2, color: 'black', display: 'block' }}
        onClick={onClickSync}
        disabled={loading}
      >
        {t("Sync account")}
      </Button> }

      <Typography variant="h6" component="p" sx={{color: "crimson"}}>
        {unsyncVisible ? <Button
          color="error"
          variant="contained"
          sx={{ my: 2, color: 'black', display: 'block' }}
          disabled={loading}
          onClick={onClickUnsync}
        >
          {t("Unsync account")}
        </Button>  : null}
      </Typography>

    </Container>
  </div>
}