import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import * as React from 'react';
import { FormEvent, useContext, useEffect, useState } from 'react';
import NamedLoader from '../../components/NamedLoader';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import baseAPI from '../../baseAPI';
import { IAvatarUpdatedResponse } from '../../types/IUser';
import { AxiosError } from 'axios';
import { StarshineHeader } from '../../components/StarshineHeader';
import { useTranslation } from 'react-i18next';
import NamedLoaderAbsolute from '../../components/NamedLoaderAbsolute';
import PieChartPlayerTime from '../../components/statistics/PieChartPlayerTime';
import Container from '@mui/material/Container';
import { AuthContext, NotificationContext } from '../../AppRouter';
import { AuthState } from '../../stores/authContext';
import { notification } from '../../stores/notificationContext';


interface ModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  defaultImage: string;
}


interface AvatarUpdateStatus {
  severity: 'error' | 'success';
  message: string;
}


const getAvatarUpdateStatusFrom = (error: AxiosError) => {
  // @ts-ignore
  console.error('error', error.response);
  // @ts-ignore
  if (error.response?.data.avatar) {
    // @ts-ignore
    return error.response?.data.avatar;
  }
  // @ts-ignore
  if (error.response?.data.message) {
    // @ts-ignore
    return error.response?.data.message;
  }
  // @ts-ignore
  if (error.response?.status === 500) {
    return 'Ошибка на стороне сервера. Постараемся исправить ее в ближайшее время 🥺. Либо попробуйте позже, либо (если этот аватар действительно того стоит) обратитесь за помощью в дискорд.';
  }
  if (error.response?.status === 503) {
    return 'Сервера недосутпны, пожалуйста попробуйте позже 🥺';
  }
  if (error.response?.status === 502) {
    return 'Ошибка на стороне сервера. Пожалуйста попробуйте позже 🥺';
  }
  return error.toString();
};


function AvatarUpdateModal({ open, setOpen, defaultImage }: ModalProps) {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [updateStatus, setUpdateStatus] = useState<AvatarUpdateStatus | null>(null);
  const handleClose = () => {setUpdateStatus(null); setOpen(false)};
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [avatarPatchIsLoading, setAvatarPatchIsLoading] = useState<boolean>(false);
  const authContext = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);
  const {t} = useTranslation();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(defaultImage);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [defaultImage, selectedFile]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedFile === undefined) return;
    // @ts-ignore
    const request = new FormData();
    request.append('avatar', selectedFile, selectedFile.name);

    setAvatarPatchIsLoading(true);
    baseAPI.put<IAvatarUpdatedResponse>(`/api/users/@me/avatar/`, request, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        if (authContext.user !== null) {
          authContext.setUser(authContext.user.updateAvatar(response.data.avatar));
        }
        notification("success", t("Avatar updated"), null, 3000, notificationContext);
        setOpen(false);
      })
      .catch((error) => {
        setUpdateStatus({ severity: 'error', message: getAvatarUpdateStatusFrom(error) });
      })
      .finally(() => {
        setAvatarPatchIsLoading(false);
      });
  };

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby='keep-mounted-modal-title'
      aria-describedby='keep-mounted-modal-description'
    >
      <Box sx={style}>
        <Typography id='keep-mounted-modal-title' variant='h6' component='h2' textAlign='center'>
          {t("Avatar update form")}
        </Typography>
        <form onSubmit={onSubmit} style={{ textAlign: 'center', marginTop: '2em', position: 'relative' }}>
          <img style={{
            width: '300px',
            transition: "0.3s in",
            height: '300px',
            background: '#333',
            objectFit: 'cover',
            opacity: avatarPatchIsLoading ? "0%" : "100%"
          }} src={preview} alt='avatar' />
          <input accept='image/png, image/jpeg' type='file' onChange={(e) => {
            if (!e.target.files || e.target.files.length === 0) {
              setSelectedFile(undefined);
              return;
            }
            setSelectedFile(e.target.files[0]);
          }} />
          {updateStatus ? <Alert severity={updateStatus.severity} sx={{ width: '100%' }}>
            {updateStatus.message}
          </Alert> : null}
          <NamedLoaderAbsolute message='Uploading Avatar...' visible={avatarPatchIsLoading} />
          <Button
            style={{ marginTop: '1em' }}
            variant='contained'
            color='warning'
            type='submit'
            onClick={() => setOpen(true)}
            disabled={selectedFile === undefined || avatarPatchIsLoading}
          >
            {selectedFile === undefined ? 'Выберите аватар' : 'Обновить аватар'}
          </Button>
        </form>
      </Box>
    </Modal>
  );
}


export const SettingsProfile = () => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);

  if (authContext.state == AuthState.LOADING) return <div style={{ padding: '4em' }}><NamedLoader message='Loading Profile' /></div>;
  return <div>
    <StarshineHeader message={t('account-settings')} />
    <Grid container spacing={3}>
      <AvatarUpdateModal open={open} setOpen={setOpen}
                         defaultImage={authContext.user !== null ? authContext.user.getAvatar() : ''} />
      <Grid item xs>
      </Grid>
      <Grid id='avatar-setting' item xs={6}>
        <div id='avatar-image'>
          <img id='user-avatar-image' src={authContext.user !== null ? authContext.user.getAvatar() : ''} alt='' />
        </div>
        <Button
          variant='contained'
          color='warning'
          onClick={() => {setOpen(true)}}
        >
          Обновить аватар
        </Button>
      </Grid>
      <Grid item xs>
      </Grid>
    </Grid>
    <StarshineHeader message={t("Playing time statistics")}/>
    <Container maxWidth="md">
      {authContext.user !== null ? <PieChartPlayerTime user_id={authContext.user.id}/> : null}
    </Container>

  </div>;
};