import './AdminGroupsPage.css';
import AdminWrapper from '../component/AdminWrapper';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Group } from '../../../entities/group';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { AuthContext, NotificationContext } from '../../../AppRouter';
import { notification } from '../../../stores/notificationContext';
import Paper from '@mui/material/Paper';
import { useAdminGroupsMap } from './GroupAPI';
import Box from '@mui/material/Box';
import ModalEditGroupForm from './ModalEditGroupForm';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import ModalDeletePermissionGrant from './ModalDeletePermissionGrant';
import ModalCreatePermissionGrant from './ModalCreatePermissionGrant';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import { PermissionGrantDTO } from '../../../api/admin';
import AddOutlinedIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { useAttributeModifiers, useAttributes } from '../../../hooks/usePermissions';


const AdminGroupInfoPage = () => {
  const { t } = useTranslation();
  const params = useParams();

  const groupID = Number(params.id);

  const attributes = useAttributes();
  const modifiers = useAttributeModifiers(groupID);
  const [focusedGroup, setFocusedGroup] = useState<Group | null>(null);
  const [focusedGrant, setFocusedGrant] = useState<PermissionGrantDTO | null>(null);
  const [focusedGroupJSON, setFocusedGroupJSON] = useState<string>('');
  const [grantRemoveModalOpen, setGrantRemoveModalOpen] = useState<boolean>(false);
  const [grantCreateModalOpen, setGrantCreateModalOpen] = useState<boolean>(false);
  const [groupChangeModalOpen, setGroupChangeModalOpen] = useState<boolean>(false);
  const notificationContext = useContext(NotificationContext);
  const authContext = useContext(AuthContext);

  let queryGroups = useAdminGroupsMap();

  useEffect(() => {
    if (queryGroups.isFetched && queryGroups.data) {
      let group1 = queryGroups.data.get(groupID);
      if (!group1) {
        setFocusedGroupJSON('');
        return;
      }

      setFocusedGroup(group1);

      let group = { ...group1 };
      // @ts-ignore
      delete group.permission_grants;
      setFocusedGroupJSON(JSON.stringify(group, null, 2));
    }
  }, [queryGroups]);

  const onEdited = (group: Group) => {
    setGroupChangeModalOpen(false);
    notification('success', t('Group changed'), null, 3000, notificationContext);
    queryGroups.refetch();
  };

  if (authContext.user == null) return null;
  const permission = authContext.user.hasPermission('web.admin-panel.manage-groups');

  return (
    <AdminWrapper name='Group info' link='/admin-panel/groups'>
      <div style={{ position: 'relative' }}>

        {
          focusedGroup && <Dialog open={grantCreateModalOpen} onClose={() => setGrantCreateModalOpen(false)}
                                  aria-labelledby='alert-dialog-title'
                                  aria-describedby='alert-dialog-description'>
            <ModalCreatePermissionGrant group={focusedGroup} onCreated={() => {
              queryGroups.refetch();
            }} setOpen={setGrantCreateModalOpen} />
          </Dialog>
        }

        {
          focusedGroup && focusedGrant &&
          <Dialog open={grantRemoveModalOpen} onClose={() => setGrantRemoveModalOpen(false)}
                  aria-labelledby='alert-dialog-title'
                  aria-describedby='alert-dialog-description'>
            <ModalDeletePermissionGrant group={focusedGroup} grant={focusedGrant} setOpen={setGrantRemoveModalOpen} />
          </Dialog>
        }

        {
          focusedGroup && <Dialog open={groupChangeModalOpen} onClose={() => setGroupChangeModalOpen(false)}>
            <ModalEditGroupForm onEdited={onEdited} initialGroup={focusedGroup} />
          </Dialog>
        }

        <Box style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '8px 12px',
          width: '100%',
        }}>
          <div></div>
          <Button variant='contained' style={{ height: '2em' }} onClick={() => {
            setGroupChangeModalOpen(true);
          }} size='small' color='warning'>
            {t('Edit group')}
          </Button>
        </Box>

        <Box style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 0', minWidth: '90%', margin: '2px' }}>
            <Typography variant='caption' gutterBottom>{t('User JSON representation')}</Typography>
            <TextField id='json_repr'
                       label='read-only'
                       multiline minRows={5}
                       aria-readonly={true}
                       fullWidth margin='dense'
                       variant='outlined' size='small'
                       value={focusedGroupJSON} />
          </div>
        </Box>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='left'>ID</TableCell>
                <TableCell align='left'>Permission</TableCell>
                <TableCell align='left'>Value</TableCell>
                <TableCell align='left'>Server Context</TableCell>
                <TableCell align='left'>World Context</TableCell>
                <TableCell align='left'>Expiration</TableCell>
                <TableCell align='left'>Granted At</TableCell>
                <TableCell align='left'>
                  <Tooltip
                    title={permission ? 'Add membership' : 'No permission'}>
                          <span
                            style={{ cursor: permission ? 'pointer' : 'not-allowed' }}>
                            <IconButton onClick={() => {
                              setGrantCreateModalOpen(true);
                            }}
                                        color='primary'
                                        aria-label='Edit group'
                                        style={{ padding: 0 }}
                                        disabled={!permission}>
                              <AddOutlinedIcon fontSize='small' />
                            </IconButton>
                          </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {focusedGroup && focusedGroup.permission_grants.map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align='left'>{row.id}</TableCell>
                  <TableCell align='left'>{row.permission_key}</TableCell>
                  <TableCell align='left'>{row.value}</TableCell>
                  <TableCell align='left'>{row.server_id}</TableCell>
                  <TableCell align='left'>{row.world_id}</TableCell>
                  <TableCell align='left'>{row.expire}</TableCell>
                  <TableCell align='left'>{row.granted_at}</TableCell>
                  <TableCell align='left'>
                      <span style={{ cursor: permission ? 'pointer' : 'not-allowed' }}>
                        <IconButton onClick={() => {
                          setFocusedGrant(row);
                          setGrantRemoveModalOpen(true);
                        }}
                                    color='primary'
                                    aria-label='Edit group'
                                    style={{ padding: 0 }}
                                    disabled={!permission}>
                                  <RemoveIcon fontSize='small'
                                  />
                                </IconButton>
                              </span>
                  </TableCell>
                </TableRow>
              ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </AdminWrapper>
  );
};

export default AdminGroupInfoPage;