import "./AdminAuditPage.css"
import AdminWrapper from '../component/AdminWrapper';
import React from 'react';


const AdminAuditPage = () => {
  return (
    <AdminWrapper name="Audit log" link="/admin-panel/audit">
      <div>AuditPage</div>
    </AdminWrapper>
  )
}

export default AdminAuditPage