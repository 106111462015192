import baseAPI from './baseAPI';
import { CSRF_TOKEN_COOKIE_KEY } from './stores/constants';
import ICSRFToken from './types/ICSRFToken';
import IDonationTopEntry from './types/IDonationTopEntry';
import IPaginatedResponse from './types/IPaginatedResponse';
import IPlayingTopEntry from './types/IPlayingTopEntry';
import IServer from './types/IServer';
import IServerPlayingTime from './types/IServerPlayingTime';
import IUser, { IAvatarUpdatedResponse, prepareIdString } from './types/IUser';
import { IProduct } from './types/IProduct';
import { IPhoto } from './types/IPhoto';
import { IInventoryEntry } from './types/IInventoryEntry';
import { IPaymentRedirect } from './types/IPaymentRedirect';
import { IServerMonitoringSample } from './types/IServerMonitoringSample';
import sleep from './utils/sleep';
import { baseAPI_V2 } from './baseAPIV2';
import ServerAdminInfo from './entities/server';

export enum Status {
  NOT_ENOUGH_BALANCE = 6969
}

export function getCookie(name: string): string | null {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function setCookie(name: string, value: string, days: number) {
  let expires = '';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export const getSelf = () => baseAPI.get<IUser>('/api/users/@me/', { withCredentials: true });

export const getTotalPlayingTime = async (id: string) => {
  const identifier = prepareIdString(id);
  const response = await baseAPI.get<IServerPlayingTime[]>(
    `/api/users/${identifier}/total_playing_time/`,
    {
      withCredentials: true,
    },
  );
  return response.data;
};

export const getUser = (identifier: string) =>
  baseAPI.get<IUser>(`/api/users/${identifier}/`, { withCredentials: true });


export const updateCSRFToken = async () => {
  const token = (await baseAPI.get<ICSRFToken>('/api/accounts/csrf_token/')).data.token;
  setCookie(CSRF_TOKEN_COOKIE_KEY, token, 120);
};



export const buy = async (product_id: number) => {
  await updateCSRFToken();
  return await baseAPI.put(`/api/products/${product_id}/buy/`, {});
};


export const getAllServers = () => baseAPI.get<IPaginatedResponse<IServer>>('/api/servers/');

export const getServer = (id: number) => baseAPI.get<IServer>(`/api/servers/${id}/`);

export const getAllServersForAdminPanel = () => baseAPI_V2.get<ServerAdminInfo[]>(`/api/v2/servers`);


export async function getInventory() {
  await sleep(4000)
  return baseAPI.get<IInventoryEntry[]>(`/api/users/@me/inventory/?page=1`);
}

export const getEnotPaymentRedirect = (total: number) => {
  return baseAPI.get<IPaymentRedirect>(`/api/enotio/payment/?donation-sum=${total}&no-redirect=true`);
};

export const updateSkin = (file: File) => {
  const request = new FormData();
  request.append('skin', file, file.name);
  return baseAPI.put<IAvatarUpdatedResponse>(`/api/users/@me/skin/`, request, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};


export const getPhotos = (server: number | undefined) => {
  if (server !== undefined) return baseAPI.get<IPaginatedResponse<IPhoto>>(`/api/photos/?server=${server}`);

  return baseAPI.get<IPaginatedResponse<IPhoto>>(`/api/photos/`);
};

export const getOnline = (server: number | undefined) => {
  return baseAPI.get<IServerMonitoringSample[]>(`/api/servers/${server}/online/`);
};

export const putItemInInventory = (server_id: number, item_id: number) => {
  return baseAPI.put(`/api/items/${item_id}/in_inventory/${server_id}/`);
};

export const getPasswordResetInfo = (user_id: number, token: string) => {
  return baseAPI.get<{ username: string }>(`/api/accounts/password_reset_info/?user_id=${user_id}&token=${token}`);
};

export const putNewPassword = (user_id: number, token: string, password: string) => {
  return baseAPI.put<{ username: string }>(`/api/accounts/new_password/`, { user: user_id, token, password });
};

export const requestPasswordReset = (username: string) => {
  return baseAPI.post(`/api/accounts/password_reset_forgot/`, { username: username });
};

export const fetchIsOnlineOnServer = (server: number) => {
  return baseAPI.get<{ result: boolean }>(`/api/users/@me/is_online/on_server/${server}/`);
};

export const getSelfSyncInfo = () => {
  return baseAPI.get(`/api/accounts/discord/sync/info/`, {
    validateStatus: (status: number) => {return (status >= 200 && status < 300) || status === 404;},
  });
};

export const putSync = (code: string) => {
  return baseAPI.put(`/api/accounts/discord/sync/`, {code: code});
};

export const deleteSync = () => {
  return baseAPI.post(`/api/accounts/discord/unsync/`);
};

export const getAllProducts = (server: IServer | null) => {
  if (server === null)
    return baseAPI.get<IPaginatedResponse<IProduct>>(`/api/products/`);
  else {
    return baseAPI.get<IPaginatedResponse<IProduct>>(`/api/products/?server=${server.id}`);
  }
};

export const getPlayingTop = () =>
  baseAPI.get<IPlayingTopEntry[]>('/api/users/top_players_by_time/');

export const getDonationTop = () => baseAPI.get<IDonationTopEntry[]>('/api/users/top_donators/');
