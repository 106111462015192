import './ShopPage.css'
import useServers from '../../hooks/useServers';
import StarIcon from '@mui/icons-material/Star';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import useProducts from '../../hooks/useProducts';
import { getItemImage, getProductImage, IProduct, ItemType } from '../../types/IProduct';
import NamedLoader from '../../components/NamedLoader';
import React, { useState } from 'react';
import IServer from '../../types/IServer';
import ProductInfoMobileModal from './ProductInfoMobileModal';
import PurchaseConfirmationModal from './PurchaseConfirmationModal';
import { EmptyCollectionAbsolute } from '../../components/EmptyCollectionAbsolute';

export const ShopPage = () => {

  const [mobileModalIsOpen, setMobileModalIsOpen] = React.useState<boolean>(false);
  const [purchaseModalIsOpen, setPurchaseModalIsOpen] = React.useState<boolean>(false);
  const [filterServer, setFilterServer] = useState<IServer | null>(null);
  const {servers, areServersLoading} = useServers();
  const {products, areProductsLoading, isRefetching} = useProducts(filterServer);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);

  const renderedProductGridItem = (p: IProduct) => {
    return <div key={p.id} className={`product-card${p.id === selectedProduct?.id ? " product-card-selected" : ""}`} onClick={() => setSelectedProduct(p)}>
      <div className="mobile-screen-info-popup-button" onClick={() => setMobileModalIsOpen(true)}>
        <InfoIcon/>
      </div>
      <div className="mobile-screen-info-popup-button">
        <i className="far fa-question-circle"></i>
      </div>
      <div className="product-image-wrapper">
        <img src={getProductImage(p)} alt={p.name}/>
      </div>
      <h6 className="product-name">{p.name}</h6>
      <div className="product-cart-button-wrapper">
        <button className="product-card-button" onClick={() => setPurchaseModalIsOpen(true)}>
          <AddShoppingCartIcon fontSize="small"/> {p.price} </button>
      </div>
    </div>
  }
  const shouldRenderProductInfo = selectedProduct === null || (products && products.find(e => e.id === selectedProduct.id) === undefined);

  // @ts-ignore
  return <div id='items-management'>
    <ProductInfoMobileModal open={mobileModalIsOpen} setOpen={setMobileModalIsOpen} product={selectedProduct}/>
    <PurchaseConfirmationModal open={purchaseModalIsOpen} setOpen={setPurchaseModalIsOpen} product={selectedProduct}/>
    <aside id='items-filter'>
      <div id='servers-filter'>
        {areServersLoading ? <NamedLoader message=""/> : null}
        {servers ? servers.map(s =>
          <div key={s.id} className={`filter server-filter hexagon${s.id === filterServer?.id ? " active-filter" : ""}`} title={s.name} onClick={() => (filterServer === null || filterServer.id !== s.id) ? setFilterServer(s) : setFilterServer(null)}>
            <div className="hexagon"><img src={s.ServerIcon} alt={s.name}/></div>
          </div>
          ) : null}
      </div>
    </aside>
    <main id='items-list'>
      <div id='items-list-inner'>
        <NamedLoader absolute message="Loading Products..." visible={areProductsLoading || isRefetching}/>
        {areProductsLoading || isRefetching ? null : <React.Fragment>
          <div id='permission-category-heading' className='category-heading'>
            <h5><StarIcon/>Привилегии</h5>
          </div>
          <div id='permission-product-list' className='product-list'>
            {products ? products
              .filter(v => v.type === ItemType.PRODUCT)
              .map(renderedProductGridItem) : null}
          </div>

          <div id='kit-category-heading' className='category-heading'>
            <h5><StarIcon/>Киты</h5>
          </div>
          <div id='kit-product-list' className='product-list'>
            {products ? products
              .filter(v => v.type === ItemType.KIT)
              .map(renderedProductGridItem) : null}
          </div>

          <div id='item-category-heading' className='category-heading'>
            <h5><StarIcon/>Предметы</h5>
          </div>
          <div id='item-product-list' className='product-list'>
            {products ? products
              .filter(v => v.type === ItemType.ITEM)
              .map(renderedProductGridItem) : null}
          </div>

          <div id='special-category-heading' className='category-heading'>
            <h5><StarIcon/>Специальные</h5>
          </div>
          <div id='special-product-list' className='product-list'>
            {products ? products
              .filter(v => v.type === ItemType.SPECIAL)
              .map(renderedProductGridItem) : null}
          </div>
        </React.Fragment>}
      </div>
    </main>
    <aside id='item-detail'>
      <EmptyCollectionAbsolute message="Select item" visible={shouldRenderProductInfo}/>
      {shouldRenderProductInfo ? null : <div id='product-detail-info'>
        <div id='product-detail-meta'>
          <h5 id='active-produtc-heading'>
            <img id='product-detail-image' src={selectedProduct.image} alt='' />
            <span id='product-detail-name'>{selectedProduct.name}</span>
          </h5>
          <ul id='active-product-content-list'>
            {selectedProduct.items.filter(r => r.item.name !== selectedProduct?.name).map((rel, i) =>
              <li key={i}>
                <img src={getItemImage(rel.item)} alt='' />
                <span className='item-count'>x{rel.count}</span><span>{rel.item.name}</span>
              </li>)}
          </ul>
        </div>
        <div id='product-detail-items'>
          <p id='product-detail-description'>
            {selectedProduct?.description}
          </p>
          <hr />
          <h5 style={{fontSize: "13px"}}>ДОСТУПНО НА СЕРВЕРАХ</h5>
          <div id='available-servers' className='product-info-detail'>
            {selectedProduct.servers.map(s => <div key={s.id} id="available-servers" className="product-info-detail">
              <span style={{color: s.color}}>{s.name}</span></div>)}
          </div>
        </div>
      </div>}

    </aside>
  </div>;
};