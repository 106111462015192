import { useParams } from 'react-router-dom';
import { useServer } from '../../hooks/useServers';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ServerPage.css';
import WholeScreenLoader from '../../components/WholeScreenLoader';
import { getImage, IPhoto } from '../../types/IPhoto';
import usePhotos from '../../hooks/usePhotos';
import { getModImage, IModCategory } from '../../types/IMod';
import useOnline from '../../hooks/useOnline';
import 'chartjs-adapter-moment';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend, TimeScale, Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Container from '@mui/material/Container';
import NamedLoaderAbsolute from '../../components/NamedLoaderAbsolute';
import Page404 from '../../components/Page404';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
);

let index = -1;


export const ServerPage = () => {
  const params = useParams();
  const serverId = Number(params.id);
  const { server, isLoading } = useServer(serverId);
  const { photos } = usePhotos(server?.id);
  const { onlineStatistics, areOnlineStatisticsLoading } = useOnline(server?.id);
  const { t } = useTranslation();
  const [image, setImage] = useState<IPhoto | null>(null);
  useEffect(() => {
    const kek = setInterval(() => {
      if (photos === undefined) return;
      if (photos.results.length === 0) return;

      if (index === photos.results.length - 1) {
        index = 0;
      } else {
        index++;
      }
      setImage(photos.results[index]);

    }, 6000);
    return () => {
      clearInterval(kek);
    };
  });

  if (server === undefined && !isLoading) {
    return <Page404 resource={true} />;
  }

  if (isNaN(serverId)) {
    return <div>404</div>;
  }
  if (params.id === undefined) return <div>No provided server id</div>;
  if (isLoading) return <WholeScreenLoader />;
  if (server === undefined) return <div></div>;
  if (image === undefined) return <div></div>;
  let maxOnlineChart = 100;

  if (onlineStatistics !== undefined && onlineStatistics.length > 0) {
    let maxOnline = Math.max.apply(null, onlineStatistics.map(s => s.count));
    maxOnlineChart = 50 * Math.ceil(maxOnline / 50);
    maxOnlineChart = maxOnlineChart === 0 ? 50 : maxOnlineChart;
  }
  const data = {
    datasets: [
      {
        fill: true,
        label: server.name,
        data: (onlineStatistics || []).map(s => {
          return { y: s.count, x: new Date(Date.parse(s.timestamp)) };
        }),
        borderColor: 'rgb(255, 200, 0)',
        backgroundColor: 'rgba(255, 255, 150, 0.5)',
      },
    ],
  };

  return (
  <div style={{ background: '#222' }}>
    <div id='server-background-preview'
         style={{ backgroundImage: `url('${image === null ? '/login-background.png' : getImage(image)}')` }}>
      <Typography
        variant='h5'
        component='div'
        style={{ paddingTop: '10px', color: 'white', height: '100%' }}
        gutterBottom
        textAlign='center'
      >
        <div id='server-info'>
          <Typography variant='h4' component='h2' gutterBottom>
            {server.name}
          </Typography>
          <Typography variant='subtitle1' component='h6' gutterBottom>
            {server.version}
          </Typography>
        </div>
      </Typography>
    </div>

    <div className='starshine-header'>
      <h2>{t('mod-list')} {server.name}</h2>
    </div>

    <Container className='server-info-element' maxWidth='md' style={{ position: 'relative', minHeight: '300px' }}>
      <div className='mods-wrapper'>
        <div className='mod-list'>
          <ul id='mod-list-base'>
            <h5>{t('core')}</h5>
            {server.mods.filter(m => m.category === IModCategory.BASE).map(s => {
              return <li key={s.id} className='mod-entity' title={s.description}>
                <img src={getModImage(s)} alt={s.name} />
                <h6><span>{s.name}</span></h6>
              </li>;
            })}
          </ul>
        </div>
        <div className='mod-list'>
          <ul id='mod-list-help'>
            <h5>{t('additional')}</h5>
            {server.mods.filter(m => m.category === IModCategory.HELPER).map(s => {
              return <li key={s.id} className='mod-entity' title={s.description}><img src={getModImage(s)}
                                                                                      alt={s.name} /><h6>
                <span>{s.name}</span></h6></li>;
            })}
          </ul>
        </div>
        <div className='mod-list'>
          <ul id='mod-list-plugins'>
            <h5>{t('plugins')}</h5>
            {server.mods.filter(m => m.category === IModCategory.PLUGIN).map(s => {
              return <li key={s.id} className='mod-entity' title={s.description}><img src={getModImage(s)}
                                                                                      alt={s.name} /><h6>
                <span>{s.name}</span></h6></li>;
            })}
          </ul>
        </div>
      </div>
    </Container>

    <div className='starshine-header'>
      <h2>{t('players-online-statistics')}</h2>
    </div>

    <Container maxWidth='md' style={{ position: 'relative', minHeight: '300px' }}>
      <NamedLoaderAbsolute message='Loading statistics' visible={areOnlineStatisticsLoading} />
      {areOnlineStatisticsLoading ? null : <Line options={{
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'minute',
              displayFormats: {
                minute: 'hh:mm',
              },
            },
            grid: {
              color: '#555',
            },
          },
          y: {
            display: true,
            grid: {
              color: '#555',
            },
            max: maxOnlineChart,
            min: 0,
            ticks: {
              stepSize: 10,
            },
          },
        },
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: t('players-per-hour'),
          },
        },
      }} data={data} />}
    </Container>
  </div>);
};