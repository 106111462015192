import { baseAPI_V2 } from '../baseAPIV2';
import { Group, Permission } from '../entities/group';
import { toCssHexColor } from '../utils/dataTypes';
import { UserAdminList, UserInfoForAdmin, UserMembership } from '../entities/user';
import { Attribute, AttributeModifier } from '../entities/attribute';


export interface PermissionGrantDTO {
  readonly id: number;
  readonly permission_id: number;
  readonly permission_key: String;
  readonly value: boolean;
  readonly expire: number | null;
  readonly granted_at: number | null;
  readonly server_id: number | null;
  readonly world_id: number | null;
}

export async function getAllGroups() {
  return await baseAPI_V2.get<Group[]>('/api/v2/groups' );
}

export async function getAllUsers(after_nickname: string | null, search: string | null) {
  return await baseAPI_V2.get<UserAdminList[]>('/api/v2/users', {params: { search: search, after_nickname: after_nickname}});
}

export async function getUserInfoForAdmin(id: number) {
  return await baseAPI_V2.get<UserInfoForAdmin>(`/api/v2/users/${id}`);
}

export async function getUserMembershipsForAdmin(user_id: number) {
  return await baseAPI_V2.get<UserMembership[]>(`/api/v2/users/${user_id}/memberships`);
}

export async function createUserMembership(user_id: number, group_id: number, server_id: number | null, expire: number | null) {
  let data = {
    group_id: group_id,
    server_id: server_id,
    expire: expire
  }
  return await baseAPI_V2.post<{}>(`/api/v2/users/${user_id}/memberships`, data);
}

export async function deleteUserMembership(user_id: number, membership_id: number) {
  return await baseAPI_V2.delete<{}>(`/api/v2/users/${user_id}/memberships/${membership_id}`);
}


export async function createGrant(group_id: number, data: any) {
  return await baseAPI_V2.post<PermissionGrantDTO>(`/api/v2/groups/${group_id}/permissions`, data);
}

export async function deleteGrant(group_id: number, grant_id: number) {
  return await baseAPI_V2.delete<Group[]>(`/api/v2/groups/${group_id}/permissions/${grant_id}` );
}

export async function getAllAttributes() {
  return await baseAPI_V2.get<Attribute[]>(`/api/v2/attributes/` );
}

export async function getGroupModifiers(group_id: number) {
  return await baseAPI_V2.get<AttributeModifier[]>(`/api/v2/groups/${group_id}/attributes/modifiers/` );
}

export async function createGroupModifiers(group_id: number, attribute_id: number, server_id: number | null, value: number) {
  let data = {
    attribute_id: attribute_id,
    server_id: server_id,
    value: value
  }
  return await baseAPI_V2.post<AttributeModifier>(`/api/v2/groups/${group_id}/attributes/modifiers/`, data);
}

export async function deleteGroupModifier(id: number) {
  return await baseAPI_V2.delete(`/api/v2/groups/attributes/modifiers/${id}/`);
}

export async function getAllPermissions() {
  return await baseAPI_V2.get<Permission[]>('/api/v2/permissions' );
}


export function getColor(group: Group): string {
  return toCssHexColor(group.color)
}

export function getTextColor(group: Group): string {
  if (group.color === null) return "black";

  return "white"
}
