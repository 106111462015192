import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import WholeScreenLoader from './components/WholeScreenLoader';
import { AuthContext } from './AppRouter';
import { AuthState } from './stores/authContext';

const AuthorizedRoutes = () => {
  const location = useLocation();
  const {state} = useContext(AuthContext);

  if (state == AuthState.LOADING) return <WholeScreenLoader />;
  if (state == AuthState.UNAUTHORIZED) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return <Outlet />;
};

export default AuthorizedRoutes;
