

export function HEXToIntColor(hex_string: string): number {
  return parseInt(hex_string.replace("#", ""), 16);
}

export function toCssHexColor(color: number | null): string {
  if (color == null) return "#ffffff"
  return "#" + (color & 0x00FFFFFF).toString(16).padStart(6, '0');
}

export function nullableNumber(value: FormDataEntryValue | null): number | null {
  if (value === null || value === "null") return null;
  return Number(value)
}

