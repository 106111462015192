import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {'Copyright © '}
      <Link style={{ color: 'gold' }} color="inherit" to="/">
        starshine-project.ru
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Footer() {
  const location = useLocation();
  const {t} = useTranslation();
  if (location.pathname.endsWith("settings")) return null
  if (location.pathname.startsWith('/launcher')) return null;
  if (location.pathname.endsWith("settings/")) return null
  if (location.pathname.includes("/admin-panel")) return null

  return (
    <Box component="footer" sx={{ bgcolor: '#000', color: 'white', py: 12 }}>
      <Container maxWidth="lg">
        <Typography variant="h6" align="center" gutterBottom style={{fontFamily: "Audiowide"}}>
          Starshine Project
        </Typography>
        <Typography
          style={{ width: '50%', margin: 'auto' }}
          variant="subtitle1"
          align="center"
          color="#999"
          component="p"
        >
          <Link to="play/">{t("play-button")}</Link>
        </Typography>
        <Copyright />
      </Container>
    </Box>
  );
}
