import React, { useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';
import { AuthContext, NotificationContext } from '../../AppRouter';
import { AuthState } from '../../stores/authContext';
import CircularProgress from '@mui/material/CircularProgress';
import { logoutWithCookies } from '../../api/auth';
import { notification } from '../../stores/notificationContext';


interface UserMenuProps {
  anchorElUser: any,
  setAnchorElUser: Function,
}


const UserMenu = ({ anchorElUser, setAnchorElUser }: UserMenuProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {state, error, setState} = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext)

  let menu = null;
  switch (state) {
    case AuthState.LOADING:
      menu = <CircularProgress size={14}/>
      break;
    case AuthState.AUTHORIZED:
      menu = [
        <MenuItem
          key='profile'
          onClick={() => {
            setAnchorElUser(null);
            navigate('/profiles/@self/settings');
          }}
        >
          <Typography textAlign='center'>{t('navigation-popup-button-profile')}</Typography>
        </MenuItem>,
        <MenuItem
          key='logout'
          onClick={() => {
            logoutWithCookies().then(() => {
              setAnchorElUser(null);
              setState(AuthState.UNAUTHORIZED);
              notification("success", t("Successful logout"), null, null, notificationContext);
              navigate('login');
            });
          }}
        >
          <Typography textAlign='center'>{t('navigation-popup-button-sign-out')}</Typography>
        </MenuItem>
      ]
      break;
    case AuthState.UNAUTHORIZED:
      menu = [
        <Link
          key='login'
          className='no-style-link'
          to='/login'
          onClick={() => {
            setAnchorElUser(null);
          }}
        >
          <MenuItem>
            <Typography textAlign='center'>{t('Sign In')}</Typography>
          </MenuItem>
        </Link>,
        <Link
          key='sign-up'
          className='no-style-link'
          to='/sign-up'
          onClick={() => {
            setAnchorElUser(null);
          }}
        >
          <MenuItem>
            <Typography textAlign='center'>{t('Sign Up')}</Typography>
          </MenuItem>
        </Link>]
      break;
    case AuthState.ERROR:
      menu = <div>
        <div>User fetching error</div>
        <div>{error?.message}</div>
      </div>
      break;
  }

  return <Menu
    sx={{ mt: '45px' }}
    id='menu-appbar'
    anchorEl={anchorElUser}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    open={Boolean(anchorElUser)}
    onClose={() => setAnchorElUser(null)}
  >
    {menu}
  </Menu>;
};
export default UserMenu;