import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import React, { FormEvent, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { notification, thereWasOurSideError } from '../../../stores/notificationContext';
import { handledValidation, } from '../../../utils/responses';
import { NotificationContext } from '../../../AppRouter';
import { useAdminGroups } from '../AdminGroupsPage/GroupAPI';
import { useServersForAdmin } from '../../../hooks/useServers';
import { createUserMembership } from '../../../api/admin';
import FormControl from '@mui/material/FormControl';
import { NativeSelect, OutlinedInput } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import InputLabel from '@mui/material/InputLabel';



export interface Props {
  onCreated: () => void;
  user_id: number
}


const ModalMembershipCreate = (props: Props) => {
  const { t } = useTranslation();

  const [valueExpiration, setValueExpiration] = useState<dayjs.Dayjs | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const notificationContext = useContext(NotificationContext);

  const queryGroups = useAdminGroups();
  const queryServers = useServersForAdmin();

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    let group_id = formData.get("group_id");
    let server_id = formData.get("server_id");
    let expire = valueExpiration === null ? null : valueExpiration.unix();

    const serverID = server_id === "global" ? null : Number(server_id);
    const expiration = expire === null ? null : Number(expire);

    setLoading(true)
    createUserMembership(props.user_id, Number(group_id), serverID, expiration)
      .then(response => {
        props.onCreated();
      })
      .catch(error => {
        if (thereWasOurSideError(error, notificationContext)) return;
        if (error.response.status === 409) {
          notification("error", t("Such membership already exist"), null, null, notificationContext);
          return;
        }

        let m = new Map();
        // m.set('key_id', setErrorFieldKeyId);
        // m.set('name', setErrorFieldName);
        // m.set('prefix', setErrorFieldPrefix);
        if (handledValidation(error, m)) return;

      })
      .finally(() => {
        setLoading(false)
      });

  };

  return (
    <form onSubmit={onSubmit} style={{position: "relative"}}>
      <Card sx={{ minWidth: 375 }}>
        <CardContent>
          <Typography variant='h6' gutterBottom>{t('Membership creation')}</Typography>
          <Box>
              <FormControl fullWidth margin="dense">
                <InputLabel variant="outlined" htmlFor="group-id">
                  Group Id
                </InputLabel>
                <NativeSelect
                  input={<OutlinedInput label="group_id" />}
                  inputProps={{
                    name: 'group_id',
                    id: 'group-id',
                  }}
                >
                  {queryGroups.isFetched && queryGroups.data && queryGroups.data.filter((g) => g.key_id !== "default").map((g, i) =>
                    <option key={i} value={g.id}>{g.key_id}</option>
                  )}
                </NativeSelect>
              </FormControl>

              <FormControl fullWidth margin="dense">
                <InputLabel variant="outlined" htmlFor="server-id">
                  Server Context
                </InputLabel>
                <NativeSelect
                  input={<OutlinedInput label="server_id" />}
                  defaultValue={30}
                  inputProps={{
                    name: 'server_id',
                    id: 'server-id',
                  }}
                >
                  <option value="global">Global</option>
                  {queryServers.isFetched && queryServers.data && queryServers.data.map((s, i) =>
                    <option key={i} value={s.id}>{s.name}</option>
                  )}
                </NativeSelect>
              </FormControl>

              <FormControl fullWidth margin="dense">
                <DatePicker name='startDate' defaultValue={null} value={valueExpiration} onChange={e => setValueExpiration(e)} />
              </FormControl>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button disabled={loading} type="submit" variant="contained" color="success">{loading ? t("Loading") : t("Grant membership")}</Button>
          </Box>
        </CardContent>
      </Card>
    </form>

  );
};

export default ModalMembershipCreate;