import ManageSearchIcon from '@mui/icons-material/ManageSearch';

interface Props {
  message?: string,
  visible?: boolean
}

export const EmptyCollectionAbsolute = (props: Props) => {

  return <div style={{
    opacity: props.visible ? "100%" : "0",
    width: "100%",
    transition: '0.3s',
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: "300px",
    display: props.visible ? "flex" : "none",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  }}>
    <ManageSearchIcon fontSize="large" style={{color: 'gold'}}/>
    {props.message ? <div style={{marginTop: "8px"}}><span style={{fontFamily: "Audiowide"}}>{props.message}</span></div> : null}
  </div>
}