import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';


interface Props {
  openEditModal: boolean,
  setOpenEditModal: Function,
  nickname: string
}

const EditModal = (props: Props) => {
  const handleClose = () => {props.setOpenEditModal(false);};

  return (
    <React.Fragment>
      <Dialog
        open={props.openEditModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title">
          Редактирование пользователя {props.nickname}
        </DialogTitle>
        <DialogContent>
          {/*<DialogContentText id="alert-dialog-description">*/}
          {/*  Let Google help apps determine location. This means sending anonymous*/}
          {/*  location data to Google, even when no apps are running.*/}
          {/*</DialogContentText>*/}
          <TextField
            autoFocus
            required
            margin="dense"
            id="nickname"
            name="nickname"
            label="Nickname"
            type="nickname"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отменить</Button>
          <Button onClick={handleClose} autoFocus>Подтвердить</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default EditModal;