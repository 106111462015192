import * as React from 'react';
import { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import BrushIcon from '@mui/icons-material/Brush';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import './SettingsPage.css';
import { SettingsProfile } from './SettingsProfile';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UserMenu from '../../components/Navigation/UserMenu';
import { useTranslation } from 'react-i18next';
import SettingsInventory from './SettingsInventory';
import SettingsAppearance from './SettingsAppearance';
import { AuthContext } from '../../AppRouter';
import { AuthState } from '../../stores/authContext';

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function SettingsPage(props: Props) {
  const { window } = props;
  const { t } = useTranslation();
  const params = useParams();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const { state, user } = useContext(AuthContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!['settings', 'inventory', 'appearance'].includes(params.page || '')) {
    return <div>{params.page}</div>;
  }

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem key='Profile' disablePadding>
          <ListItemButton onClick={() => {
            navigate('/profiles/@self/settings/');
          }} selected={params.page === 'settings'}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={t('Profile')} />
          </ListItemButton>
        </ListItem>
        <ListItem key='Appearance' disablePadding>
          <ListItemButton onClick={() => {
            navigate('/profiles/@self/appearance/');
          }} selected={params.page === 'appearance'}>
            <ListItemIcon>
              <BrushIcon />
            </ListItemIcon>
            <ListItemText primary={t('Appearance')} />
          </ListItemButton>
        </ListItem>
        <ListItem key='Inventory' disablePadding>
          <ListItemButton onClick={() => {
            navigate('/profiles/@self/inventory/');
          }} selected={params.page === 'inventory'}>
            <ListItemIcon>
              <ShoppingBasketIcon />
            </ListItemIcon>
            <ListItemText primary={t('Inventory')} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>

      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  let page = null;
  if (params.page === 'settings') page = <SettingsProfile />;
  if (params.page === 'inventory') page = <SettingsInventory />;
  if (params.page === 'appearance') page = <SettingsAppearance />;

  return (
    <Box sx={{ display: 'flex', padding: '0' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div' style={{ width: '100%' }}>
            <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
              style={{ justifyContent: 'space-between' }}
            >
              <Link id='nav-logo-settings' to='/'>
                <img src='/SP_.jpg' alt='' />
                <span>{t('profile-nav-main-page')}</span>
              </Link>
              <span onClick={(event) => {
                setAnchorElUser(event.currentTarget);
              }} id='avatar-button'>
                  <IconButton sx={{ p: 0, mr: 0.5 }}>
                    {state == AuthState.LOADING ? (
                      <CircularProgress />
                    ) : (
                      <Avatar alt='Remy Sharp' src={user?.getAvatar()} />
                    )}
                  </IconButton>
                  <KeyboardArrowDownIcon style={{ verticalAlign: 'middle' }} />
                </span>
            </Typography>

            <UserMenu anchorElUser={anchorElUser} setAnchorElUser={setAnchorElUser} />
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label='mailbox folders'
      >
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{ flexGrow: 1, p: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, minHeight: '80vh', padding: 0 }}
      >
        <Toolbar />
        {page}
      </Box>
    </Box>
  );
}
