import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getProductImage, IProduct } from '../../types/IProduct';
import CloseIcon from '@mui/icons-material/Close';
import React, { ReactElement, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import './PurchaseConfirmationModal.css'
import { buy, Status } from '../../API';
import { AlertColor } from '@mui/material/Alert/Alert';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../AppRouter';
import { loadSelfFromBackendAndUpdateContext } from '../../stores/authContext';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Alert from '@mui/material/Alert';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface ModalProps {
  product: IProduct | null,
  setOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void
  open: boolean
}

export default function PurchaseConfirmationModal(props: ModalProps) {
  const handleClose = () => {
    setPurchaseResult(null);
    props.setOpen(false);
  }
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [purchaseResult, setPurchaseResult] = useState<{severity: AlertColor, message: ReactElement} | null>(null);
  const authContext = useContext(AuthContext);

  const purchase = () => {
    if (props.product === null) return;
    setLoading(true);
    setPurchaseResult(null);
    buy(props.product.id)
      .then((r) => {
        setPurchaseResult({severity: "success", message: <span>Товар успешно куплен и добавлен в <Link className="small-link" target="_blank" to="/profiles/@self/inventory">хранилище</Link></span>   })
        loadSelfFromBackendAndUpdateContext(authContext);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          if (err.response?.data.code === Status.NOT_ENOUGH_BALANCE) {
            setPurchaseResult({severity: "error", message: <span>{t("not-enough-balance")}</span>   })
          }
        }
        console.error(err)
      })
      .finally(() => {setLoading(false)})
  }

  if (props.product === null) return null;
  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style} className='modal-content'>
            <Typography id='transition-modal-title' variant='h6' component='h2'>
              Подтверждение покупки <span id="close-modal" onClick={() => handleClose()}
                                          style={{ float: 'right', marginRight: '0.5em' }}><CloseIcon /></span>
            </Typography>

            <Box display='flex' width='100%' textAlign='center' mt='2em'>
              <div style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
                <img id='buying-confirmation-product-image' src={getProductImage(props.product)} alt='' />
                <h5 id='buying-confirmation-product-name'>{props.product.name}</h5>
              </div>

              <div id='buying-information' style={{ flex: 1 }}>
                После покупки содержание товара будет добавлено в
                <Link style={{color: 'gold'}} to='/profiles/@self/inventory'>{' хранилище '}</Link>.
                После этого товар можно будет выдать на один из доступных серверов.
              </div>
            </Box>
            <div id='confirmation-buttons' className='modal-footer'>
              {purchaseResult ? <Alert severity={purchaseResult.severity}>{purchaseResult.message}</Alert> : null }

              <Button
                onClick={() => {purchase()}}
                id='buy-button'
                color='warning'
                variant='contained'
                disabled={loading}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                {loading ? "Loading..." : <React.Fragment>Купить за <span id='buying-confirmation-product-price'>{props.product.price}</span><img
                  src='/starcoin-min.png' style={{ height: '1em', margin: '0 0 3px 2px' }} alt='' /></React.Fragment> }

              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}