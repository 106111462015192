

export enum ErrorCode {
  BAD_CAPTCHA = "BadCaptcha",
  LOGIN_INVALID_CREDENTIALS = "LoginInvalidCredentials",
  LOGIN_TOO_MANY_ATTEMPTS = "TooManyLoginAttempts",

  REGISTRATION_USERNAME_EXIST = "RegistrationUsernameExist",
  REGISTRATION_TOO_MANY_ATTEMPTS = "TooManyRegistrations",
  REGISTRATION_USERNAME_TOO_SHORT = "RegistrationUsernameTooShort",
  REGISTRATION_USERNAME_TOO_LONG = "RegistrationUsernameTooLong",
  REGISTRATION_USERNAME_INVALID = "RegistrationUsernameInvalid",
  REGISTRATION_USERNAME_RESERVED = "RegistrationUsernameReserved",
  REGISTRATION_EMAIL_INVALID = "RegistrationEmailInvalid",
  REGISTRATION_EMAIL_EXIST = "RegistrationEmailExist",

  USER_HAS_NO_PERMISSION = "UserHasNoPermission",

  JWT_TOKEN_EXPIRED = "JWTTokenExpired",
  JWT_TOKEN_INVALID = "JWTTokenInvalid",
  JWT_TOKEN_INVALID_USER_NOT_FOUND = "JWTTokenInvalidUserNotFound",
  JWT_TOKEN_INVALID_EMAIL_NOT_MATCHED = "JWTTokenInvalidUserEmailNotMatched",

  DONATION_SUM_NOT_SPECIFIED = "NoDonationSumSpecified",
  DONATION_SUM_IS_INVALID = "DonationSumIsInvalid"

}