import "./AdminAnalyticsPage.css"
import AdminWrapper from '../component/AdminWrapper';
import React from 'react';


const AdminAnalyticsPage = () => {
  return (
    <AdminWrapper name="Analytics" link="/admin-panel/analytics">
      <div>AdminAnalyticsPage</div>
    </AdminWrapper>
  )
}

export default AdminAnalyticsPage