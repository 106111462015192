import { AxiosError } from 'axios';
import { isAxiosError } from '../utils/responses';
import { Dispatch, SetStateAction } from 'react';
import i18next from 'i18next';

type Severity = "error" | "warning" | "info" | "success";

export interface INotificationContext {
  open: boolean
  message: string | null
  userHint: string | null
  timeoutMs: number
  severity: Severity
  setOpen: Dispatch<SetStateAction<boolean>>
  setSeverity: Dispatch<SetStateAction<Severity>>
  setTimeoutMs: Dispatch<SetStateAction<number>>
  setMessage: Dispatch<SetStateAction<string | null>>
  setUserHint: Dispatch<SetStateAction<string | null>>
}


export function defaultNotificationContext(): INotificationContext {
  return {
    open: false,
    message: null,
    userHint: null,
    timeoutMs: 5000,
    severity: "info",
    setOpen: () => {},
    setMessage: () => {},
    setUserHint: () => {},
    setTimeoutMs: () => {},
    setSeverity: () => {},
  }
}

export function thereWasOurSideError(error: any | AxiosError, context: INotificationContext): boolean {
  if (error.message === "Network Error") {
    context.setSeverity("error");
    context.setMessage(i18next.t('Network error occurred ({{which}}). This can be any sort of problem: lost internet connection, provider bans or server outage', {which: error.message}));
    context.setUserHint(i18next.t('Check you internet connection, use VPN or ask in discord ticket'));
    context.setTimeoutMs(20000);
    context.setOpen(true)
    return true;
  }

  if (isAxiosError<{}>(error)) {
    if (error.response && error.response.data && typeof error.response.data === "string" && error.response.data.includes("Json deserialize error")) {
      console.log(error.response.data);
      context.setSeverity("error");
      context.setMessage(i18next.t('Server serialization error occurred. This is probably a bug (see console).'))
      context.setUserHint(i18next.t('Please contact us in discord ticket if problem will not go away'))
      context.setTimeoutMs(8000);
      context.setOpen(true)
      return true;
    }

    if (error.response && error.response.status >= 500) {
      context.setSeverity("error");
      context.setMessage(i18next.t('Server error occurred (code: {{code}}). This is probably temporary problem on our side.', {code: error.response.status}))
      context.setUserHint(i18next.t('Try wait for some time or ask in discord ticket if problem will not go away'))
      context.setTimeoutMs(20000);
      context.setOpen(true)
      return true;
    }
  }
  return false
}

export function handledError401AsNotification(error: any | AxiosError, context: INotificationContext): boolean {
  if (isAxiosError<{}>(error)) {
    if (error.response && error.response.status == 401) {
      context.setSeverity("error");
      context.setMessage(i18next.t('Please re-login, to proceed'))
      context.setUserHint(null)
      context.setTimeoutMs(10000);
      context.setOpen(true)
      return true;
    }
  }
  return false
}

export function notification(severity: Severity, message: string, hint: string | null, timeout_ms: number | null, context: INotificationContext) {
  context.setSeverity(severity);
  context.setMessage(message)
  context.setUserHint(hint)
  context.setTimeoutMs(timeout_ms === null ? 5000 : timeout_ms);
  context.setOpen(true)
}

export function backgroundColorOfSeverity(severity: Severity): string {
  switch (severity) {
    case 'error':
      return "crimson";
    case 'warning':
      return "goldendrod";
    case 'info':
      return "rgb(34, 34, 34)";
    case 'success':
      return "green";
  }
}

export function textColorOfSeverity(severity: Severity): string {
  switch (severity) {
    case 'error':
      return "whitesmoke";
    case 'warning':
      return "black";
    case 'info':
      return "whitesmoke";
    case 'success':
      return "whitesmoke";
  }
}
