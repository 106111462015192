import './AdminUsersPage.css';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import AdminWrapper from '../component/AdminWrapper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';
import Pagination from '@mui/material/Pagination';
import EditModal from './component/EditModal';
import { useAdminUsers } from '../../../api/Users';
import NamedLoaderAbsolute from '../../../components/NamedLoaderAbsolute';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { prettyDateWithDaysAgo } from '../../../utils/responses';
import { useNavigate } from 'react-router-dom';


const AdminUsersPage = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [nickname, setNickname] = useState('');
  const [search, setSearch] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [nicknamePage, setNicknamePage] = useState<string | null>(null);
  const navigate = useNavigate();

  const usersQuery = useAdminUsers(nicknamePage, search);

  useEffect(() => {
    const val = localStorage.getItem('last_search');
    if (val == null) return;
    setSearchValue(val)
    setSearch(val)
  }, []);

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const createData = (
    avatar: string,
    nickname: string,
    lastOnline: Date,
    donationsMonthly: number,
    donationsAll: number,
    actions: string[],
  ) => {
    return { avatar, nickname, lastOnline, donationsMonthly, donationsAll, actions };
  };

  const actionVariants = ['Edit', 'Ban', 'Check'];

  return (
    <AdminWrapper name='Users' link='/admin-panel/users'>
      <div>
        <EditModal
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          nickname={nickname}
        />

        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '8px 12px', width: "100%" }}>
          <TextField id="outlined-search"
                     onKeyDown={(e) => {if (e.key === 'Enter') {
                       let val = (e.target as HTMLInputElement).value;
                       setSearch(val);
                       if (val) localStorage.setItem('last_search', val);
                     }}}
                     onChange={e => setSearchValue(e.target.value)}
                     value={searchValue === null ? "" : searchValue}
                     label="Search field"
                     type="search"
                     size="small"

          />
        </Box>

        {usersQuery.isLoading ? <NamedLoaderAbsolute message={t('Loading users')} /> : null}
        {usersQuery.isFetched && usersQuery.data && <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='left'>ID</TableCell>
                <TableCell align='left'>Avatar</TableCell>
                <TableCell align='left'>Nickname</TableCell>
                <TableCell align='left'>Email</TableCell>
                <TableCell align='left'>Last online</TableCell>
                <TableCell align='left'>Date joined</TableCell>
                <TableCell align='left'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersQuery.data.map((row) =>
                (
                  <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align='left' className="clickable-id" onClick={e => navigate(`/admin-panel/users/${row.id}/details`)}>{row.id}</TableCell>
                    <TableCell component='th' scope='row'>
                      <Avatar alt='Avatar' src={row.avatar} sx={{ width: 24, height: 24 }} />
                    </TableCell>
                    <TableCell align='left'>{row.username}</TableCell>
                    <TableCell align='left'>{row.email}</TableCell>
                    <TableCell align='left'>{prettyDateWithDaysAgo(row.last_active_timestamp)}</TableCell>
                    <TableCell align='left'>{prettyDateWithDaysAgo(row.date_joined)}</TableCell>
                    <TableCell align='left'>...</TableCell>
                  </TableRow>))}
            </TableBody>
          </Table>

          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseDropdown}
          >
            <MenuItem onClick={() => handleCloseDropdown()}>Показать</MenuItem>
            <MenuItem onClick={() => handleCloseDropdown()}>Сессии пользователя</MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseDropdown();
                setOpenEditModal(true);
              }}>
              Редактировать
            </MenuItem>
            <MenuItem onClick={() => handleCloseDropdown()}>В бан</MenuItem>
          </Menu>

          <Pagination count={10} variant='outlined' color='primary' id='admin-user-pagination' />
        </TableContainer>
        }
      </div>
    </AdminWrapper>
  );
};

export default observer(AdminUsersPage);