import {baseAPI_V2} from '../baseAPIV2';
import IUserSelfInfo from '../types/IUserSelfInfo';
import { useQuery } from 'react-query';
import { getAllUsers, getUserInfoForAdmin, getUserMembershipsForAdmin } from './admin';


export interface ISelfUserInfoDTO {
  readonly id: number;
  readonly username: string;
  readonly uuid: string;
  readonly avatar: string;
  readonly skin: null | string
  readonly cloak: null | string
  readonly date_joined: string;
  readonly balance: number
  readonly permissions: string[]
}

function map_dto_to_model(dto: ISelfUserInfoDTO): IUserSelfInfo {
  return new IUserSelfInfo(
    dto.id,
    dto.username,
    dto.uuid,
    dto.avatar.length == 0 ? null : new URL(dto.avatar),
    (dto.skin === null || dto.skin.length == 0) ? null : new URL(dto.skin),
    (dto.cloak === null || dto.cloak.length == 0) ? null : new URL(dto.cloak),
    new Date(dto.date_joined),
    dto.balance,
    new Set(dto.permissions)
  );
}

export async function fetchSelf(): Promise<IUserSelfInfo> {
  const response = await baseAPI_V2.get<ISelfUserInfoDTO>(`/api/v2/users/@me`);
  const user = response.data;
  return map_dto_to_model(user)
}

export const useUserMembershipsForAdmin = (id: number) => {

  return useQuery([`user-memberships`, id], () => {
    return getUserMembershipsForAdmin(id).then((r) => {
      return r.data
    });
  }, {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export const useUserInfoForAdmin = (id: number) => {

  return useQuery([`user-info`, id], () => {
    return getUserInfoForAdmin(id).then((r) => {
      return r.data
    });
  }, {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export const useAdminUsers = (after_nickname: string | null, search: string | null) => {
  return useQuery([`groups`, after_nickname, search], () => {
    return getAllUsers(after_nickname, search).then((r) => {
      return r.data
    });
  }, {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}