import * as React from 'react';
import { Link } from 'react-router-dom';
import LockResetIcon from '@mui/icons-material/LockReset';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import StarsineTextField from '../../components/Forms/StarshineTextField';
import Copyright from '../../components/Copyright';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ActionStatus } from '../../types/ActionStatus';
import { requestPasswordReset } from '../../API';
import Alert from '@mui/material/Alert';


export default function PasswordResetLoginPage() {
  const { t } = useTranslation();
  const [status, setStatus] = useState<ActionStatus | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('starshine-username')
    if (username === null) return;

    setLoading(true)
    requestPasswordReset(username.toString())
      .then(r => {
        setStatus({severity: "success", message: t('password-reset-email-sent')})
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
            if (err.response.data.code === 2000) {
              setStatus({severity: "error", message: t('password-reset-failed-no-confirmation')})
              return
            }
            if (err.response.data.message) {
              setStatus({severity: "error", message: err.response.data.message})
              return
            }
            if (err.response.data.username) {
              setStatus({severity: "error", message: err.response.data.username})
              return
            }
          }
          if (err.response.status === 429) {
            if (err.response.data.code === 2001) {
              setStatus({severity: "error", message: t('too-many-password-reset-requests')})
              return
            }
            setStatus({severity: "error", message: t('too-many-password-reset-requests-short-limit')})
          }
          if (err.response.status === 500) {
            setStatus({severity: "error", message: t('Email service error 500. Please ask for help in discord ticket')})
          }
        }
      })
      .finally(() => setLoading(false))
  };
  return (

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockResetIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Reset password')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <StarsineTextField
              margin="normal"
              required
              fullWidth
              id="starshine-username"
              inputProps={{ minLength: 3, maxLength: 16 }}
              name="starshine-username"
              type="text"
              autoComplete="username"
              label={t('input-text-username')}
              helperText="Письмо-подтвеждение для сброса пароля придет на почту этого аккаунта, если она подтверждена. Если пиьсмо не приходит, или возникла другая проблема, - напишите в дискорд-тикет. (приглашение в дискорд на главной странице снизу)"
              autoFocus
            />
            {status ? <Alert severity={status.severity}>{status.message}</Alert> : null}
            <Button
              type="submit"
              fullWidth
              disabled={loading}
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: 'secondary.main',
                '&:hover': {
                  backgroundColor: '#fff',
                  color: '#000',
                },
              }}
            >
              {loading ? "Loading..." : t('Reset password')}
            </Button>
            <Grid container>
              <Grid item>
                <Link className="small-link" to="/sign-up">
                  {t('Don\'t have an account? Sign Up')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}
