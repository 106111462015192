import { useQuery } from 'react-query';
import { getSelfSyncInfo } from '../API';

const useSyncInfo = () => {
  return useQuery(['sync-info'], () => getSelfSyncInfo().then((r) => r), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export default useSyncInfo;
