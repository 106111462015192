import { useTranslation } from 'react-i18next';
import React, { FormEvent, useContext, useState } from 'react';
import { NotificationContext } from '../../../AppRouter';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { validColor, validLength, validLocalizedString } from '../../../utils/validation';
import { ColorPicker } from '../component/ColorPicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { DatePicker } from '@mui/x-date-pickers';
import DialogActions from '@mui/material/DialogActions';
import { useServersForAdmin } from '../../../hooks/useServers';
import { useAttributes } from '../../../hooks/usePermissions';
import { createGroupModifiers } from '../../../api/admin';
import { AttributeModifier } from '../../../entities/attribute';


interface Props {
  groupId: number
  setOpen: (a: boolean) => void
  onCreated: (a: AttributeModifier) => void
}


const ModalCreateAttributeModifier = (props: Props) => {
  const { t } = useTranslation();

  const attributes = useAttributes();
  const [loading, setLoading] = useState<boolean>(false);
  const queryServers = useServersForAdmin();
  const notificationContext = useContext(NotificationContext);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    let server = formData.get('Server id') as string | null;
    let attributeId = formData.get('Attribute id') as string | null;
    let value = formData.get('value') as string | null;

    if (server == null) return;
    if (attributeId == null) return;
    if (value == null) return;

    let serverId = server === "Global" ? null : Number(server);

    setLoading(true);
    createGroupModifiers(props.groupId, Number(attributeId), serverId, Number(value))
      .then(attr => {
        props.setOpen(false);
        props.onCreated(attr.data);
      })
      .catch()
      .finally(() => setLoading(false))

    console.log(serverId, attributeId, value);

  };

  return (
    <Container maxWidth='sm'>
      <form onSubmit={onSubmit}>
        <DialogTitle id='alert-dialog-title'>
          {t('Add attribute to group')} #{props.groupId}
        </DialogTitle>
        <FormControl fullWidth variant='standard' sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id='grant-permission-id'>{t('Choose permission to add to group')}</InputLabel>
          <NativeSelect inputProps={{ name: 'Attribute id', id: 'new-modifier-attribute-id' }}>
            {attributes.data && attributes.data.map(a => <option key={a.id} value={a.id}>Attribute: {a.key_id}</option>)}
          </NativeSelect>
        </FormControl>
        <FormControl fullWidth variant='standard' sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id='grant-server-id'>{t('Choose server context')}</InputLabel>
          <NativeSelect inputProps={{ name: 'Server id', id: 'new-grant-server-id' }}>
            <option value='Global' style={{ color: 'lightblue' }}>{t('Globally')}</option>
            {queryServers.data && queryServers.data.map(p => <option key={p.id} value={p.id}>Server: {p.name} (id={p.id})</option>)}
          </NativeSelect>
        </FormControl>
        <div style={{display: "flex", flexDirection: "column", flex: "1 1 0", minWidth: "30%", margin: "2px"}}>
          <Typography variant='caption' gutterBottom>{t('Attribute modifier value')}</Typography>
          <TextField
            name="value"
            label="Integer Value"
            margin='dense'
            variant='standard'
            size='small'
            defaultValue="2"
            type="number" required inputProps={{max: 10000, min: 1}}/>
        </div>
        <DialogActions>
          <Button onClick={_e => props.setOpen(false)}>Cancel</Button>
          <Button type='submit' autoFocus>Ok</Button>
        </DialogActions>
      </form>
    </Container>
  )
};

export default ModalCreateAttributeModifier;