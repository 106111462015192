import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';


interface Props {
  resource?: boolean
}


export default function Page500(props: Props) {
  const resource = props.resource === undefined ? false : props.resource
  const { t } = useTranslation();
  return <div id="wrapper-500">
    <img src='/hat_kid_500.jpg' alt='hat-kid-500' />
    <Typography variant="h5" component="h5">
      {t("Internal server error")}
    </Typography>
    <Typography variant="h6" component="h6">
      {t("Please try again later or contact our mods in Discord")}
    </Typography>
  </div>
}