import React, { FC } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { BASE_URL } from '../../baseAPI';
import '../../global.css';
import IMod from '../../types/IMod';
import IServer from '../../types/IServer';
import './ServerCard.css';
import { useTranslation } from 'react-i18next';

interface ServerCardProps {
  server: IServer;
  onClick?: () => void;
}

const ServerCard: FC<ServerCardProps> = ({ server, onClick }) => {
  const mods = server.mods.filter((el) => el.name.toLowerCase() != 'minecraft');
  const {t} = useTranslation();
  return (
      <Paper elevation={3} className="server-card" onClick={onClick}>
        <div className="server-card-hex-content">
          <div className="server-card__secondary-hexagon-wrapper">
            <div className="hexagon square server-card__version">
              <span>{server.version}</span>
            </div>
          </div>
          <div className="server-card__primary-hexagon-wrapper">
            <div className="hexagon square server-card__image">
              <img src={server.ServerIcon} alt="name" />
            </div>
          </div>
          <div className="server-card__secondary-hexagon-wrapper">
            <div className="hexagon square server-card__online">
              <span>{server.PvP ? 'PvP' : 'PvE'}</span>
            </div>
          </div>
        </div>
        <hr style={{ width: '70%' }} />
        <Typography
          className="server-card__title"
          variant="h6"
          component="h6"
        >
          {server.name.toUpperCase()}
        </Typography>
        <hr style={{ width: '70%' }} />
        <div className="server-card__mod-list">
          {mods.map((s: IMod, i: number) => {
            let url = s.image;
            if (url.startsWith('/')) url = BASE_URL + url;
            return (
              <div key={i}>
                <img src={url} alt={s.name} />
                <Typography
                  className="server-card__mod__name"
                  variant="h6"
                  component="span"
                  style={{ textAlign: 'center', color: 'white', fontWeight: '100' }}
                >
                  {s.name}
                </Typography>
              </div>
            );
          })}
          <div key="-1" style={{ textAlign: 'center' }}>
            <Typography
              variant="body2"
              component="p"
              style={{ color: 'gray', fontWeight: '100', marginTop: '1em' }}
              gutterBottom
            >
              ⬆️{t('main-mods')} ⬆️
            </Typography>
          </div>
        </div>
        <Typography
          className="see-details-link"
          variant="body1"
          component="h6"
          gutterBottom
          style={{ textAlign: 'center', color: 'gray', fontWeight: '100', textDecoration: "underline" }}
        >
          {t('see-details')}...
        </Typography>
      </Paper>
  );
};

export default ServerCard;
