import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import React, { FormEvent, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { thereWasOurSideError } from '../../../stores/notificationContext';
import { NotificationContext } from '../../../AppRouter';
import { deleteUserMembership } from '../../../api/admin';


export interface Props {
  onDeleted: () => void;
  user_id: number
  membership_id: number | null
}


const ModalMembershipDelete = (props: Props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const notificationContext = useContext(NotificationContext);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (props.membership_id === null) return;

    setLoading(true)
    deleteUserMembership(props.user_id, props.membership_id)
      .then(_response => {
        props.onDeleted();
      })
      .catch(error => {
        if (thereWasOurSideError(error, notificationContext)) return;
      })
      .finally(() => {
        setLoading(false)
      });

  };

  if (props.membership_id === null) return null;

  return (
    <form onSubmit={onSubmit} style={{position: "relative"}}>
      <Card sx={{ minWidth: 375 }}>
        <CardContent>
          <Typography variant='h6' gutterBottom>{t('Membership creation')}</Typography>
          <Box margin="8px">
            {t("Please confirm group membership removing")}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button disabled={loading} type="submit" variant="contained" color="error">{loading ? t("Loading") : t("Remove")}</Button>
          </Box>
        </CardContent>
      </Card>
    </form>

  );
};

export default ModalMembershipDelete;