import { useEffect } from 'react';
import { BASE_URL } from '../baseAPI';
import Container from '@mui/material/Container';
import NamedLoaderAbsolute from './NamedLoaderAbsolute';

export function RedirectToAdmin() {
  useEffect(() => {
    window.location.href = BASE_URL + "/backend/admin/";
  }, [])
  return <Container>
    <NamedLoaderAbsolute message="Redirecting to admin" visible/>
  </Container>
}