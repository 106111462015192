import "./AdminServersPage.css"
import AdminWrapper from '../component/AdminWrapper';
import React, { useContext, useState } from 'react';
import { useServersForAdmin } from '../../../hooks/useServers';
import NamedLoaderAbsolute from '../../../components/NamedLoaderAbsolute';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Card from '@mui/material/Card';
import { useSearchParams } from 'react-router-dom';
import ServerAdminInfo, { getAdminServerImage } from '../../../entities/server';
import { useTranslation } from 'react-i18next';
import { AuthContext, NotificationContext } from '../../../AppRouter';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';


// separate object to avoid copy Map every time
interface EditedSnapshots {
  readonly snapshots: Map<number, ServerAdminInfo>; // not Set because JS is shit without identity support
}


const AdminServersPage = () => {
  const { t } = useTranslation();
  let serversAdminInfo = useServersForAdmin();
  const [selectedServer, setSelectedServer] = useState<ServerAdminInfo | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [editedSnapshots, setEditedSnapshots] = useState<EditedSnapshots>({ snapshots: new Map() });
  const notificationContext = useContext(NotificationContext);
  const authContext = useContext(AuthContext);

  const hasSnapshot = (group: ServerAdminInfo | null): boolean => {
    if (group === null) return false;
    return editedSnapshots.snapshots.has(group.id);
  };
  const reactUpdateSnapshots = () => {setEditedSnapshots({ ...editedSnapshots });};
  const addSnapshot = (group: ServerAdminInfo) => {
    const deepcopy = JSON.parse(JSON.stringify(group));
    editedSnapshots.snapshots.set(group.id, deepcopy);
    reactUpdateSnapshots();
  };
  const removeSnapshot = (group: ServerAdminInfo) => {
    editedSnapshots.snapshots.delete(group.id);
    reactUpdateSnapshots();
  };
  const getSnapshot = (group: ServerAdminInfo): ServerAdminInfo => {
    const snapshot = editedSnapshots.snapshots.get(group.id);
    if (snapshot) return snapshot;
    throw new Error('Snapshot not exist, but asserted to exist. This is a bug');
  };

  if (authContext === null) return null;
  if (authContext.user === null) return null;

  return (
    <AdminWrapper name="Servers" link="/admin-panel/servers">
      <div>
        {serversAdminInfo.isLoading ? <NamedLoaderAbsolute message={t('Loading groups')} /> : null}
        {serversAdminInfo.isFetched && serversAdminInfo.data && <List dense>
          <Grid container spacing={2}>
            <Grid item xs={5} md={5}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} size='small' aria-label='Groups table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align='left'>Icon</TableCell>
                      <TableCell align='left'>Name</TableCell>
                      <TableCell align='left'>Version</TableCell>
                      <TableCell align='right'>...</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {serversAdminInfo.data.map((row) => (
                      <TableRow
                        className='group-row'
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        onClick={() => {
                          setSelectedServer(row);
                          setSearchParams({ selected: row.id.toString() });
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          {row.id}
                        </TableCell>
                        <TableCell align='left'>
                          <Avatar alt="Avatar" src={getAdminServerImage(row)} sx={{ width: 24, height: 24 }}/>
                        </TableCell>
                        <TableCell align='left'>{row.name}</TableCell>
                        <TableCell align='left'>{row.version}</TableCell>
                        <TableCell>
                          <EditOutlinedIcon fontSize='small' color='secondary' style={{opacity: hasSnapshot(row) ? "100" : "0"}}/>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={7} md={7}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  {
                    selectedServer === null
                      ? <Typography variant='h6' gutterBottom>{t('Please select group')}</Typography>
                      :
                      <>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant='h6' gutterBottom>
                            {hasSnapshot(selectedServer) ? t('Editing server') : t('Server info')}
                          </Typography>

                          {hasSnapshot(selectedServer) &&
                            <IconButton onClick={() => removeSnapshot(selectedServer)} color='primary'
                                        aria-label='Edit group'>
                              <EditOffOutlinedIcon fontSize='small' />
                            </IconButton>
                          }
                          {!hasSnapshot(selectedServer) &&
                            <Tooltip
                              title={authContext.user.hasPermission('web.admin-panel.manage-groups') ? 'Edit' : 'No permission'}>
                              <span
                                style={{ cursor: authContext.user.hasPermission('web.admin-panel.manage-groups') ? 'pointer' : 'not-allowed' }}>
                                <IconButton onClick={() => addSnapshot(selectedServer)} color='primary'
                                            aria-label='Edit group'
                                            disabled={!authContext.user.hasPermission('web.admin-panel.manage-groups')}
                                >
                                <EditOutlinedIcon fontSize='small' />
                              </IconButton>
                              </span>
                            </Tooltip>
                          }
                        </div>
                        {hasSnapshot(selectedServer)
                          ? <TextField key={'  mutable_key' + selectedServer.id} margin='dense' fullWidth
                                       variant='outlined' size="small" label='Key id'
                                       defaultValue={getSnapshot(selectedServer).name}
                                       onChange={(event) => getSnapshot(selectedServer).name = event.target.value} />
                          : <TextField key={'immutable_key' + selectedServer.id} margin='dense' fullWidth
                                       variant='outlined' size="small" label='Key id' value={selectedServer.name} disabled />
                        }

                        <Box sx={{ display: 'flex', justifyContent: 'center' }} m="10px">
                          <Button
                            onClick={() => {}}
                            disabled={!hasSnapshot(selectedServer)}
                            variant="outlined"
                            sx={{opacity: hasSnapshot(selectedServer) ? 100 : 0, transition: "0.3s ease-in-out"}}>
                            {t("Confirm")}
                          </Button>
                        </Box>
                      </>
                  }
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </List>}
      </div>
    </AdminWrapper>
  )
}

export default AdminServersPage