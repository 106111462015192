import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CSSTransition from 'react-transition-group/CSSTransition';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useUser from '../../hooks/useUser';
import { getAvatarUrl, prepareIdString } from '../../types/IUser';
import './ProfilePage.css';
import PieChartPlayerTime from '../../components/statistics/PieChartPlayerTime';
import useUserPlayingTime from '../../hooks/useUserPlayingTime';

const ProfilePage = (props: any) => {
  const { t } = useTranslation();
  const nodeRef = React.useRef(null);
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const identifier = prepareIdString(params.id);
  const { user, isLoading, isRefetching } = useUser(identifier);
  const prepareId = (id: string) => {
    if (id.startsWith('@')) return id.substring(1);
    return id;
  };

  return (
    <div style={{ background: '#222', minHeight: '80vh' }}>
      <div style={{ backgroundColor: '#333' }}>
        <div style={{ height: '20px' }} />
        <CSSTransition
          nodeRef={nodeRef}
          in={isLoading || isRefetching}
          classNames={'starshine-transition'}
          timeout={{ enter: 200, exit: 300 }}
        >
          <div ref={nodeRef}>
            <div id='avatar-image'>
              {user !== undefined ? <img src={getAvatarUrl(user)} alt='' /> : ''}
            </div>
            <Typography
              id='profile-username'
              variant='h5'
              component='h5'
              style={{}}
              gutterBottom
            >
              {isLoading || isRefetching ? (
                <CircularProgress color='warning' />
              ) : user === undefined ? (
                identifier
              ) : (
                user.username
              )}
            </Typography>
            <p id='profile-date-joined'>
              {isLoading || isRefetching
                ? 'Loading...'
                : user === undefined
                  ? `User with ${identifier} not found within system`
                  : t('member-of-the-project-since').toString() +
                  ' ' +
                  new Date(
                    Date.parse(user.date_joined === undefined ? '' : user.date_joined),
                  ).toLocaleDateString()}
            </p>
            <p id='profile-total-playtime'>
              {t('total-playtime') + (useUserPlayingTime(prepareId(identifier)).entries.reduce((a, entry) => a + entry.total, 0) / 3600).toFixed(0) + t('h.')}
            </p>
          </div>
        </CSSTransition>
        <div style={{ height: '20px' }} />
      </div>
      {user ? <PieChartPlayerTime user_id={user.pk} /> : null}
    </div>
  );
};

export default ProfilePage;
