import axios, { AxiosError } from 'axios';
import { GOOGLE_CAPTCHA_SITE_KEY } from '../stores/constants';
import i18next from 'i18next';


export function isAxiosError<ResponseType>(error: unknown): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error);
}

export enum ValidationErrorCode {
  MinLengthError = "MinLengthError",
  MaxLengthError = "MaxLengthError",
  FormatError = "FormatError",
  IsNotAlphanumeric = "IsNotAlphanumeric",
  IsNotLowercase = "IsNotLowercase",
  ObjectAlreadyExist = "ObjectAlreadyExist",
}



export interface ValidationError {
  validation_error_code: ValidationErrorCode,
  field: string,
  arg_numeric: number,
  arg_string: string,
}

export function toUNIXTs(date: Date): number {
  return Math.floor((date).getTime() / 1000)
}

export function prettyDateWithDaysAgo(datetime: string | null) {
  if (datetime === null) return null;
  const dt = new Date(datetime)
  const now = new Date()

  const td2 = toUNIXTs(now);
  const td1 = toUNIXTs(dt);

  const days = Math.floor((td2 - td1) / (24 * 60 * 60))

  return dt.toLocaleDateString() + `  (${days} days)`
}

export function handledValidation(error: any, obj: Map<string, (a: string | null) => void>): boolean {
  if (isValidationError(error)) {
    let validationError = error.response.data as ValidationError;

    console.log("validationError", validationError);
    let setter = obj.get(validationError.field);
    if (setter === undefined) {
      console.warn("handledValidation not received error setter for field:", validationError.field);
      return false;
    }

    switch (validationError.validation_error_code) {
      case ValidationErrorCode.MinLengthError:
        setter(i18next.t("Minimum {{len}} characters required", {len: validationError.arg_numeric}));
        break;
      case ValidationErrorCode.MaxLengthError:
        setter(i18next.t("Maximum {{len}} characters allowed", {len: validationError.arg_numeric}));
        break;
      case ValidationErrorCode.FormatError:
        setter(i18next.t("Format must be: '{{format}}'", {format: validationError.arg_numeric}));
        break;
      case ValidationErrorCode.IsNotAlphanumeric:
        setter(i18next.t("Must be alphanumeric"));
        break;
      case ValidationErrorCode.IsNotLowercase:
        setter(i18next.t("Must be only lowercase"));
        break;
      case ValidationErrorCode.ObjectAlreadyExist:
        setter(i18next.t("Object already exists"));
        break;
    }
  }
  return false;
}

export function isValidationError(error: any | AxiosError): boolean {
  if (!isAxiosError(error)) return false;
  if (error.response === undefined) return false;
  if (!(error.response.hasOwnProperty('data'))) return false;
  if (error.response.data === null) return false;

  if (!('error_code' in (error.response.data as any))) return false;

  // @ts-ignore
  return (typeof (error.response.data.error_code) === "string") && error.response.data.error_code === "ValidationError";
}

export function hasApplicationCode(error: any | AxiosError): boolean {
  if (!isAxiosError(error)) return false;
  if (error.response === undefined) return false;
  if (!('data' in error.response)) return false;
  if (!('error_code' in (error.response.data as any))) return false;

  // @ts-ignore
  return typeof (error.response.data.error_code) === "string";
}

export function getApplicationCode(error: any | AxiosError): string {
  // @ts-ignore
  return error.response.data.error_code;
}

export function getCooldownInMinutes(error: any | AxiosError): number {
  // @ts-ignore
  return error.response.data.cooldown_min;
}

export function filterNoCopy<T>(a: Array<T>, condition: (el: T) => boolean) {
  let i = 0, j = 0;

  while (i < a.length) {
    const val = a[i];
    if (condition(val)) a[j++] = val;
    i++;
  }

  a.length = j;
  return a;
}

export function isPermissionError(error: unknown): boolean {
  if (isAxiosError<{}>(error)) {
    if (error.response && error.response.status === 403) {
      return true;
    }
  }
  return false
}

export function loadCaptchaScriptToDOM() {
  const loadScriptByURL = (id: string, url: string, callback: any) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  // load the script by passing the URL
  loadScriptByURL(
    'recaptcha-key',
    `https://www.google.com/recaptcha/api.js?render=${GOOGLE_CAPTCHA_SITE_KEY}`,
    function () {
      // console.debug('Google captcha script loaded!');
    },
  );
}