import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getItemImage, IProduct } from '../../types/IProduct';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import './ProductInfoMobileModal.css'


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface ModalProps {
  product: IProduct | null,
  setOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void
  open: boolean
}

export default function ProductInfoMobileModal(props: ModalProps) {
  const handleClose = () => props.setOpen(false);

  if (props.product === null) return null;
  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style} className="modal-content">
            <Typography  id="transition-modal-title" variant="h6" component="h2">
              {props.product.name} <span onClick={() => handleClose()} style={{float: "right", marginRight: "0.5em"}}><CloseIcon/></span>
            </Typography>

            <ul id="modal-product-content-list">
              {props.product.items.map(rel => <li key={rel.item.id}>
                <img src={getItemImage(rel.item)} alt=""/>
                <span className="item-count">x{rel.count}</span>
                <span>{rel.item.name}</span>
              </li>)}
            </ul>

            <Typography id="modal-product-content-description" sx={{ mt: 2 }}>
              {props.product.description}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}