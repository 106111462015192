import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteGrant, PermissionGrantDTO } from '../../../api/admin';
import { filterNoCopy, isPermissionError } from '../../../utils/responses';
import { loadSelfFromBackendAndUpdateContext } from '../../../stores/authContext';
import { notification, thereWasOurSideError } from '../../../stores/notificationContext';
import { useAdminGroups } from './GroupAPI';
import { useQueryClient } from 'react-query';
import { AuthContext, NotificationContext } from '../../../AppRouter';
import { Group } from '../../../entities/group';


interface Props {
  setOpen: (a: boolean) => void
  grant: PermissionGrantDTO
  group: Group
}


const ModalDeletePermissionGrant = (props: Props) => {

  const { t } = useTranslation();
  const queryGroups = useAdminGroups();
  const queryClient = useQueryClient();
  const notificationContext = useContext(NotificationContext);
  const authContext = useContext(AuthContext);

  const removeGrant = () => {
    deleteGrant(props.group.id, props.grant.id)
      .then(() => {
        if (queryGroups.data === undefined) return;

        const group = queryGroups.data.find(group => group.id === props.group.id);
        if (group === undefined) return;

        filterNoCopy(group.permission_grants, (e) => e.id !== props.grant.id);

        queryClient.setQueryData(['groups'], [...queryGroups.data]);
        props.setOpen(false);
        loadSelfFromBackendAndUpdateContext(authContext);
        notification('success', t('Permission removed successfully'), null, null, notificationContext);

      })
      .catch(error => {
        if (thereWasOurSideError(error, notificationContext)) return;
        if (isPermissionError(error)) return;

      })
      .finally(() => {

      });
  };


  return <div>
    <DialogTitle id='alert-dialog-title'>
      {t('Remove group grant')} #{props.grant.id}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id='alert-dialog-description'>
        {t('Do you really want to remove permission grant {{grant}} from group {{group}}', {
          grant: props.grant.permission_key,
          group: props.group.key_id,
        })}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={_a => props.setOpen(false)}>Cancel</Button>
      <Button onClick={() => removeGrant()} autoFocus>
        Ok
      </Button>
    </DialogActions>
  </div>
}

export default ModalDeletePermissionGrant;