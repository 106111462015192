import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import React, { Children, useContext, useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import PeopleIcon from '@mui/icons-material/People';
import StorageIcon from '@mui/icons-material/Storage';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GroupsIcon from '@mui/icons-material/Groups';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import GavelIcon from '@mui/icons-material/Gavel';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CategoryIcon from '@mui/icons-material/Category';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import { AuthContext } from '../../../AppRouter';
import Tooltip from '@mui/material/Tooltip';


interface Props {
  children: React.ReactNode,
  name: string,
  link: string
}

const drawerWidth = 240;


const AdminWrapper = (props: Props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const location = useLocation();
  const element = Children.only(props.children);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {user} = useContext(AuthContext);

  const firstList = [
    {
      link: "/admin-panel/users",
      label: t('Users'),
      icon: <PeopleIcon />,
      permission: "web.admin-panel.read-users"
    },
    {
      link: "/admin-panel/servers",
      label: t('Servers'),
      icon: <StorageIcon/>,
      permission: "web.admin-panel.read-servers"
    },
    {
      link: "/admin-panel/audit",
      label: t('Audit log'),
      icon: <VerifiedUserIcon/>,
      permission: ""
    },
    {
      link: "/admin-panel/groups",
      label: t('Groups'),
      icon: <GroupsIcon/>,
      permission: "web.admin-panel.read-groups"
    },
    {
      link: "/admin-panel/products",
      label: t('Products'),
      icon: <CategoryIcon/>,
      permission: ""
    },
    {
      link: "/admin-panel/kits",
      label: t('Kits'),
      icon: <VideogameAssetIcon/>,
      permission: ""
    },
  ]

  const secondList = [
    {
      link: "/admin-panel/analytics",
      label: t('Analytics'),
      icon: <StackedBarChartIcon/>,
      permission: ""
    },
    {
      link: "/admin-panel/violations",
      label: t('Bans/violations'),
      icon: <GavelIcon/>,
      permission: ""
    },
    {
      link: "/admin-panel/transactions",
      label: t('Transactions'),
      icon: <CreditScoreIcon/>,
      permission: ""
    },
    {
      link: "/admin-panel/purchases",
      label: t('Purchases'),
      icon: <ShoppingCartIcon/>,
      permission: ""
    },
  ]

  if (user === null) return null;
  if (!user.hasPermission("web.admin-panel"))  {
    return <Navigate to="/permission-denied" state={{from: location}}/>
  }

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {setMobileOpen(!mobileOpen);
    }};

  return (<div style={{position: "relative"}}>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {t(props.name)}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <div>
            <Toolbar>
              <Link id='nav-logo-admin' to='/'>
                <img src='/SP_.jpg' alt='logo' />
                <span style={{ lineHeight: '2em', marginLeft: '0.5em' }}>SP Admin</span>
              </Link>
            </Toolbar>
            <Divider />
            <List>
              {firstList.map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  style={props.link === item.link?{textDecoration:"underline", background:"#ffffff14"}:{}}
                >
                  <Tooltip title={user.hasPermission(item.permission) ? item.label : "No permission"}>
                    <span style={{cursor: user.hasPermission(item.permission) ? "pointer" : 'not-allowed'}}>
                      <ListItemButton onClick={()=>{
                        navigate(item.link)
                      }}>
                        <ListItemIcon className="icon">
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.label} />
                      </ListItemButton>
                    </span>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List>
              {secondList.map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  style={props.link === item.link?{textDecoration:"underline", background:"#ffffff14"}:{}}
                >
                  <ListItemButton onClick={()=>{
                    navigate(item.link)
                  }}>
                    <ListItemIcon className="icon">
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>

        </Drawer>
        <Drawer variant="permanent" open
                sx={{ display: { xs: 'none', sm: 'block' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
        >
          <div>
            <Toolbar>
              <Link id='nav-logo-admin' to='/'>
                <img src='/SP_.jpg' alt='logo' />
                <span style={{ lineHeight: '2em', marginLeft: '0.5em' }}>SP Admin</span>
              </Link>
            </Toolbar>
            <Divider />
            <List>
              {firstList.map((item, index) => (
                <Tooltip
                  key={index}
                  placement="left-end"
                  title={user.hasPermission(item.permission) ? item.label : "No permission"}>
                  <ListItem
                    style={{
                      cursor: user.hasPermission(item.permission) ? "pointer" : 'not-allowed',
                      textDecoration: props.link === item.link ? "underline" : "none",
                      background: props.link === item.link ? "#ffffff14" : "unset"
                    }}
                    disablePadding
                  >

                      <ListItemButton disabled={!user.hasPermission(item.permission)} onClick={()=>{
                        navigate(item.link)
                      }}>
                        <ListItemIcon className="icon">
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.label} />
                      </ListItemButton>
                  </ListItem>
                </Tooltip>
              ))}
            </List>
            <Divider />
            <List>
              {secondList.map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  style={props.link === item.link?{textDecoration:"underline", background:"#ffffff14"}:{}}
                >
                  <ListItemButton onClick={()=>{
                    navigate(item.link)
                  }}>
                    <ListItemIcon className="icon">
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>

        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <div>
          {element}
        </div>
      </Box>
    </Box>
  </div>)
}

export default AdminWrapper;