import IUserSelfInfo from '../types/IUserSelfInfo';
import { Dispatch, SetStateAction } from 'react';
import { fetchSelf } from '../api/Users';


export enum AuthState {
  LOADING,
  AUTHORIZED,
  UNAUTHORIZED,
  ERROR,
}


export interface IAuthContext {
  state: AuthState
  user: IUserSelfInfo | null
  error: Error | null

  setState: Dispatch<SetStateAction<AuthState>>
  setError: Dispatch<SetStateAction<Error | null>>
  setUser: Dispatch<SetStateAction<IUserSelfInfo | null>>
}

export function initialAuthContext(): IAuthContext {
  return {
    state: AuthState.LOADING,
    user: null,
    error: null,

    setState: () => {},
    setError: () => {},
    setUser: () => {},
  }
}

export function loadSelfFromBackendAndUpdateContext(authContext: IAuthContext) {
  authContext.setState(AuthState.LOADING);
  fetchSelf()
    .then(user => {
      authContext.setUser(user)
      authContext.setState(AuthState.AUTHORIZED);
    })
    .catch(err => {
      if (err.response && err.response.status === 401) {
        authContext.setUser(null);
        authContext.setState(AuthState.UNAUTHORIZED);
        return
      }
      authContext.setError(err);
      authContext.setState(AuthState.ERROR);
    })
}