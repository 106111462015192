import * as React from 'react';
import './Rules.css';
import { useTranslation } from 'react-i18next';

const RulesPage = () => {
  const { t } = useTranslation();
  return (<div id='rules'>
    <div id='about-rules' className='sidebar-element'>
      <h3 id='unit-rules'>{t('HOW RULES WORK')}</h3>
      <div id='rules-project-logo'>
        <img src='/SP_.jpg' alt='' />
      </div>
      <p id='rules-description'>
        {t('rules-introduction')}
      </p>
      <h3 id='unit-rules'>{t('RULES LIST')}</h3>
    </div>

    <div className='setting-up-wrapper'>
      <div className='setting-up'>
        <div className='info-wrapper'>
          <div className='rules-instruction'>
            <h5 className='sp-heading'>{t('common-rules')}:</h5>
            <ul>
              <li>
                <span className='badge-warning'>1.1</span>
                <span> {t('1.1')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>1.2</span>
                <span> {t('1.2')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>1.3</span>
                <span> {t('1.3')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>1.4</span>
                <span> {t('1.4')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>1.5</span>
                <span> {t('1.5')}</span>
              </li>
              <li><span className='badge badge-warning'>1.6</span>
                <span> {t('1.6')}</span>
              </li>
              <li><span className='badge badge-warning'>1.7</span>
                <span> {t('1.7')}</span>
              </li>
            </ul>
            <h5 className='sp-heading'>{t('communication-rules')}</h5>
            <ul className='list-group list-group-flush'>
              <li>
                <span className='badge badge-warning'>2.1</span>
                <span> {t('2.1')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>2.2</span>
                <span> {t('2.2')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>2.3</span>
                <span> {t('2.3')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>2.4</span>
                <span> {t('2.4')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>2.5</span>
                <span> {t('2.5')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>2.6</span>
                <span> {t('2.6')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>2.7</span>
                <span> {t('2.7')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>2.8</span>
                <span> {t('2.8')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>2.9</span>
                <span> {t('2.9')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>2.10</span>
                <span> {t('2.10')}</span>
              </li>
            </ul>
            <h5 className='sp-heading'>{t('gameplay-rules')}</h5>
            <ul className='list-group list-group-flush'>
              <li>
                <span className='badge badge-warning'>3.1</span>
                <span> {t('3.1')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>3.2</span>
                <span> {t('3.2')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>3.3</span>
                <span> {t('3.3')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>3.4</span>
                <span> {t('3.4')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>3.5</span>
                <span> {t('3.5')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>3.6</span>
                <span> {t('3.6')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>3.7</span>
                <span> {t('3.7')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>3.8</span>
                <span> {t('3.8')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>3.9</span>
                <span> {t('3.9')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>3.10</span>
                <span> {t('3.10')}</span>
              </li>
            </ul>
            <h5 className='sp-heading'>{t('vanilla-rules')}</h5>
            <ul className='list-group list-group-flush'>
              <li>
                <span className='badge badge-warning'>4.1</span>
                <span> {t('4.1')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>4.2</span>
                <span> {t('4.2')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>4.3</span>
                <span> {t('4.3')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>4.4</span>
                <span> {t('4.4')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>4.5</span>
                <span> {t('4.5')}</span>
              </li>
              <li>
                <span className='badge badge-warning'>4.6</span>
                <span> {t('4.6')}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>);
};

export default RulesPage;