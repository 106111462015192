import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext, useState } from 'react';
import './BalanceModal.css';
import TextField from '@mui/material/TextField';
import { StarCoinIcon } from '../StarCoinIcon';
import Button from '@mui/material/Button';
import { getEnotPaymentRedirect } from '../../API';
import {
  handledError401AsNotification,
  thereWasOurSideError,
  notification,
} from '../../stores/notificationContext';
import { getApplicationCode, hasApplicationCode } from '../../utils/responses';
import { ErrorCode } from '../../api/codes';
import { NotificationContext } from '../../AppRouter';
import { useTranslation } from 'react-i18next';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


interface ModalProps {
  setOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void;
  open: boolean;
}

enum PaymentService {
  ENOT_IO
}

export default function BalanceModal(props: ModalProps) {
  const handleClose = () => props.setOpen(false);
  const { t } = useTranslation();

  const [agreementIsOpen, setAgreementOpen] = useState(false);
  const handleAgreementClose = () => setAgreementOpen(false);
  const notificationContext = useContext(NotificationContext);

  const [loading, setLoading] = useState(false);
  const [coins, setCoins] = useState<number | null>(null);

  const redirectToPaymentSystem = (system: PaymentService, total: number | null) => {
    if (total === null) return;
    if (system === PaymentService.ENOT_IO) {
      setLoading(true);
      getEnotPaymentRedirect(total)
        .then(response => {
          console.log("Redirecting to:", response.data.url);
          window.open(response.data.url);
        })
        .catch(error => {
          if (thereWasOurSideError(error, notificationContext)) return;
          if (handledError401AsNotification(error, notificationContext)) return;
          if (hasApplicationCode(error)) {
            switch (getApplicationCode(error)) {
              case ErrorCode.DONATION_SUM_NOT_SPECIFIED:
                notification("error", t("Donation sum is not specified"), null, null, notificationContext);
                break;
              case ErrorCode.DONATION_SUM_IS_INVALID:
                notification("error", t("Donation sum is invalid"), null, null, notificationContext);
                break;
            }
          }
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }

  // determine using language and country, maybe HTTP request
  const paymentProvider = PaymentService.ENOT_IO

  return (
    <React.Fragment>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={agreementIsOpen}
        onClose={handleAgreementClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={agreementIsOpen}>
          <Box sx={style} className='modal-content'>
            <Typography mb='1em' variant='h6' component='h2'>
              Публичная оферта <span onClick={() => handleAgreementClose()}
                                     style={{ float: 'right', marginRight: '0.5em' }}><CloseIcon /></span>
            </Typography>
            <div style={{ maxHeight: '400px', overflowY: 'scroll', width: '100%' }}>
              <Typography className='modal-agreement-paragraph'>
                - Перевод денег проекту считается добровольным пожертвованием. Таким образом, игроки помогают нам
                оплачивать хостинг, искать новые решения, покрывать прочие расходы проекта.
              </Typography>
              <Typography className='modal-agreement-paragraph'>
                - При внесении доната, каждый участник соглашается с тем, что внесенное пожертвование является
                безвозмездной помощью проекту. Ответной благодарностью на донат со стороны администрации проекта
                является предоставление различных бонусов в зависимости от выбора игрока. Возможность выбора реализуется
                через локальную внутриигровую валюту “starcoin”, которую невозможно никаким образом вывести с игровой
                платформы и которая не несет никакой фактической ценности.
              </Typography>
              <Typography className='modal-agreement-paragraph'>
                - Если произошла системная ошибка или товар не соответствует содержанию на игровом сервере (такое может
                быть если донат добавляли недавно), игрок в праве требовать возврат того количества star-coins которые
                были затрачены на покупку внутри игровой платформы.
              </Typography>
              <Typography className='modal-agreement-paragraph'>
                - Политика возвратов: так как перевод денег является добровольным пожертвованием, он не может быть
                рассмотрен к возврату. В случае если происходит системная ошибка при донате, эти случаи рассматриваются
                индивидуально с администрацией. Если у вас возникла проблема, свяжитесь с нами.
              </Typography>
            </div>

            <Typography id='transition-modal-title' variant='h6' component='h2'>
              Связь с администрацией по любым вопросам:<br />
              Discord: <a href={'https://discord.com/invite/tv3cBnd'} >Starshine Project</a><br />
              EMAIL: <a href={'mailto:system-mails@starshine-project.ru'}>system-mails@starshine-project.ru</a>
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style} className='modal-content'>
            <form onSubmit={(e) => {
              e.preventDefault()
              redirectToPaymentSystem(paymentProvider, coins)
            }}>
              <Typography id='transition-modal-title' variant='h6' component='h2'>
                Пополнение баланса (донат) <span onClick={() => handleClose()} style={{
                float: 'right',
                marginRight: '0.5em',
                marginBottom: '1em',
              }}><CloseIcon /></span>
              </Typography>
              <TextField
                style={{ width: '100%' }}
                id='donation-sum'
                helperText={<span>1₽ = 1<StarCoinIcon noWrap /></span>}
                label='Количество starcoins'
                type='number'
                onChange={(event) => setCoins(Number(event.target.value))}
                required
                inputProps={{ pattern: '[0-9]*' }}
                InputProps={{
                  inputMode: 'numeric',
                  inputProps: { min: "10", max: "10000", id: "donation-sum" }
                }}
                variant='standard'
              />
              <div id='coins-buying-buttons' className='modal-footer'>
              <span style={{ flex: 1 }}>
                <a href='#' onClick={() => {
                  setAgreementOpen(true);
                }}>Пользовательское соглашение</a>
              </span>
                <Button
                  id='buy-button'
                  color='info'
                  variant='contained'
                  disabled={loading}
                  type="submit"
                  sx={{ my: 2, color: 'black', display: 'block' }}
                >
                  {loading ? 'Loading...' :
                    <React.Fragment>Перевести<span id='buying-confirmation-product-price'></span><img
                      src='/starcoin-min.png' style={{ height: '1em', margin: '0 0 3px 2px' }}
                      alt='' /></React.Fragment>}
                </Button>
              </div>
              <Typography id='modal-product-content-description' sx={{ mt: 2 }}>
                Вопреки тому, что майнкрафт принято считать детской игрой, ведение проекта для нашей команды - сотни
                часов работы и огромные усилия.
              </Typography>
              <Typography id='thanks-for-support' sx={{ mt: 2 }} textAlign='center'>
                💛 Спасибо за поддержку 💛
              </Typography>
            </form>
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}