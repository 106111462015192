import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import StarsineTextField from '../../components/Forms/StarshineTextField';
import WholeScreenLoader from '../../components/WholeScreenLoader';
import Copyright from '../../components/Copyright';
import { useTranslation } from 'react-i18next';
import { GOOGLE_CAPTCHA_SITE_KEY } from '../../stores/constants';
import { ActionStatus } from '../../types/ActionStatus';
import Alert from '@mui/material/Alert';
import { AuthContext, NotificationContext } from '../../AppRouter';
import { AuthState } from '../../stores/authContext';
import { thereWasOurSideError } from '../../stores/notificationContext';
import { register } from '../../api/auth';
import {
  getApplicationCode,
  hasApplicationCode,
  loadCaptchaScriptToDOM,
} from '../../utils/responses';
import { ErrorCode } from '../../api/codes';

function SignUpPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<ActionStatus | null>()
  const [errorUsername, setErrorUsername] = useState<string | null>(null)
  const [errorPassword, setErrorPassword] = useState<string | null>(null)
  const [errorEmail, setErrorEmail] = useState<string | null>(null)
  useEffect(loadCaptchaScriptToDOM, []);

  if (authContext.state == AuthState.AUTHORIZED) {
    return <Navigate to="/" replace />;
  }

  if (authContext.state == AuthState.LOADING) {
    return <WholeScreenLoader />;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let token = null;

    setErrorEmail(null);
    setErrorUsername(null);
    setErrorPassword(null);
    setStatus(null)

    if (window.grecaptcha === undefined) {
      setStatus({severity: "error", message: t("Failed to load Google Captcha service. Maybe Google has problems right now. Please try to reload page.")})
      return
    }
    token = await window.grecaptcha.execute(GOOGLE_CAPTCHA_SITE_KEY, { action: 'registration' });

    const username = data.get('username');
    const email = data.get('email');
    const password1 = data.get('password');
    const password2 = data.get('password2');
    const accept = data.get('accept-rules-and-emails') !== null;

    if (username === null) return
    if (email === null) return
    if (password1 === null) return
    if (password2 === null) return
    if (accept === null) return

    if (password1 !== password2) {
      setStatus({severity: "error", message: t("Passwords do not match")})
      return
    }

    if (!accept) {
      setStatus({severity: "warning", message: t("You need to accept rules and allow email sending" +
          " in order to register. The need to accept rules is clear," +
          " and email sending is necessary to confirm the account.")})
      return
    }

    try {
      setLoading(true)
      await register(
        username.toString(),
        password1.toString(),
        email.toString(),
        token
      )
      navigate("/login")
    } catch (error: any) {
      if (thereWasOurSideError(error, notificationContext)) return;

      if (hasApplicationCode(error)) {
        switch (getApplicationCode(error)) {
          case ErrorCode.BAD_CAPTCHA:
            setStatus({severity: "error", message: t("Google captcha did not approved your request. Try wait for some time and try again (are you using Tor browser)")});
            break;
          case ErrorCode.REGISTRATION_USERNAME_TOO_SHORT:
            setErrorUsername(t("Username is too short. Minimum 3 characters allowed"))
            break;
          case ErrorCode.REGISTRATION_EMAIL_INVALID:
            setErrorEmail(t("Email is invalid: {{error}}", {error: error.response.data.message}))
            break;
          case ErrorCode.REGISTRATION_USERNAME_TOO_LONG:
            setErrorUsername(t("Username is too long. Maximum 16 characters allowed"))
            break;
          case ErrorCode.REGISTRATION_USERNAME_INVALID:
            setErrorUsername(t("Username must contain only alphanumeric English letters or _ (but cannot starts with _ )"))
            break;
          case ErrorCode.REGISTRATION_USERNAME_RESERVED:
            setErrorUsername(t("Username is invalid: reserved keyword"))
            break
          case ErrorCode.REGISTRATION_TOO_MANY_ATTEMPTS:
            setStatus({severity: "error", message: t("Too many registrations. If you need help, please contact admins in discord.")})
            break;
          case ErrorCode.REGISTRATION_USERNAME_EXIST:
            setErrorUsername(t("User with this nickname already registered"))
            break;
          case ErrorCode.REGISTRATION_EMAIL_EXIST:
            setErrorEmail(t("User with this email already registered"))
            break;
        }
      }
    } finally {
      setLoading(false)
    }
  };
  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <PersonAddAlt1Icon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("Sign Up")}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <StarsineTextField
              inputProps={{maxLength: 16}}
              margin="normal"
              required
              fullWidth
              id="username"
              label={t("Username")}
              helperText={errorUsername !== null ? errorUsername : t('Your in-game nickname which is visible by other players')}
              name="username"
              error={errorUsername !== null}
              autoComplete="username"
              autoFocus
            />
            <StarsineTextField
              margin="normal"
              inputProps={{maxLength: 48}}
              required
              fullWidth
              id="email"
              type="email"
              label={t("Email Address")}
              helperText={errorEmail !== null ? errorEmail : t('Used for notifications and password reset')}
              error={errorEmail !== null}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <StarsineTextField
              margin="normal"
              required
              fullWidth
              inputProps={{maxLength: 160}}
              helperText={errorPassword !== null ? errorPassword : t("Not less than 8 characters")}
              error={errorPassword !== null}
              name="password"
              label={t("Password")}
              type="password"
              id="password"
              autoComplete="off"
            />
            <input type="hidden" name="g-recaptcha-response" id="recaptcha" />
            <StarsineTextField
              margin="normal"
              required
              fullWidth
              inputProps={{maxLength: 160}}
              helperText={t('Please save the password somewhere (file, password management app, etc). You will need to sign in after registration.')}
              name="password2"
              label={t("Confirm Password")}
              type="password"
              id="password2"
              autoComplete="off"
            />
            {status ? <Alert severity={status.severity}>{status.message}</Alert> : null}
            <FormControlLabel
              control={<Checkbox name="accept-rules-and-emails" color="primary" />}
              label={t("I accept rules of the platform and allow emails from starshine project").toString()}
            />
            <Button
              type="submit"
              fullWidth
              disabled={loading}
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: 'secondary.main',
                '&:hover': {
                  backgroundColor: '#fff',
                  color: '#000',
                },
              }}
            >
              {loading ? t("Loading") : t('Sign Up')}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link className="small-link" to="/password-reset">
                  {t("Forgot password?")}
                </Link>
              </Grid>
              <Grid item>
                <Link className="small-link" to="/login">
                  {t("Already a member? Sign In")}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}

export default SignUpPage;
