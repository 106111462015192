import Container from '@mui/material/Container'
import './LetsPlay.css'
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StarshineHeader } from '../../components/StarshineHeader';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';


const OS_LINUX = 'linux'
const OS_WINDOWS = 'windows'
const OS_MAC = 'macos'
const OS_DEFAULT = 'unknown'

const LAUNCHER_WINDOWS_URL = "https://installer.starshine-project.ru/win64/FusionLauncherInstaller.exe"
const LAUNCHER_MACOS_URL = "https://installer.starshine-project.ru/macos/FusionLauncher.dmg"
const LAUNCHER_LINUX_URL = "https://installer.starshine-project.ru/linux64/fusionlauncher-x86_64.tar.gz"

function getOperatingSystem() {
  if (window.navigator.appVersion.indexOf('Win') !== -1) return OS_WINDOWS
  if (window.navigator.appVersion.indexOf('Mac') !== -1) return OS_MAC
  if (window.navigator.appVersion.indexOf('X11') !== -1) return OS_LINUX
  if (window.navigator.appVersion.indexOf('Linux') !== -1) return OS_LINUX

  return OS_DEFAULT;
}

const OS = getOperatingSystem();
let imageOfOsUrl = "/block_of_emerald.webp"
let launcherUrl = LAUNCHER_LINUX_URL


if (OS === OS_WINDOWS) {
  imageOfOsUrl = "/block_of_gold.webp"
  launcherUrl = LAUNCHER_WINDOWS_URL
}
if (OS === OS_MAC) {
  imageOfOsUrl = "/block_of_diamond.webp"
  launcherUrl = LAUNCHER_MACOS_URL
}
if (OS === OS_LINUX) {
  imageOfOsUrl = "/block_of_emerald.webp"
  launcherUrl = LAUNCHER_LINUX_URL
}


export default function LetsPlay() {
  const { t } = useTranslation()
  const [modalIsOpen, setModalOpen] = useState<boolean>(false);

  return <div style={{ background: '#222', minHeight: "80vh" }}>
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={modalIsOpen}
      onClose={() => {setModalOpen(false)}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalIsOpen}>
        <Box id="explanation-modal" className='modal-content' >
          <Typography mb='1em' variant='h6' component='h2'>
            {t('Windows thing')}<span onClick={() => setModalOpen(false)}
                                   style={{ float: 'right', marginRight: '0.5em' }}><CloseIcon /></span>
          </Typography>

          <Typography id='explanation-body' variant='h6' component='h2'>
            <img src='/windows_defender.jpg' alt='defender' />
          </Typography>
          <Typography id='explanation-body' component='p'>
            {t('move-from-downloads-explanation')}
          </Typography>
        </Box>
      </Fade>
    </Modal>
    <Container id="main-downloading-section" maxWidth="md">
      <img src={imageOfOsUrl} title={OS} alt='block' />
      <a href={launcherUrl} target="_blank" download>
        <Button
          color="success"
          variant="contained"
          sx={{ my: 2, color: 'black', display: 'block' }}
          onClick={() => {}}
        >
          {t("Download Launcher")}
        </Button>
      </a>
      <Container id="main-downloading-section-explanation" maxWidth="sm">
        {(OS === OS_WINDOWS) ? <>
          {t('If you have Windows Smart Screen Defender warning, just press More info → Run Anyway')}
          <br/>
          <br/>
          </> : null}

        {(OS === OS_MAC) ? <>
          {t('You probably need to install application via finder')}
          <br/>
          <br/>
        </> : null}

        {(OS === OS_LINUX) ? <>
          {t('Just untar anywhere you want and run')}
          <br/>
          <br/>
        </> : null}
      </Container>
    </Container>
    <StarshineHeader message={t("OS specific launcher versions")}></StarshineHeader>
    <Container id="os-specific-downloading-section" maxWidth="md">
      <div className="os-specific-download-section">
        <img src="/block_of_gold.webp" alt='block' />
        <a href={LAUNCHER_WINDOWS_URL} target="_blank" download>
          <Button
            color="success"
            variant="outlined"
            size="small"
            sx={{ my: 2, color: 'black', display: 'block' }}
          >
            {t("Download Launcher")}
          </Button>
        </a>
        <Container className="os-specific-section-span" maxWidth="sm">
          Windows 64
        </Container>
      </div>

      <div className="os-specific-download-section">
        <img src="/block_of_diamond.webp" alt='block' />
        <a href={LAUNCHER_MACOS_URL} target="_blank" download>
          <Button
            color="success"
            variant="outlined"
            size="small"
            sx={{ my: 2, color: 'black', display: 'block' }}
          >
            {t("Download Launcher")}
          </Button>
        </a>
        <Container className="os-specific-section-span" maxWidth="sm">
          Mac OS <br/> (Apple Silicon/x86)
        </Container>
      </div>

      <div className="os-specific-download-section">
        <img src="/block_of_emerald.webp" alt='block' />
        <a href={LAUNCHER_LINUX_URL} target="_blank" download>
          <Button
            color="success"
            variant="outlined"
            size="small"
            sx={{ my: 2, color: 'black', display: 'block' }}
          >
            {t("Download Launcher")}
          </Button>
        </a>
        <Container className="os-specific-section-span" maxWidth="sm">
          Linux
        </Container>
      </div>
    </Container>
  </div>
}