import { useContext, useEffect } from 'react';
import NamedLoaderAbsolute from './NamedLoaderAbsolute';
import { AuthContext } from '../AppRouter';
import { AuthState } from '../stores/authContext';

const CLIENT_ID = "596690955394875392"
const REDIRECT_URI = "https://starshine-project.ru/accounts/discord/sync"
const SCOPE = "identify%20guilds%20guilds.join%20email"
const RESPONSE_TYPE = "code"

export function RedirectToDiscord() {
  const {state} = useContext(AuthContext);

  useEffect(() => {
    if (state != AuthState.AUTHORIZED) return;
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${CLIENT_ID}` +
      `&redirect_uri=${REDIRECT_URI}` +
      `&response_type=${RESPONSE_TYPE}` +
      `&scope=${SCOPE}`;
  }, [state])
  return <div style={{width: "100vw", height: "100vh", background: "#222", position: "relative"}}>
    <NamedLoaderAbsolute message="Redirecting to discord" visible/>
  </div>
}