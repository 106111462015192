import i18next from 'i18next';


export function valid_json(value: string | null, allow_null: boolean): boolean {
  if (allow_null && value == null) return true;
  if (value == null) return false;

  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }

}

export interface ValidationResult {
  ok: boolean;
  message: string | null;
}

export function valid_localized_string_raw(value: string | null, allow_null: boolean): ValidationResult {
  if (!valid_json(value, false)) return { ok: false, message: 'Not valid json' };
  if (allow_null && (value == null || value === 'null')) return { ok: true, message: null };
  if (value == null || value == 'null') return { ok: false, message: 'Cannot be null' };

  const object = JSON.parse(value);

  if (!object.hasOwnProperty('EN')) return { ok: false, message: 'Must have english default ("EN": "something")' };
  if (object.EN === '') return { ok: false, message: 'EN default must not be empty' };

  return { ok: true, message: null };
}

export function validLocalizedString(
  value: FormDataEntryValue | null,
  errorSetter: (a: string | null) => void,
  nullable: boolean,
): boolean {
  if (nullable && (value == null || value === 'null')) {
    errorSetter(null);
    return true;
  }
  if (value == null || value == 'null') {
    errorSetter(i18next.t('Cannot be null'));
    return false;
  }
  if (typeof value !== 'string') {
    errorSetter(i18next.t('Field must be JSON string type'));
    return false;
  }

  try {
    let object = JSON.parse(value);
    if (!object.hasOwnProperty('EN')) {
      errorSetter(i18next.t('Must have english default ("EN": "something")' ));
      return false;
    }

    if (object.EN === '') {
      errorSetter(i18next.t('EN default must not be empty' ));
      return false;
    }
  } catch (e) {
    errorSetter(i18next.t('Not valid json'));
    return false;
  }

  errorSetter(null);
  return true;
}

const colorRegex6 = new RegExp("^#([0-9a-fA-F]{6})$", "g");

export function validColor(
  value: FormDataEntryValue | null,
  setter: (a: string | null) => void,
  nullable: boolean,
): boolean {
  if (!nullable && value === null) {
    setter(i18next.t('Field cannot be empty'));
    return false;
  }
  if (value === null) {
    setter(null);
    return true;
  }
  if (typeof value !== 'string') {
    setter(i18next.t('Field must be string with format #......'));
    return false;
  }
  if (value.length != 7 || !value.startsWith("#") || !value.matchAll(colorRegex6)) {
    setter(i18next.t('Field must be string with format #123abc'));
    return false;
  }

  setter(null);
  return true
}

export function validLength(
  value: FormDataEntryValue | null,
  min: number,
  max: number,
  setter: (a: string | null) => void,
): boolean {
  if (value === null) {
    setter(i18next.t('Field cannot be empty'));
    return false;
  }
  if (typeof value !== 'string') {
    setter(i18next.t('Field must be string type'));
    return false;
  }
  if (value.length < min) {
    setter(i18next.t('Value is too short. Minimum {{len}} characters', { len: min }));
    return false;
  }
  if (value.length > max) {
    setter(i18next.t('Value is too long. Maximum {{len}} characters', { len: min }));
    return false;
  }
  setter(null);
  return true;
}