import { useQuery } from 'react-query';
import { getOnline, getPhotos } from '../API';

export const useOnline = (server_id: number | undefined) => {
  let result = useQuery([`server-online-${server_id}`], () => getOnline(server_id).then((r) => r.data), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !isNaN(Number(server_id))
  });
  return {onlineStatistics: result.data, areOnlineStatisticsLoading: result.isLoading, ...result};
};

export default useOnline;
