import { BASE_URL } from '../baseAPI';

export interface IPhoto {
  id: number
  author: number,
  description: string,
  image: string,
  label: string,
  server: number,
  timeStamp: string
  uploaded_by: number
}

export function getImage(photo: IPhoto) {
  return photo.image.startsWith("http") ? photo.image : BASE_URL + photo.image
}