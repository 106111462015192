import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CSSTransition from 'react-transition-group/CSSTransition';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ServerCard from '../../components/MainServerCard/ServerCard';
import useDonationsPlayersTop from '../../hooks/useDonationsPlayersTop';
import usePlayingTimePlayersTop from '../../hooks/usePlayingTimePlayersTop';
import useServers from '../../hooks/useServers';
import { getAvatarUrl } from '../../types/IUser';
import './About.css';
import './MainPage.css';
import { useTranslation } from 'react-i18next';
import { StarshineHeader } from '../../components/StarshineHeader';
import { TextAlign } from 'chart.js';
import NamedLoaderAbsolute from '../../components/NamedLoaderAbsolute';
import { loadCaptchaScriptToDOM } from '../../utils/responses';


const DISCORD_LIST_STYLE = {
  textAlign: 'right' as TextAlign,
  fontFamily: "Roboto",
  color: 'white',
  fontWeight: '100',
  width: "100%",
  fontSize: "15px"
};

const MainPage = () => {
  const { servers, isLoading } = useServers();
  const navigate = useNavigate();
  const isServersLoading = isLoading;

  const nodeRef = React.useRef(null);
  const { playingTimeEntries, arePlayingTimeEntriesLoading } = usePlayingTimePlayersTop();
  const { donationEntries, areDonationEntriesLoading } = useDonationsPlayersTop();
  const { t } = useTranslation();

  // load captcha at once to allow Google to monitor user behavior in advance
  useEffect(loadCaptchaScriptToDOM, []);

  return (
    <div style={{ background: '#222' }}>
      <div>
        <div id="main-starshine-header">
          <div id="project-description">
            <Typography variant="h4" component="h2" gutterBottom fontFamily="Audiowide">
              STARSHINE PROJECT
            </Typography>
            <Typography className="project-introduction-cringe" variant="body1" component="p" gutterBottom>
              - {t('Unique lovingly balanced modpacks')}
              <br/>
              - {t('No toxics in community (almost)')}
              <br/>
              - {t('Cheaters get bananas')}
              <br/>
            </Typography>
            <Typography className="project-introduction" variant="body1" component="p" gutterBottom>
              🐭 {t('Play with us')} 🐭
            </Typography>

            <Box id="play-button-wrapper" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                color="warning"
                variant="contained"
                sx={{ my: 2, color: 'black', display: 'block' }}
                onClick={() => {navigate(`play/`)}}
              >
                {t("play-button")}
              </Button>
            </Box>
          </div>
          <div id="project-image-wrapper">
            <img src="/magic-repr.webp" alt="server" />
          </div>
        </div>
        <div id="main-starshine-header-bottom-triangle">
          <Typography
            variant="h5"
            component="h5"
            gutterBottom
            textAlign="center"
          >
            {t('our-servers')}
          </Typography>
        </div>
      </div>

      <CSSTransition
        nodeRef={nodeRef}
        in={isServersLoading}
        classNames={'server-card-transition'}
        timeout={{ enter: 500, exit: 1000 }}
      >
        {isServersLoading ? (
          <Box
            ref={nodeRef}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100vw',
              minHeight: '25vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div id="main-servers" ref={nodeRef}>
            {servers &&
              servers.map((server, i) => (
                <ServerCard key={i} server={server} onClick={() => navigate(`servers/${server.id}`)} />
              ))}
          </div>
        )}
      </CSSTransition>

      <div className="sidebar-element">
        <h3 className="main-heading-separator">Vanilla 1.20.4</h3>
        <Container maxWidth='md' id="vanilla-server">

          <div style={{textAlign: "center", padding: "8px"}}>
            <Typography style={{padding: "8px"}} variant="subtitle2" component="h6" color="gray">
              {t('Direct connection')}:
            </Typography>
            <span className="vanilla-address">5.188.158.177:24200</span>
            <Typography style={{padding: "8px"}} variant="subtitle2" component="h6" color="gray">
              {t('Reverse-proxy for Ukraine and other countries')}:
            </Typography>
            <span className="vanilla-address">vanilla-v1-n1.starshine-project.ru</span>
          </div>

          <div style={{display: 'flex', width: "85%", margin: '2em auto'}}>
            <div className="vanilla-core">
              <div className="vanilla-core-item">
                <img src="mojang-logo.webp" alt="M" />
                <Typography className="server-card__mod__name" variant="h6" component="span">
                  {t('License or Our-launcher')}
                </Typography>
              </div>

              <div className="vanilla-core-item">
                <img src="fabric.webp" alt="M" />
                <Typography className="server-card__mod__name" variant="h6" component="span">
                  {t('Fabric Powered')}
                </Typography>
              </div>

              <div className="vanilla-core-item">
                <img src="diamond_sword.png" alt="M" />
                <Typography className="server-card__mod__name" variant="h6" component="span">
                  PvP
                </Typography>
              </div>

              <div className="vanilla-core-item">
                <img src="barrier.png" alt="M" />
                <Typography className="server-card__mod__name" variant="h6" component="span">
                  {t('No regions')}
                </Typography>
              </div>

              <div className="vanilla-core-item">
                <img src="mojang-logo.webp" alt="M" />
                <Typography className="server-card__mod__name" variant="h6" component="span">
                  Java Edition
                </Typography>
              </div>
            </div>
            <div className="vanilla-mods">
              <div className="vanilla-core-item">
                <Typography className="server-card__mod__name" variant="h6" component="span">
                  Voice Chat
                </Typography>
                <img src="voicechat.png" alt="M" />
              </div>

              <div className="vanilla-core-item">
                <Typography className="server-card__mod__name" variant="h6" component="span">
                  Emotecraft
                </Typography>
                <img src="fabric.webp" alt="M" />
              </div>

              <div className="vanilla-core-item">
                <Typography className="server-card__mod__name" variant="h6" component="span">
                  {t('Beer!')}
                </Typography>
                <img src="fabric.webp" alt="M" />
              </div>

              <div className="vanilla-core-item">
                <Typography className="server-card__mod__name" variant="h6" component="span">
                  Optimisations
                </Typography>
                <img src="fabric.webp" alt="M" />
              </div>

              <div className="vanilla-core-item">
                <Typography className="server-card__mod__name" variant="h6" component="span">
                  Custom Worldgen
                </Typography>
                <img src="fabric.webp" alt="M" />
              </div>
            </div>
          </div>

          {/*<div style={{textAlign: "center", padding: "8px", margin: "4px 0"}}>*/}
          {/*  <Typography style={{padding: "8px"}} variant="subtitle2" component="h6" color="gray">*/}
          {/*    {t('To play from Bedrock you still need Java Edition License')}*/}
          {/*  </Typography>*/}
          {/*</div>*/}
        </Container>
      </div>

      <div id="about-project" className="sidebar-element">
        <h3 className="main-heading-separator">{t('about-sp')}</h3>
        <div id="project-logos">
          <div id="project-logo-wrapper">
            <img id="project-logo" src="SP_.jpg" alt="" />
          </div>
          <div style={{ flex: 1.5 }}></div>
          <div id="project-logo-wrapper">
            <img id="project-logo" src="obsidiantime.jpg" alt="" />
          </div>
        </div>
        <Typography
          id="project-story"
          variant="body1"
          component="p"
          gutterBottom
          style={{ textAlign: 'center', color: 'white', fontWeight: '100' }}
        >
          {t('The Starshine Project was founded in 2018' +
            ' for Obsidian Empire as a streaming platform' +
            ' for members of the Obsidian Time discord server and channel.' +
            ' The highest goal of the project is to give our players ' +
            'memories of a pleasant pastime')}
        </Typography>
      </div>

      <h3 className="main-heading-separator">{t('OUR COMMUNITY')}</h3>

      <div id="community-tops">
        <div id="total-playing-top">
          <h2>{t("Top by playing time")}</h2>
          <NamedLoaderAbsolute visible={arePlayingTimeEntriesLoading} message={t("Loading...")}/>
          {playingTimeEntries &&
            playingTimeEntries.map((entry, i) => (
              <Link key={i} className="no-style-link" to={'/profiles/' + entry.user.id}>
                <div className="player-top-entry" key={i}>
                  <img src={getAvatarUrl(entry.user)} alt="" />
                  <span className="entry-username">{entry.user.username}</span>
                  <span className="entry-meta">{Math.floor(entry.total / 3600)} {t('h.')}</span>
                </div>
              </Link>
            ))}
          <Typography
            component="p"
            gutterBottom
            style={{ textAlign: 'center', color: 'white', fontWeight: '100' }}
          >
            {t("For last half a year")}
          </Typography>
        </div>
        <div id="total-donation-top">
          <h2>{t("Top supporters")}</h2>
          <NamedLoaderAbsolute visible={areDonationEntriesLoading} message={t("Loading...")}/>
          {donationEntries &&
            donationEntries.map((entry, i) => (
              <Link key={i} className="no-style-link" to={'/profiles/' + entry.user.id}>
                <div className="player-top-entry" key={i}>
                  <img src={getAvatarUrl(entry.user)} alt="" />
                  <span className="entry-username">{entry.user.username}</span>
                  <span className="entry-meta">
                    {entry.total ? Math.floor(entry.total) + ' ₽' : '#' + entry.place}
                  </span>
                </div>
              </Link>
            ))}
          <Typography
            component="p"
            gutterBottom
            style={{ textAlign: 'center', color: 'white', fontWeight: '100' }}
          >
            {t("For last 2 years")}
          </Typography>
        </div>
      </div>

      <StarshineHeader message='Discord' />
      <Container maxWidth="md" sx={{display: "flex", flexWrap: "wrap", marginTop: "1em"}}>
        <div id="discord-description">
          <Typography
            component="p"
            gutterBottom
            style={{ textAlign: 'right', fontFamily: "Roboto", width: "100%", fontSize: "18 px", color: 'gold', fontWeight: '100' }}
          >
            {t("In our discord server you can:")}
          </Typography>
          <Typography display="block" component="p" gutterBottom style={DISCORD_LIST_STYLE}>
            + {t("ask questions")}
          </Typography>
          <Typography component="p" gutterBottom style={DISCORD_LIST_STYLE}>
            + {t("get help")}
          </Typography>
          <Typography component="p" gutterBottom style={DISCORD_LIST_STYLE}>
            + {t("find team")}
          </Typography>
          <Typography component="p" gutterBottom style={DISCORD_LIST_STYLE}>
            + {t("share your Minecraft buildings")}
          </Typography>
          <Typography component="p" gutterBottom style={DISCORD_LIST_STYLE}>
            + {t("communicate and have fun")}
          </Typography>
        </div>
        <div id="discord-widget" style={{
          flex: 1,
          flexBasis: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <iframe
            className="discord"
            style={{display: "block"}}
            src="https://discordapp.com/widget?id=485008379702673419&theme=dark" width="300"
            height="400"
            frameBorder="0"
          ></iframe>
        </div>
      </Container>
    </div>
  );
};

export default MainPage;
