import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';


interface Props {
  resource?: boolean
}


export default function Page404(props: Props) {
  const resource = props.resource === undefined ? false : props.resource
  const { t } = useTranslation();
  return <div id="wrapper-404">
    <img src='/hat_kid_404.jpg' alt='hat-kid-404' />
    <Typography variant="h5" component="h5">
      {resource ? t("Resource not found") : t("Page not found")}
    </Typography>
  </div>
}