import IServer from './IServer';
import { BASE_URL } from '../baseAPI';


interface IInternalItem {
  id: number
  command: string,
  description: string,
  duration: number,
  image: string,
  material_id: string,
  max_stack_size: number,
  meta: string,
  mod: number,
  name: string,
  name_ru: string,
  nbt: string,
  permission: string,
  type: string
}


export interface IInventoryEntry {
  count: number,
  user: number,
  servers: IServer[],
  item: IInternalItem
}

export function getInventoryEntryImage(inventory: IInventoryEntry) {
  return inventory.item.image.startsWith("http") ? inventory.item.image : BASE_URL + inventory.item.image
}