import { useQuery } from 'react-query';
import { getAllGroups } from '../../../api/admin';
import { Group } from '../../../entities/group';


export const useAdminGroups = () => {
  return useQuery([`groups-list`], () => {
    return getAllGroups().then((r) => {
      return r.data
    });
  }, {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export const useAdminGroupsMap = () => {
  return useQuery([`groups-map`], () => {
    return getAllGroups().then((response) => {
      const result = new Map<number, Group>();
      response.data.forEach(group => {
        result.set(group.id, group)
      })
      return result
    });
  }, {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}