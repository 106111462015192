import { useQuery } from 'react-query';
import { getAllAttributes, getAllPermissions, getGroupModifiers } from '../api/admin';


export function usePermissions() {
  return useQuery(['permissions'], () => {
    return getAllPermissions().then((r) => r.data.sort((a,b) => (a.key_id > b.key_id) ? 1 : ((b.key_id > a.key_id) ? -1 : 0)));
  }, {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export function useAttributes() {
  return useQuery(['attributes'], () => {
    return getAllAttributes().then((r) => r.data.sort((a,b) => (a.key_id > b.key_id) ? 1 : ((b.key_id > a.key_id) ? -1 : 0)));
  }, {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export function useAttributeModifiers(group: number) {
  return useQuery([`attribute-modifiers-${group}`], () => {
    return getGroupModifiers(group).then((r) => r.data);
  }, {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}