import './AdminUsersPage.css';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useState } from 'react';
import AdminWrapper from '../component/AdminWrapper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { useUserInfoForAdmin, useUserMembershipsForAdmin } from '../../../api/Users';
import NamedLoaderAbsolute from '../../../components/NamedLoaderAbsolute';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useServersForAdmin } from '../../../hooks/useServers';
import { useAdminGroupsMap } from '../AdminGroupsPage/GroupAPI';
import Tooltip from '@mui/material/Tooltip';
import { AuthContext, NotificationContext } from '../../../AppRouter';
import IconButton from '@mui/material/IconButton';
import AddOutlinedIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Dialog from '@mui/material/Dialog';
import ModalMembershipCreate from './ModalMembershipCreate';
import ModalMembershipDelete from './ModalMembershipDelete';
import { notification } from '../../../stores/notificationContext';


const AdminUserInfoPage = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [membershipId, setMembershipId] = useState<number | null>(null);
  const [newMembershipModalOpened, setNewMembershipModalOpened] = useState<boolean>(false);
  const [removeMembershipModalOpened, setRemoveMembershipModalOpened] = useState<boolean>(false);
  const userID = Number(params.id);

  const queryUsers = useUserInfoForAdmin(userID);
  const queryGroups = useAdminGroupsMap();
  const queryMemberships = useUserMembershipsForAdmin(Number(params.id));
  const queryServers = useServersForAdmin();
  const authContext = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);

  const getGroupInfo = useCallback((id: number) => {
    if (queryGroups.isLoading) return 'Loading...';
    if (queryGroups.isError) return 'Error';
    if (queryGroups.data) {
      const group = queryGroups.data.get(id);
      if (group === undefined) return `Unknown (id=${id})`;
      return group.key_id;
    }
    return '?';
  }, [queryGroups]);

  let userRepr = null;
  if (queryUsers.data) {
    userRepr = { ...queryUsers.data };
    // @ts-ignore
    delete userRepr.groups;
    // @ts-ignore
    if (userRepr.permissions !== undefined) delete userRepr.permissions;
    userRepr = JSON.stringify(userRepr, null, 2);
  }

  const getServerName = (serverId: number | null): string => {
    if (serverId === null) return 'Global';
    if (!queryServers.data) return '#' + serverId;
    let server = queryServers.data.find(s => s.id === serverId);
    if (server === undefined) return '#' + serverId;
    return '#' + serverId + ': ' + server.name;
  };

  if (authContext === null) return null;
  if (authContext.user === null) return null;

  const permission = authContext.user.hasPermission('web.admin-panel.manage-memberships');

  return (
    <AdminWrapper name='Users' link='/admin-panel/users'>
      <div>
        {
          <Dialog open={newMembershipModalOpened} onClose={() => setNewMembershipModalOpened(false)}>
            <ModalMembershipCreate onCreated={() => {
              notification('success', t('Membership created'), null, null, notificationContext);
              setNewMembershipModalOpened(false);
              queryMemberships.refetch();
            }} user_id={userID} />
          </Dialog>
        }

        {
          <Dialog open={removeMembershipModalOpened} onClose={() => setRemoveMembershipModalOpened(false)}>
            <ModalMembershipDelete
              user_id={userID}
              membership_id={membershipId}
              onDeleted={() => {
                notification('success', t('Membership removed'), null, null, notificationContext);
                setRemoveMembershipModalOpened(false);
                queryMemberships.refetch();
              }}
            />
          </Dialog>
        }

        {queryUsers.isLoading ? <NamedLoaderAbsolute message={t('Loading users')} /> : null}
        {queryUsers.isFetched && queryUsers.data &&
          <Box style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 0', minWidth: '90%', margin: '2px' }}>
              <Typography variant='caption' gutterBottom>{t('User JSON representation')}</Typography>
              <TextField id='json_repr'
                         label='read-only'
                         multiline minRows={5}
                         aria-readonly={true}
                         fullWidth margin='dense'
                         variant='outlined' size='small'
                         value={userRepr === null ? '' : userRepr} />

              <Typography variant='h6' gutterBottom>{t('User group memberships')}</Typography>
              <Typography variant='caption' gutterBottom>{t('Descriptions')}</Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table' size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>ID</TableCell>
                      <TableCell align='left'>Group</TableCell>
                      <TableCell align='left'>Context</TableCell>
                      <TableCell align='left'>Expire</TableCell>
                      <TableCell align='left'>Granted By</TableCell>
                      <TableCell align='left'>Granted At</TableCell>
                      <TableCell align='left'>
                        <Tooltip
                          title={authContext.user.hasPermission('web.admin-panel.manage-memberships') ? 'Add membership' : 'No permission'}>
                          <span
                            style={{ cursor: permission ? 'pointer' : 'not-allowed' }}>
                            <IconButton onClick={() => {
                              setNewMembershipModalOpened(true);
                            }}
                                        color='primary'
                                        aria-label='Edit group'
                                        style={{ padding: 0 }}
                                        disabled={!permission}>
                              <AddOutlinedIcon fontSize='small' />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {queryMemberships.isFetched && queryMemberships.data && queryMemberships.data.map((row) =>
                      (
                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell align='left' className='clickable-id'>{row.id}</TableCell>
                          <TableCell align='left'>{getGroupInfo(row.group_id)}</TableCell>
                          <TableCell align='left'>{getServerName(row.server_id)}</TableCell>
                          <TableCell align='left'>{row.expire === null ? 'Never' : row.expire}</TableCell>
                          <TableCell align='left'>{row.granted_by}</TableCell>
                          <TableCell align='left'>{row.granted_at}</TableCell>
                          <TableCell align='left'>
                            <Tooltip
                              title={permission ? 'Add membership' : 'No permission'}
                            >
                          <span
                            style={{ cursor: permission ? 'pointer' : 'not-allowed' }}>
                            <IconButton onClick={() => {
                              setMembershipId(row.id);
                              setRemoveMembershipModalOpened(true);
                            }}
                                        color='primary'
                                        aria-label='Edit group'
                                        style={{ padding: 0 }}
                                        disabled={!permission}>
                                  <RemoveIcon fontSize='small' />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </TableCell>
                        </TableRow>))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Box>
        }


      </div>
    </AdminWrapper>
  );
};

export default observer(AdminUserInfoPage);