import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


interface LoaderProps {
  absolute?: boolean
  message?: string
  visible?: boolean
}


const STYLES_P = {
  color: 'white',
  fontFamily: 'Roboto, serif',
  fontWeight: 'thin',
  fontStyle: 'italic',
}

const STYLES_ABS = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column' as 'column',
  justifyContent: 'center',
  position: "absolute" as "absolute"
}

function NamedLoader(props: LoaderProps) {
  if (props.absolute) {
    return <div style={{
      opacity: props.visible ? "100%" : "0" ,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column' as 'column',
      justifyContent: 'center',
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }}>
      <CircularProgress color="warning" />
      {props.message ? <p style={STYLES_P}>{props.message}</p> : null}
    </div>
  }
  return <div style={STYLES_ABS}>
    <CircularProgress color="warning" />
    {props.message ? <p style={STYLES_P}>{props.message}</p> : null}
  </div>;
}

export default NamedLoader;
