import "./AdminViolationsPage.css"
import AdminWrapper from '../component/AdminWrapper';
import React from 'react';


const AdminViolationsPage = () => {
  return (
    <AdminWrapper name="Bans/violations" link="/admin-panel/violations">
      <div>AdminViolationsPage</div>
    </AdminWrapper>
  )
}

export default AdminViolationsPage