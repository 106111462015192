import './AdminGroupsPage.css';
import AdminWrapper from '../component/AdminWrapper';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Group } from '../../../entities/group';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { AuthContext, NotificationContext } from '../../../AppRouter';
import { notification } from '../../../stores/notificationContext';
import Paper from '@mui/material/Paper';
import { useAdminGroupsMap } from './GroupAPI';
import Box from '@mui/material/Box';
import ModalEditGroupForm from './ModalEditGroupForm';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import ModalDeletePermissionGrant from './ModalDeletePermissionGrant';
import ModalCreatePermissionGrant from './ModalCreatePermissionGrant';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import { deleteGroupModifier, PermissionGrantDTO } from '../../../api/admin';
import AddOutlinedIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { useAttributeModifiers, useAttributes } from '../../../hooks/usePermissions';
import ModalCreateAttributeModifier from './ModalCreateAttributeModifier';
import { UseQueryResult } from 'react-query';
import { Attribute } from '../../../entities/attribute';


function attributeName(attributes: UseQueryResult<Attribute[]>, attr_id: number): string {
  if (attributes.data) {
    let attr = attributes.data.find(a => a.id === attr_id);
    if (attr === undefined) return "#" + attr_id;

    return attr.key_id.toUpperCase();
  }
  return "#" + attr_id;
}


const AdminGroupAttributesPage = () => {
  const { t } = useTranslation();
  const params = useParams();

  const groupID = Number(params.id);

  const [creationModalOpen, setCreationModalOpen] = useState<boolean>(false);
  const attributes = useAttributes();
  const modifiers = useAttributeModifiers(groupID);
  const notificationContext = useContext(NotificationContext);
  const authContext = useContext(AuthContext);

  let queryGroups = useAdminGroupsMap();

  if (authContext.user == null) return null;
  const permission = authContext.user.hasPermission('web.admin-panel.manage-groups');
  const onCreated = (a: any) => {modifiers.refetch()}
  const deleteModifier = (id: number) => {
    deleteGroupModifier(id)
      .then(e => {
        modifiers.refetch()
      })
      .catch(err => {
        console.log(err);
      })
      .finally()
  }

  return (
    <AdminWrapper name='Group attributes' link='/admin-panel/groups'>
      <div style={{ position: 'relative' }}>

        <Dialog open={creationModalOpen} onClose={() => setCreationModalOpen(false)}>
          <ModalCreateAttributeModifier groupId={groupID} setOpen={setCreationModalOpen} onCreated={onCreated}/>
        </Dialog>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='left'>ID</TableCell>
                <TableCell align='left'>Attribute</TableCell>
                <TableCell align='left'>Server Context</TableCell>
                <TableCell align='left'>Value</TableCell>
                <TableCell align='left'>Granted At</TableCell>
                <TableCell align='left'>
                  <Tooltip title={permission ? 'Add membership' : 'No permission'}>
                    <span style={{ cursor: permission ? 'pointer' : 'not-allowed' }}>
                      <IconButton onClick={() => {
                        setCreationModalOpen(true);
                      }}
                                  color='primary'
                                  aria-label='Edit group'
                                  style={{ padding: 0 }}
                                  disabled={!permission}>
                        <AddOutlinedIcon fontSize='small' />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modifiers.data && modifiers.data.map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align='left'>{row.id}</TableCell>
                  <TableCell align='left'>{attributeName(attributes, row.attribute_id)}</TableCell>
                  <TableCell align='left'>{row.server_id}</TableCell>
                  <TableCell align='left'>{row.value}</TableCell>
                  <TableCell align='left'>{row.granted_at}</TableCell>
                  <TableCell align='left'>
                    <span style={{ cursor: permission ? 'pointer' : 'not-allowed' }}>
                      <IconButton onClick={() => {deleteModifier(row.id)}}
                                  color='primary'
                                  aria-label='Edit group'
                                  style={{ padding: 0 }}
                                  disabled={!permission}>
                        <RemoveIcon fontSize='small' />
                      </IconButton>
                    </span>
                  </TableCell>
                </TableRow>
              ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </AdminWrapper>
  );
};

export default AdminGroupAttributesPage;